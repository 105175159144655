import React, { useState, useEffect, useCallback } from "react";
import { Button, Image } from "antd-mobile";
import Request from "../../../components/request/request";
import NavigationBar from "../../../uimodules/navbar";
//import { Toast } from "antd-mobile";
import { useHistory, useLocation } from "react-router-dom";
import AIMEEaseMob from "../../../components/easemob/index";
import { useTranslation } from 'react-i18next';
import "./index.css";
const UserList = () => {
  const history = useHistory();
  const location = useLocation();
  const liveId = location.state?.liveId;
  const [searchText, setSearchText] = useState("");
  const [historyList, setHistoryList] = useState([]);
  const { t } = useTranslation();
  
  const readUserList = async () => {
    try {
      const url = "/api/live/getLiveVisitorsList";
      const headers = {};

      const result = await Request.get(url, {live_id:liveId}, headers);
      const { data, code, msg } = result;
      if (code === 200) {
        setHistoryList(data);
      }
      console.log("data:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const chatWith = () => {
    history.push("/chat");
    history.push({
      pathname: "/chat",
      state: {
        item: {
          // nickname: item.nickName,
          // avatar_path: item.avatarPath,
          // external_uid: item.conversationId,
        },
      },
    });
  };
  const moreAct = () => {};

  useEffect(() => {
    readUserList();

    return () => {};
  }, []);

  return (
    <div className="userlist-container">
      <NavigationBar navTitle={t("live.userlist.tilte")}/>

      <div className="userlist-list">
        {(historyList ?? []).map((item) => (
          <div
            key={item.account_id}
            className="userlist-list-item"
          >
            <Image
              src={item?.avatar_path}
              className="userlist-list-item-img"
            />
            <div className="userlist-list-item-word">
              <div className="userlist-list-item-title">
                {item?.nickname}
              </div>
              <div className="userlist-list-item-content">
                {item?.description}
              </div>
            </div>
            {item.owner ? null : (
              <div>
                <div className="userlist-item-star" onClick={chatWith}>
                  {t("prelive.private.chat")}
                </div>
                <Image
                  src="/images/live/live_more.png"
                  className="userlist-item-more"
                  onClick={moreAct}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserList;
