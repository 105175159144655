import React, { useEffect } from "react";
import { Image, Toast } from "antd-mobile";
import Request from "../../../components/request/request";
import { useTranslation } from 'react-i18next';
import "./index.css";
const UnclosedAlert = ({ setAlertVisiable, submit, unclosedInfo }) => {
  const { t } = useTranslation();
  const closeLive = async (unclosedInfo) => {
    console.log("#########unclosedInfo",unclosedInfo);
    Toast.show({
      icon: "loading",
      content: t("toast.close"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/live/closeLiveRoom-v2"; // 设置API端点
      const headers = {};
      const params = {
        live_id: unclosedInfo.live_id,
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data, msg, code } = result;
      if (code === 200) {
        setAlertVisiable(false);
      }
      Toast.show({
        content: msg,
        maskClickable: false,
        position: "bottom",
      });
      Toast.clear();
    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div
      className="unclosed-alert-container"
      onClick={() => setAlertVisiable(false)}
    >
      <Image
        src="/images/live/live_price_bg.png"
        className="unclosed-alert-bg"
      />

      <div className="unclosed-alert-main" onClick={(e) => e.stopPropagation()}>
        <Image
          src="https://onlyforyou.live/api/public/download/system_message_avatar.png"
          className="unclosed-alert-main-top-img"
        />

        <div className="unclosed-alert-main-price">{unclosedInfo?.message}</div>
        <div
          className="unclosed-alert-main-sure"
          onClick={() => submit(unclosedInfo)}
        >
          {t("home.join.live")}
        </div>
        <div
          className="unclosed-alert-main-close"
          onClick={() => closeLive(unclosedInfo)}
        >
          {t("home.close.live")}
        </div>

        {/* <div className="unclosed-alert-main-bottom-txt">用户可以开关己方摄像头</div> */}
      </div>
    </div>
  );
};

export default UnclosedAlert;
