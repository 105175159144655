import React, { useEffect } from "react";
import { Image } from "antd-mobile";
import { useTranslation } from 'react-i18next';
import "./index.css";
const InviteAlert = ({ setAlertVisiable, priceItem, acceptInvite}) => {
  const { t } = useTranslation();
  useEffect(() => {

    return () => {};
  }, []);

  return (
    <div className="invite-alert-container" onClick={() => setAlertVisiable(false)}>
      <Image src="/images/live/live_price_bg.png" className="invite-alert-bg" />

      <div className="invite-alert-main" onClick={(e) => e.stopPropagation()}>
        <Image
          src="/images/live/live-invite1v1.png"
          className="invite-alert-main-top-img"
        />

        <div className="invite-alert-main-price">
          <div className="invite-alert-main-price-item-sel">
            <div className="invite-alert-main-price-item1-sel">
              {priceItem?.amount}
            </div>
            <div className="invite-alert-main-price-item2"></div>

            <div className="invite-alert-main-price-item3">
              {priceItem?.time_duration}min
            </div>
          </div>
        </div>
        <div className="invite-alert-main-sure" onClick={()=>acceptInvite(priceItem)}>
          {t("alert.accept.invite")}
        </div>

        {/* <div className="invite-alert-main-bottom-txt">用户可以开关己方摄像头</div> */}
      </div>
    </div>
  );
};

export default InviteAlert;
