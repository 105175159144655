import React, { useState, useEffect, useCallback } from "react";
import { Picker, Image, DatePicker } from "antd-mobile";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./index.css";
const Verified = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [country, setCountry] = useState("");
  const [birthday, setBirthday] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const [minDate, setMinDate] = useState(new Date());
  const [typeColumns] = useState([
    [
      { label: "身份证", value: "身份证" },
      { label: "护照", value: "护照" },
      { label: "驾照", value: "驾照" },
      { label: "其他", value: "其他" },
    ],
  ]);

  const [countryColumns] = useState([
    [
      { label: "美国", value: "美国" },
      { label: "中国", value: "中国" },
      { label: "加拿大", value: "加拿大" },
    ],
  ]);

  const [pickVisiabel, setPickVisiabel] = useState(false);
  const [countryPickVisiabel, setCountryPickVisiabel] = useState(false);
  const [datePickVisiabel, setDatePickVisiabel] = useState(false);
  
  useEffect(() => {
    let currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 150);
    setMinDate(currentDate);
    return () => {};
  }, []);

  const cerType = () => {
    setPickVisiabel(true);
  };

  const countrySel = () => {
    setCountryPickVisiabel(true);
  };

  const birthDate = () => {
    setDatePickVisiabel(true);
  };

  const submit = async () => {
    if (type.length === 0) {
      Toast.show({
        content: "请选择证件类型！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }

    if (name.length === 0) {
      Toast.show({
        content: "请输入真实姓名！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }

    if (idNumber.length === 0) {
      Toast.show({
        content: "请输入证件号！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }

    if (country.length === 0) {
      Toast.show({
        content: "请输入国家地区！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }

    if (birthday.length === 0) {
      Toast.show({
        content: "请输入生日！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }

    if (!videoFile) {
      Toast.show({
        content: "请上传视频！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }

    Toast.show({
      icon: "loading",
      content: t("toast.title.upload"),
      duration: 0,
      maskClickable: false,
    });

    try {
    const url = "/api/user/registerBroadcaster";
    const headers = {};
    const params = {
      full_name: name,
      id_type: type,
      id_number: idNumber,
      location: country,
      birthday: birthday,
    };
    const result = await Request.postFormData(url, params, headers, videoFile);
    console.log("result:", result);
    const { code, msg } = result;
    Toast.clear();
    if (code === 200) {
      Toast.show({
        content: "提交成功，正在审核中...",
        maskClickable: false,
        position: "bottom",
        afterClose: function () {
          history.goBack();
        },
      });
    } else {
      Toast.show({
        content: msg,
        maskClickable: false,
        position: "bottom",
      });
    }
    } catch (error) {
      Toast.clear();
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    } 
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setVideoFile(file);
    reader.onload = (event) => {
      // 使用URL.createObjectURL方法将文件转换为URL
      const videoUrl = URL.createObjectURL(file);
      setSelectedVideo(videoUrl);
    };

    // 读取文件内容
    reader.readAsDataURL(file);
  };

  return (
    <div className="verified-container">
      <NavigationBar navTitle={t("personal.verified")} />
      <div className="verified-title">
        {t("verified.notice")}
      </div>
      <div className="verified-item" onClick={cerType}>
        <div className="verified-item-title">{t("verified.certificate")}</div>
        <div className="verified-item-content">{type}</div>
        <Image
          className="verified-item-arrow"
          src="/images/home/verified_arrow.png"
        />
      </div>
      <div className="verified-item">
        <div className="verified-item-title">{t("verified.realname")}</div>
        <input
          className="verified-input"
          type="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder=""
        />
      </div>
      <div className="verified-item">
        <div className="verified-item-title">{t("verified.id")}</div>
        <input
          className="verified-input"
          type="id"
          value={idNumber}
          onChange={(e) => setIdNumber(e.target.value)}
          placeholder=""
        />
      </div>
      <div className="verified-item" onClick={countrySel}>
        <div className="verified-item-title">{t("verified.country")}</div>
        <div className="verified-item-content">{country}</div>
        <Image
          className="verified-item-arrow"
          src="/images/home/verified_arrow.png"
        />
      </div>
      <div className="verified-item" onClick={birthDate}>
        <div className="verified-item-title">{t("verified.birth")}</div>
        <div className="verified-item-content">{birthday}</div>
        <Image
          className="verified-item-arrow"
          src="/images/home/verified_arrow.png"
        />
      </div>
      <div className="verified-item-video">
        <div className="verified-item-title">{t("verified.video")}</div>
      </div>
      <div className="verified-item-upload">
        <Image
          src="/images/live/verified_add.png"
          className="verified-item-upload-image"
        />
        <div className="verified-item-upload-txt">
          {t("verified.des")}
        </div>
        {selectedVideo && (
          <video className="verified-upload-video" controls type="video/mp4" src={selectedVideo} />
        )}
        <input
          className="verified-upload"
          type="file"
          accept="video/*"
          onChange={handleVideoChange}
        />
      </div>

      <div className="verified-next" onClick={submit}>
      {t("btn.next")}
      </div>

      <Picker
        columns={typeColumns}
        visible={pickVisiabel}
        onClose={() => {
          setPickVisiabel(false);
        }}
        value={type}
        onConfirm={(v) => {
          setType(v);
        }}
      />

      <Picker
        columns={countryColumns}
        visible={countryPickVisiabel}
        onClose={() => {
          setCountryPickVisiabel(false);
        }}
        value={type}
        onConfirm={(v) => {
          setCountry(v);
        }}
      />

      <DatePicker
        title={t("personal.time.pick")}
        min={minDate}
        visible={datePickVisiabel}
        onClose={() => {
          setDatePickVisiabel(false);
        }}
        max={new Date()}
        onConfirm={(date) => {
          const year = date.getFullYear(); // Gets the year (four digits)
          const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 获取月份（0-11），调整为（1-12）并确保两位数
          const day = date.getDate().toString().padStart(2, '0'); // 获取月中的日（1-31）并确保两位数
          setBirthday(`${year}-${month}-${day}`);
        }}
      />
    </div>
  );
};

export default Verified;
