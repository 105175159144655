import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import WebIM from "easemob-websdk";
import Request from "../../components/request/request";
import AIMEEaseMob from "../../components/easemob/index";
import { Toast, Dialog } from "antd-mobile";
import "./index.css";

const ChatScroll = forwardRef((props, ref) => {
  const [chatList, setChatList] = useState([]);
  const chatListRef = useRef(null);
  const [chatIsJoin, setChatIsJoin] = useState(false);
  const scrollToBottom = () => {
    const current = chatListRef.current;
    if (current) {
      current.scrollTop = current.scrollHeight;
      console.log("###############" + current.scrollHeight);
    }
  };
  const easeUserToken = async () => {
    AIMEEaseMob.easeUserToken();
  };

  const joinChatRoom = () => {
    try {
      let option = {
        roomId: props.chatroomId,
        message: "reason",
      };
      console.log("##########joinChatRoom" + JSON.stringify(option));
      AIMEEaseMob.conn.joinChatRoom(option).then((res) => {
        console.log("##########joinChatRoom" + JSON.stringify(res));
        AIMEEaseMob.conn
          .getJoinedChatRooms({
            pageNum: 1,
            pageSize: 20,
          })
          .then((res) => {
            console.log(res);
          });
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const leaveChatRoom = () => {
    if (props.chatroomId) {
      let option = {
        roomId: props.chatroomId,
      };
      // try {
      //   AIMEEaseMob.conn.leaveChatRoom(option).then((res) => console.log(res));
      // } catch (error) {
      //   console.log(error);
      // }
    }
  };

  const msgNotice = () => {
    AIMEEaseMob.conn.addEventHandler("CHATROOM", {
      onChatroomEvent: (e) => {
        switch (e.operation) {
          case "memberPresence":
            props.updateNumber(e?.memberCount);
            // 当前聊天室在线人数
            console.log("===============memberCount11" + e?.memberCount);
            break;
          default:
            break;
        }
      },
    });

    AIMEEaseMob.conn.addEventHandler("handlerId", {
      onTextMessage: (message) => {
        console.log("################## 收到消息：" + JSON.stringify(message));
        const { id, type, msg, to, ext } = message;
        const { fromNickname } = ext;
        console.log(
          "##################msgNotice：" + props.chatroomId + "||||" + to
        );
        if (props.chatroomId === to) {
          addNewMsg(id, fromNickname, msg);
        }
      },
      onImageMessage: (message) => {},
      onCustomMessage: (message) => {
        console.log("################## 收到消息：" + JSON.stringify(message));
        const { id, type, customEvent, customExts, to, ext } = message;
        const {
          fromNickname,
          gift_id,
          gift_num,
          entry_id,
          price_item,
          live_1v1_id,
        } = customExts;
        console.log(
          "##################msgNotice：" + props.chatroomId + "||||" + to
        );
        if (props.chatroomId === to) {
          if (customEvent === "gift") {
            if (props.playSvga) props.playSvga(gift_id);
          }
        }
        if (customEvent === "1v1_pay_success") {
          if (props.inviteAlert) {
            props.inviteAlert(entry_id, price_item);
          }
        }

        if (customEvent === "1v1_invite") {
          console.log("#####################1v1_invite");
          if (props.showInvite) props.showInvite(live_1v1_id);
        }
      },
      onConnected: () => {
        console.log("##################onConnected");
        setChatIsJoin(true);
        joinChatRoom();
      },
      onDisconnected: () => {
        console.log("##################onDisconnected");
      },
      onTokenWillExpire: () => {
        console.log("##################onTokenWillExpire");
      },
      onTokenExpired: () => {
        console.log("##################onTokenExpired");
      },
    });
  };

  const addNewMsg = (mid, name, content) => {
    let itemDict = {
      mid: mid,
      name: name,
      content: content,
    };

    setChatList((prevChatList) => [...prevChatList, itemDict]);
    setTimeout(scrollToBottom, 100);
  };

  const sendTextMessage = (inputValue) => {
    console.log(inputValue);
    if (!inputValue) {
      return;
    }
    AIMEEaseMob.conn.addEventHandler("handlerId", {
      onConnected: () => {
        sendMessage(inputValue);
      },
    });
    if (AIMEEaseMob.conn.isOpened()) {
      sendMessage(inputValue);
    } else {
      AIMEEaseMob.easeUserToken();
    }
  };

  const sendMessage = (inputValue) => {
    console.log(inputValue);
    if (!inputValue) {
      return;
    }
    if (!chatIsJoin) {
      joinChatRoom();
    }
    let option = {
      type: "txt",
      msg: inputValue,
      to: props.chatroomId,
      chatType: "chatRoom",
      ext: {
        fromNickname: props.nickname,
      },
    };
    console.log("########sendTextMessage=", JSON.stringify(option));
    let msg = WebIM.message.create(option);
    AIMEEaseMob.conn
      .send(msg)
      .then((res) => {
        const { message } = res;
        const { id } = message;
        console.log("########Send message success", res);
        addNewMsg(id, props.nickname, inputValue);
        props.clearInput();
      })
      .catch((e) => {
        console.log("######Send message fail", e);
        easeUserToken();
      });
  };

  const sendGift = (gift_id) => {
    if (!chatIsJoin) {
      joinChatRoom();
    }
    let customEvent = "gift";
    // 通过键值对设置自定义消息内容。
    let customExts = {
      gift_id: gift_id,
      fromNickname: props.nickname,
    };
    let option = {
      type: "custom",
      to: props.chatroomId,
      chatType: "chatRoom",
      customEvent,
      customExts,
      ext: {},
    };
    console.log("########sendGift=", JSON.stringify(option));
    let msg = WebIM.message.create(option);
    AIMEEaseMob.conn
      .send(msg)
      .then((res) => {
        const { message } = res;
        const { id } = message;
        console.log("########Send sendGift success", res);
        props.playSvga(gift_id);
      })
      .catch((e) => {
        console.log("######Send sendGift fail", e);
        easeUserToken();
      });
  };

  useEffect(() => {
    msgNotice();
    return () => {};
  }, [props.chatroomId, chatList]);

  useImperativeHandle(ref, () => ({
    sendTextMessage,
    easeUserToken,
    sendGift,
    leaveChatRoom,
  }));

  return (
    <div className="liveoutput-chat-container" ref={chatListRef}>
      {(chatList ?? []).map((item) => (
        <div key={item?.mid} className="live-chat-scroll-item">
          <div className="live-chat-name">{item?.name}：</div>
          <div className="live-chat-content">{item?.content}</div>
        </div>
      ))}
    </div>
  );
});

export default ChatScroll;
