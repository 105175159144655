import React, { useState, useEffect, useCallback } from "react";
import { Picker, Image, DatePicker } from "antd-mobile";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import PopPwd from "../../uimodules/poppwd/index";
import { useTranslation } from 'react-i18next';
import "./index.css";
const Personal = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const history = useHistory();
  const [udi, setUdi] = useState("");
  const [name, setName] = useState("");
  const [sex, setSex] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [minDate, setMinDate] = useState(new Date());
  const [popPwdVisiable, setPopPwdVisiable] = useState(false);
  const [header, setHeader] = useState("");
  const [birthday, setBirthday] = useState("");
  const [nickname, setNickname] = useState("");
  const { t } = useTranslation();
  const [typeColumns] = useState([
    [
      { label: t("personal.sex.male"), value: t("personal.sex.male") },
      { label: t("personal.sex.female"), value: t("personal.sex.female") },
      { label: t("personal.sex.unknow"), value: t("personal.sex.unknow") },
    ],
  ]);
  var sexMap = { 0: t("personal.sex.male"), 1: t("personal.sex.male"), 2: t("personal.sex.female"), 3:t("personal.sex.unknow") };
  const [pickVisiabel, setPickVisiabel] = useState(false);

  const [datePickVisiabel, setDatePickVisiabel] = useState(false);

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const fetchData = async () => {
    try {
      const url = "/api/user/info"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      if (code === 200) {
        const {
          unique_nickname_id,
          first_name,
          last_name,
          sex,
          address,
          login_email,
          avatar_path,
          nickname,
          birthday,
          phone_number,
        } = data;
        setUdi(unique_nickname_id);
        setName(last_name + first_name);
        setBirthday(birthday);
        setSex(sexMap[sex] ? sexMap[sex] : "未知");
        setAddress(address);
        setEmail(login_email);
        setHeader(avatar_path);
        setNickname(nickname);
        setPhone(phone_number);
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  const setPaymentPassword = async (psw) => {
    setPopPwdVisiable(false);
    Toast.show({
      icon: "loading",
      content: t("personal.toast.submit"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/user/setPaymentPassword"; // 设置API端点
      const headers = {};
      const params = {
        payment_password: psw,
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      Toast.clear();
      if (code === 200) {
        Toast.show({
          content: t("personal.toast.suc"),
          maskClickable: false,
          position: "bottom",
        });
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  const changePaymentPassword = async (psw) => {
    Toast.show({
      icon: "loading",
      content: t("personal.toast.submit"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/user/changePaymentPassword"; // 设置API端点
      const headers = {};
      const params = {
        current_payment_password: psw,
        new_payment_password: psw,
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      Toast.clear();
      if (code === 200) {
        Toast.show({
          content: t("personal.toast.suc"),
          maskClickable: false,
          position: "bottom",
        });
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  const updatePersonalInfo = async () => {
    Toast.show({
      icon: "loading",
      content: t("personal.toast.submit"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/updatePersonalInfo"; // 设置API端点
      const headers = {};
      const newSex = getKeyByValue(sexMap, sex);
      const params = {
        nickname: nickname,
        unique_nickname_id: udi,
        sex: newSex ? parseInt(newSex) : 3,
        phone_number: phone,
        address: address,
      };

      console.log(params);
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      Toast.clear();
      if (code === 200) {
        Toast.show({
          content: t("personal.toast.suc"),
          maskClickable: false,
          position: "bottom",
        });
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  const sendTextMessage = (event) => {
    if (event.charCode !== 13) {
      return;
    }
    updatePersonalInfo();
  };

  useEffect(() => {
    fetchData();
    let currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 150);
    setMinDate(currentDate);
    return () => {};
  }, []);

  const birthDate = () => {
    setDatePickVisiabel(true);
  };

  const verified = () => {
    history.push("/verified");
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      // 使用URL.createObjectURL方法将文件转换为URL
      const videoUrl = URL.createObjectURL(file);
      setSelectedVideo(videoUrl);
    };

    // 读取文件内容
    reader.readAsDataURL(file);
  };

  return (
    <div className="personal-container">
      <NavigationBar navTitle={t("mine.title1")} />
      <div className="personal-title">
        <Image src={header} className="personal-header" />

        <div className="personal-title-edit">
          <input
            className="personal-title-input"
            type="name"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            placeholder=""
            onKeyPress={sendTextMessage}
            onBlur={updatePersonalInfo}
          />
          <Image
            src="/images/person/personal_edit.png"
            className="personal-header-edit"
          />
        </div>
        <div></div>
      </div>
      <div className="personal-item">
        <div className="personal-item-title">UID</div>
        <input
          className="personal-input"
          type="name"
          value={udi}
          onChange={(e) => setUdi(e.target.value)}
          placeholder=""
          onKeyPress={sendTextMessage}
          onBlur={updatePersonalInfo}
        />
      </div>
      <div className="personal-item">
        <div className="personal-item-title">{t("personal.name")}</div>
        <input
          readOnly
          className="personal-input"
          type="name"
          value={name.length>0?name:t("personal.verified.placeholder")}
          onChange={(e) => setName(e.target.value)}
          placeholder=""
          onKeyPress={sendTextMessage}
          onBlur={updatePersonalInfo}
        />
      </div>
      <div className="personal-item" onClick={() => setPickVisiabel(true)}>
        <div className="personal-item-title">{t("personal.sex")}</div>
        <div className="personal-item-content">{sex}</div>
        <Image
          className="personal-item-arrow"
          src="/images/home/verified_arrow.png"
        />
      </div>
 
      <div className="personal-item" onClick={birthDate}>
        <div className="personal-item-title">{t("personal.birthday")} </div>
        <div className="personal-item-content">{birthday}</div>
        <Image
          className="personal-item-arrow"
          src="/images/home/verified_arrow.png"
        />
      </div>
      <div className="personal-item">
        <div className="personal-item-title">{t("personal.phone")}</div>
        <input
          className="personal-input"
          type="name"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder=""
          onKeyPress={sendTextMessage}
          onBlur={updatePersonalInfo}
        />
      </div>

      <div className="personal-item">
        <div className="personal-item-title">{t("personal.address")}</div>
        <input
          className="personal-input"
          type="name"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          placeholder=""
          onKeyPress={sendTextMessage}
          onBlur={updatePersonalInfo}
        />
      </div>

      <div className="personal-item" onClick={verified}>
        <div className="personal-item-title">{t("personal.verified")}</div>
        <div className="personal-item-content"></div>
        <Image
          className="personal-item-arrow"
          src="/images/home/verified_arrow.png"
        />
      </div>

      <div className="personal-item" onClick={() => setPopPwdVisiable(true)}>
        <div className="personal-item-title">{t("personal.pay.password")}</div>
        <div className="personal-item-content"></div>
        <Image
          className="personal-item-arrow"
          src="/images/home/verified_arrow.png"
        />
      </div>

      <div className="personal-item">
        <div className="personal-item-title">{t("personal.email")}</div>
        <input
          readOnly
          className="personal-input"
          type="name"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder=""
          onKeyPress={sendTextMessage}
          onBlur={updatePersonalInfo}
        />
      </div>

      <Picker
        columns={typeColumns}
        visible={pickVisiabel}
        onClose={() => {
          setPickVisiabel(false);
        }}
        value={sex}
        onConfirm={(v) => {
          let sexNew = v[0];
          // setSex(sexNew);
          setSex(prevSex =>sexNew);
          updatePersonalInfo();
        }}
      />

      <DatePicker
        title={t("personal.time.pick")}
        min={minDate}
        visible={datePickVisiabel}
        onClose={() => {
          setDatePickVisiabel(false);
        }}
        max={new Date()}
        onConfirm={(date) => {
          const year = date.getFullYear(); // Gets the year (four digits)
          const month = date.getMonth() + 1; // Gets the month (0-11) and adjusts it to (1-12)
          const day = date.getDate(); // Gets the day of the month (1-31)

          setBirthday(`${year}-${month}-${day}`);
          updatePersonalInfo();
        }}
      />
      {popPwdVisiable && (
        <PopPwd
          popPwdVisiable={popPwdVisiable}
          setPopPwdVisiable={setPopPwdVisiable}
          calllback={setPaymentPassword}
        />
      )}
    </div>
  );
};

export default Personal;
