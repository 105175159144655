import React, { useEffect, useState, useRef } from "react";
import { Image, Swiper, Toast, Empty } from "antd-mobile";
import Request from "../../components/request/request";
import NavBar from "./navbar/index";
import { Link } from "react-router-dom";
import AIMEEaseMob from "../../components/easemob/index";
import { useHistory } from "react-router-dom";
import ShowPayAlert from "./showalert/index";
import UnclosedAlert from "./unclosedalert/index";
import Custom from "../../components/custom/custom";
import { useTranslation } from "react-i18next";
import "./index.css";

const Home = () => {
  const history = useHistory();
  const swiperRef = useRef(null);
  const [homeList, setHomeList] = useState([]);
  const [exploreList, setExploreList] = useState([]);
  const [liveList, setLiveList] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [userPhoto, setUserPhoto] = useState("/images/home/home_head.png");
  const [isLogin, setIsLogin] = useState(false);
  const [alertVisiable, setAlertVisiable] = useState(false);
  const [unclosedAlertVisiable, setUnclosedAlertVisiable] = useState(false);
  const [unclosedInfo, setUnclosedInfo] = useState({});
  const [alertItem, setAlertItem] = useState("");
  const [selectIndex, setSelectIndex] = useState(1);
  const [alertInfo, setAlertInfo] = useState({});
  const [homeIsEmpty, setHomeIsEmpty] = useState(false);
  const [exploreIsEmpty, setExploreIsEmpty] = useState(false);
  const { t } = useTranslation();
  const fetchData = async () => {
    try {
      const url = "/api/exploreList";
      const headers = {};
      const result = await Request.get(url, headers);
      console.log("result:", result);

      const { data, code, msg } = result || [];
      if (code === 200) {
        setExploreIsEmpty(!data || data.length === 0);
        setExploreList(data);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }

    try {
      const url = "/api/followingPageList";
      const headers = {};
      const result = await Request.get(url, headers);
      console.log("result:", result);
      const { data, code, msg } = result || [];
      if (code === 200) {
        // console.log("data:", data);
        setHomeIsEmpty(!data || data.length === 0);
        setHomeList(data);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }

    try {
      const url = "/api/followingPageLiveList";
      const headers = {};
      const result = await Request.get(url, headers);
      const { data, code, msg } = result || [];
      if (code === 200) {
        setLiveList(data);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const unclosedLive = async () => {
    try {
      const url = "/api/live/check-unclosed-live"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      if (code === 200 && data) {
        setUnclosedInfo(data);
        setUnclosedAlertVisiable(true);
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  const readLocal = () => {
    const data = localStorage.getItem("userdata");
    if (data) {
      const parsedData = JSON.parse(data);
      const { avatar_path } = parsedData.account || {};
      setUserInfo(parsedData);
      setUserPhoto(avatar_path);
      setIsLogin(!!parsedData.access_token);
      // console.log("##################data=" + data);
    }
  };
  const tabChange = (index) => {
    console.log("##################" + index);
    setSelectIndex(index);
    if (swiperRef.current) {
      swiperRef.current.swipeTo(index); // Invoke swipeTo method
    }
  };

  const getPayInfo = async (item) => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/private-show/viewer-get-show-info";
      const headers = {};
      const params = {
        live_id: item.live_id,
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result || [];
      Toast.clear();
      if (code === 200) {
        const { bought_ticket, private_show_ticket_info } = data;
        if (bought_ticket) {
          console.log(item);
          history.push({ pathname: "/liveoutput", state: { item } });
        } else {
          setAlertItem(item);
          setAlertInfo(private_show_ticket_info);
          setAlertVisiable(true);
        }
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.clear();
      console.error("Error:", error);
    }
  };

  const openLive = (item) => {
    if (item.type === 1) {
      getPayInfo(item);
    } else {
      history.push({ pathname: "/liveoutput", state: { item } });
    }
  };

  const openUnclosedLive = (item) => {
    const {
      type,
      entries_id,
      description,
      live_1v1_id,
      room_name,
      one_on_one_open,
    } = item;
    switch (type) {
      case 0:
        {
          let params = {
            isFront: true,
            beautyON: true,
            roomName: room_name ? room_name : t("home.room.name"),
            oneVOne: one_on_one_open,
            isUncloseLive: true,
          };
          var path = { pathname: "/liveinput", state: params };
          history.push(path);
        }
        break;
      case 1:
        {
          let params = {
            isFront: true,
            beautyON: true,
            roomName: room_name ? room_name : t("home.room.name"),
            oneVOne: one_on_one_open,
            isUncloseLive: true,
          };
          var path = { pathname: "/liveinput", state: params };
          history.push(path);
        }
        break;
      case 2:
        if (live_1v1_id) {
          history.push({
            pathname: "/ovoaud",
            state: { live_1v1_id: live_1v1_id },
          });
        } else if (entries_id) {
          history.push({
            pathname: "/ovo",
            state: { entry_id: entries_id, description: description },
          });
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchData();
    readLocal();
    unclosedLive();
    if (isLogin) {
      AIMEEaseMob.easeUserToken();
    }
  }, []);

  return (
    <div className="app">
      <div className="body">
        <div className="home">
          <NavBar userPhoto={userPhoto} isLogin={isLogin} />
          <div className="home-main">
            <div className="home-tabs">
              <div
                className={`home-tabs-item ${
                  selectIndex === 0 ? "home-selected" : ""
                }`}
                onClick={() => tabChange(0)}
              >
                <div className="home-tabs-item-txt">
                  {t("home.notice.title")}
                </div>
                {selectIndex === 0 && (
                  <div className="home-tabs-item-bg">
                    <Image
                      className="home-tabs-item-bg-left"
                      src="/images/home/home_selected_left.png"
       
                    />
                    <Image
                      className="home-tabs-item-bg-right"
                      src="/images/home/home_selected_right.png"
                    />
                  </div>
                )}
              </div>
              <div
                className={`home-tabs-item ${
                  selectIndex === 1 ? "home-selected" : ""
                }`}
                onClick={() => tabChange(1)}
              >
                <div className="home-tabs-item-txt">
                  {t("home.recommend.title")}
                </div>
                {selectIndex === 1 && (
                  <div className="home-tabs-item-bg">
                  <Image
                    className="home-tabs-item-bg-left"
                    src="/images/home/home_selected_left.png"
     
                  />
                  <Image
                    className="home-tabs-item-bg-right"
                    src="/images/home/home_selected_right.png"
                  />
                </div>
                )}
              </div>
            </div>

            <Swiper
              direction="horizontal"
              loop={false}
              allowTouchMove={false}
              indicator={() => null}
              ref={swiperRef}
              defaultIndex={selectIndex}
              onIndexChange={(index) => {
                setSelectIndex(index);
              }}
            >
              <Swiper.Item key="home">
                {!homeIsEmpty ? (
                  <div className="home-list">
                    <div className="home-live">
                      {(liveList ?? []).map((item) => (
                        <Link to={{ pathname: "/liveoutput", state: { item } }}>
                          <div key={item.id} className="home-live-item">
                            <Image
                              src="/images/home/home_live_user_tag.png"
                              className="home-live-item-tag"
                            />

                            <div className="home-live-item-outside-image">
                              <Image
                                src={item.avatar_path}
                                className="home-live-item-image"
                              />
                            </div>

                            <div className="home-live-item-nickname">
                              {item.nickname}
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>

                    {(homeList ?? []).map((item) => (
                      <div key={item.id} className="home-item">
                        <Image className="home-bg" src={item.bk_pic_path} />
                        <div className="home-item-id">
                          {Custom.truncateString(item.unique_nickname, 16)}
                          <Image
                            className="home-item-id-star"
                            src={
                              item.favorite === 0
                                ? "/images/find/find_star.png"
                                : "/images/find/find_star_sel.png"
                            }
                          />
                        </div>
                        {item.online_status ? (
                          <div>
                            <Image
                              src="/images/live/live_online.png"
                              className="home-item-online"
                            />
                            <div className="home-item-online">
                              {item.online_status}
                            </div>
                          </div>
                        ) : null}
                        <Image
                          src="/images/home/home_cover.png"
                          className="home-item-main"
                        />
                        <Image
                          src={item.one_on_one_status_logo_path}
                          className="home-item-1v1-invite"
                        />
                        <Link to={{ pathname: "/personinfo", state: { item } }}>
                          <Image
                            src={item.avatar_path}
                            className="home-item-head"
                          />
                          <div className="home-item-name">
                            {Custom.truncateString(item.nickname, 8)}{" "}
                            {Custom.emojiComponent(item.location_emoji_unicode)}
                          </div>
                        </Link>
                        <div
                          className="home-item-enter"
                          onClick={() => openLive(item)}
                        >
                          {item.type === 1
                            ? t("home.big.show")
                            : t("home.studio")}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="home-list">
                    <Empty
                      className="home-list-empty"
                      description={t("home.empty")}
                    />
                  </div>
                )}
              </Swiper.Item>
              <Swiper.Item key="explore">
                {!exploreIsEmpty ? (
                  <div className="home-list">
                    {(exploreList ?? []).map((item) => (
                      <div key={item.id} className="home-item">
                        <Image className="home-bg" src={item.bk_pic_path} />
                        <div className="home-item-id">
                          {Custom.truncateString(item.unique_nickname, 16)}
                          <Image
                            className="home-item-id-star"
                            src={
                              item.favorite === 0
                                ? "/images/find/find_star.png"
                                : "/images/find/find_star_sel.png"
                            }
                          />
                        </div>
                        {item.online_status ? (
                          <div>
                            <Image
                              src="/images/live/live_online.png"
                              className="home-item-online"
                            />
                            <div className="home-item-online">
                              {item.online_status}
                            </div>
                          </div>
                        ) : null}
                        <Image
                          src="/images/home/home_cover.png"
                          className="home-item-main"
                        />
                        <Image
                          src={item.one_on_one_status_logo_path}
                          className="home-item-1v1-invite"
                        />

                        <Link to={{ pathname: "/personinfo", state: { item } }}>
                          <Image
                            src={item.avatar_path}
                            className="home-item-head"
                          />

                          <div className="home-item-name">
                            {Custom.truncateString(item.nickname, 8)}{" "}
                            {Custom.emojiComponent(item.location_emoji_unicode)}
                          </div>
                        </Link>
                        <div
                          className="home-item-enter"
                          onClick={() => openLive(item)}
                        >
                          {item.type === 1
                            ? t("home.big.show")
                            : t("home.studio")}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="home-list">
                    <Empty
                      className="home-list-empty"
                      description={t("home.empty")}
                    />
                  </div>
                )}
              </Swiper.Item>
            </Swiper>
          </div>
        </div>
        {alertVisiable ? (
          <ShowPayAlert
            alertItem={alertItem}
            alertInfo={alertInfo}
            setAlertVisiable={setAlertVisiable}
          />
        ) : null}
        {unclosedAlertVisiable ? (
          <UnclosedAlert
            unclosedInfo={unclosedInfo}
            submit={openUnclosedLive}
            setAlertVisiable={setUnclosedAlertVisiable}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Home;
