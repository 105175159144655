import React, { useState, useEffect, useRef } from "react";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { Image,Toast } from "antd-mobile";
import Valid from "../../components/custom/valid";
import { useHistory } from "react-router-dom";
import PopPwd from "../../uimodules/poppwd/index";
import { useTranslation } from 'react-i18next';
import "./index.css";
const Withdraw = () => {
  const history = useHistory();

  const { t } = useTranslation();
  const [chargeInfo, setChargeInfo] = useState("");
  const [popPwdVisiable, setPopPwdVisiable] = useState(false);
  const [wdAmount, setWdAmount] = useState("");
  const [email, setEmail] = useState("");
  const [psw, setPsw] = useState("");
  const [scoresBalance, setScoresBalance] = useState("");
  
  const fetchData = async () => {
    try {
      const url = "/api/getExchangeRate"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { data } = result;
      setChargeInfo(data);
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }

    try {
      const url = "/api/user/info"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data,code } = result;
      if(code===200){
        const { scores_balance } = data;
        setScoresBalance(scores_balance);
      }

    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };
  
  const calllback = async (value) => {
    setPopPwdVisiable(false);
    Toast.show({
      icon: "loading",
      content: t("toast.process"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/payment/withdrawal"; // 设置API端点
      const headers = {};
      const params = {
        amount:wdAmount,
        currency:"USD",
        email:email,
        payment_password:value
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data,code,msg } = result;
      Toast.show({
        content: msg,
        maskClickable: false,
        position: "bottom",
      });
      if(code===200){
        history.goBack();
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  }

  const withDrawAct = async () => {
    if (!Valid.isValidEmail(email)) {
      Toast.show({
        content: "Invalid email format！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }
    if (parseInt(wdAmount)<=0|| parseInt(wdAmount)>parseInt(scoresBalance)) {
      Toast.show({
        content: "Invalid amount！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }
    setPopPwdVisiable(true);
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);


  return (
    <div className="withdraw-container">
      <NavigationBar navTitle={t("withdraw.title")} />
      <div className="withdraw-title">{t("recharge.subtitle")}：</div>
      <div className="withdraw-content">{chargeInfo}</div>

      <div className="withdraw-item">
        <input
          className="withdraw-input"
          value={wdAmount}
          onChange={(e) => setWdAmount(e.target.value)}
          placeholder={t("withdraw.avaliable")+"："+scoresBalance}
          type="number"
        />
        <Image
          src="/images/recharge/recharge_big_coin.png"
          className="withdraw-input-coin"
        />
      </div>

      <div className="withdraw-title1">{t("withdraw.account")}：</div>
      <div className="withdraw-column">
        <div className="withdraw-item">
          <input
            className="withdraw-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("withdraw.email")}
            type="text"
          />
        </div>
      </div>

      <div className="withdraw-next" onClick={withDrawAct}>
      {t("withdraw.title")}
      </div>
            {popPwdVisiable && (
        <PopPwd
        popPwdVisiable ={popPwdVisiable}
        setPopPwdVisiable={setPopPwdVisiable}
        calllback={calllback}
        />
      )}
    </div>
  );
};

export default Withdraw;
