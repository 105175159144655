import { createRoot } from "react-dom/client";
import App from "./App";
import React from "react";
import { ConfigProvider } from "antd-mobile";
import enUS from "antd-mobile/es/locales/en-US";

import "./index.css";
import "./components/i18n/index";
const root = createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider locale={enUS}>
    <App />
  </ConfigProvider>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);
