import React, { useState, useEffect } from "react";
import { Image } from "antd-mobile";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import QRCode from "qrcode.react";
import { useTranslation } from 'react-i18next';
import "./index.css";
const Share = () => {
  const history = useHistory();
  const [userPhoto, setUserPhoto] = useState("");
  const location = useLocation();
  const { item } = location.state || {};
  const { t } = useTranslation();
  const readLocal = () => {
    const data = localStorage.getItem("userdata");
    if (data) {
      const userInfo = JSON.parse(data) || {};
      const { avatar_path } = userInfo.account || {};
      setUserPhoto(avatar_path);
    }
  };

  const copyText = () => {
    navigator.clipboard
      .writeText(item?.share_link)
      .then(() => {
        Toast.show({
          content: "复制成功！",
          maskClickable: false,
          position: "bottom",
        });
      })
      .catch((err) => {
        Toast.show({
          content: "复制失败！",
          maskClickable: false,
          position: "bottom",
        });
      });
  };

  useEffect(() => {
    readLocal();
    return () => {};
  }, []);

  const scan = () => {
    // history.push("/pay");
  };

  return (
    <div className="share-container">
      <NavigationBar
        navTitle={t("mine.title4")}
        // moreImg="/images/share/share_scan.png"
        // moreHandle={scan}
      />

      <Image src={"/images/share/share_bg.png"} className="share-item-img" />

      <div className="share-body">
        <div className="share-item-nickname">{item?.nickname}</div>
        <QRCode
        className="share-item-qrcode"
          value={item?.share_link} // 生成二维码的内容
          size={180} // 二维码的大小
          fgColor="#000000" // 二维码的颜色
          imageSettings={{
            // 中间有图片logo
            src: "/logo192.png",
            height: 30,
            width: 30,
            excavate: true,
          }}
        />
        <div className="share-item-notice">Scan this QR code to connect with me</div>
        
      </div>
      <Image src={item.avatar_path?item.avatar_path:userPhoto} className="share-header" />

      <div className="share-btn-body">
        <Image src={"/images/share/share_pofile.png"} className="share-btn" />
        <Image
          src={"/images/share/share_copy_link.png"}
          className="share-btn"
          onClick={copyText}
        />
      </div>
    </div>
  );
};

export default Share;
