import React, { useState, useEffect, useCallback } from "react";
import { Button, Image } from "antd-mobile";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { Link } from "react-router-dom";
import { Toast } from "antd-mobile";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import "./index.css";
const SearchPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [searchList, setSearchList] = useState([]);

  const searchAct = async () => {
    console.log("Input lost focus:", searchText);
    if (searchText.length === 0) {
      return;
    }
    try {
      const url = "/api/search";
      const headers = {};
      const params = {
        keyword: searchText || "",
        page: 1,
        limit: 100,
      };
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      if (code === 200) {
        setSearchList(data);
        if(!data||data.length===0){
          Toast.show({
            content: t("search.empty"),
            maskClickable: false,
            position: "bottom",
          });
        }
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const searchTextMessage = (event) => {
    if (event.charCode !== 13) {
      return;
    }
    searchAct();
  };

  return (
    <div className="search-container">
      <NavigationBar bgColor="#262626" />
      <div className="search-header-container">
        <input
          className="search-search-input"
          type="searchText"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onBlur={searchAct}
          placeholder={t("input.search.placehold")} 
          onKeyPress={searchTextMessage}
        />
      </div>

      <div className="search-list">
        {(searchList ?? []).map((item) => (
          <Link
            key={item.id}
            to={{ pathname: "/personinfo", state: { item: item } }}
          >
            <div className="search-list-item">
              <Image src={item.avatar_path} className="search-list-item-img" />
              <div className="search-list-item-word">
                <div className="search-list-item-title">{item.nickname}</div>
                <div className="search-list-item-content">
                  {item.description}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SearchPage;
