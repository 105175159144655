import AgoraRTC from "agora-rtc-react";

const config = {
  mode: "rtc",
  codec: "vp8",
};

const usertcClient = AgoraRTC.createClient(config);

export default usertcClient;
