import React, { useState, useEffect, useCallback } from "react";
import { Button, Image } from "antd-mobile";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { Link } from "react-router-dom";
import { Tabs, Badge, Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Custom from "../../components/custom/custom";
import { useTranslation } from 'react-i18next';
import "./index.css";
import { ImageViewer, Mask } from "antd-mobile";

const PersonInfo = () => {
  const location = useLocation();
  const history = useHistory();
  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [listInfo, setListInfo] = useState({
    num_of_pics: 0,
    num_of_vids: 0,
  });
  const [userPhoto, setUserPhoto] = useState("");
  const [userID, setUserID] = useState("");
  const [nickname, setNickname] = useState("");
  const [domainnick, setDomainnick] = useState("");
  const [description, setDescription] = useState("");
  const [emoji, setEmoji] = useState("");
  const [numberLikes, setNumberLikes] = useState("");
  const [numberPlay, setNumberPlay] = useState("");
  const [imageVisible, setImageVisible] = useState(false);
  const [quickImageUrl, setQuickImageUrl] = useState("");
  const [videoVisible, setVideoVisible] = useState(false);
  const [quickVideoUrl, setQuickVideoUrl] = useState("");

  const [isFollow, setIsFollow] = useState(false);
  const { state } = location || {};
  const { item } = state || {};
  const { t } = useTranslation();
  const getBroadcasterPosts = async (type) => {
    try {
      const url = "/api/post/getBroadcasterPosts";
      const headers = {};
      const params = {
        page: 1,
        limit: 200,
        account_id: item?.account_id,
        type: type,
      };
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { code, msg, data } = result;
      const { posts_list, info } = data;
      if (code === 200) {
        if (type === 1) {
          setImageList(posts_list);
        } else {
          setVideoList(posts_list);
        }
        setListInfo(info);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };

  const getBroadcasterPublicInfo = async () => {
    try {
      const url = "/api/getBroadcasterPublicInfo";
      const headers = {};
      const params = {
        broadcaster_account_id: item?.account_id,
      };
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { code, msg, data } = result;

      if (code === 200) {
        setIsFollow(data?.is_following);
        setUserID(data?.account_id);
        setUserPhoto(data?.avatar_path);
        setNickname(data?.nickname);
        setDomainnick(data?.unique_nickname);
        setDescription(data?.description);
        getBroadcasterPosts(1);
        setEmoji(data?.location_emoji_unicode);
        setNumberLikes(data?.num_of_likes);
        setNumberPlay(data?.num_of_lives);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getBroadcasterPublicInfo();
    return () => {};
  }, [item]);

  const follow = async () => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/user/follow";
      const headers = {};
      const params = {
        followee_id: item?.account_id,
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, msg } = result;
      Toast.clear();
      if (code === 200) {
        setIsFollow(!isFollow);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.clear();
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };

  const unlock = async (post_id, type) => {
    Toast.show({
      icon: "loading",
      content: "解锁中…",
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/post/unlock";
      const headers = {};
      const params = {
        post_id: post_id,
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, msg } = result;
      Toast.clear();
      if (code === 200) {
        getBroadcasterPosts(type);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.clear();
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };

  const imageQuickLock = (mediaItem, type) => {
    if (
      mediaItem.paid_content === 1 &&
      mediaItem.price > 0 &&
      mediaItem.path.length < 10
    ) {
      unlock(mediaItem.id, type);
      return;
    }
    const mediaPath =
      mediaItem.path.length > 10 ? mediaItem.path : mediaItem.preview_path;
    if (type == 1) {
      setImageVisible(true);
      setQuickImageUrl(mediaPath);
    } else {
      setVideoVisible(true);
      setQuickVideoUrl(mediaPath);
    }
  };

  return (
    <div className="person-container">
      <NavigationBar />
      <div className="person-header-container">
        <Image
          className="person-header-bg"
          src="/images/mine/mine_header_bg.png"
        />
        <Image className="person-header-user-photo" src={userPhoto} />
        <div className="person-header-name">
          {" "}
          {Custom.truncateString(nickname, 8)} {Custom.emojiComponent(emoji)}
        </div>
        <div className="person-header-id">{domainnick}</div>
        <div className="person-header-des">{description}</div>
        <div className="person-header-star">
          <Image
            className="person-header-logo"
            src="/images/person/person_star.png"
          />
          <div>{numberLikes}</div>
        </div>
        <div className="person-header-like">
          <Image
            className="person-header-logo"
            src="/images/person/person_like.png"
          />
          <div>{numberPlay}{t("personal.times")}</div>
        </div>
        <div className="person-header-lnf">
          <Link to={{ pathname: "/share", state: { item } }}>
            <Image
              src={"/images/person/person_icon1.png"}
              className="person-header-lnf-cell"
            />
          </Link>
          <Link to={{ pathname: "/report", state: { item } }}>
          <Image
            src={"/images/person/person_icon2.png"}
            className="person-header-lnf-cell"
          />
          </Link>
          <Link to={{ pathname: "/chat", state: { item } }}>
            <Image
              src={"/images/person/person_icon3.png"}
              className="person-header-lnf-cell"
            />
          </Link>
        </div>

        {isFollow ? (
          <div onClick={follow} className="person-header-follow">
            {t("personal.followed")}
          </div>
        ) : (
          <div onClick={follow} className="person-header-notice">
            {t("personal.follow")}
          </div>
        )}
      </div>

      <Tabs
        className="my-info-media"
        onChange={(key) => {
          getBroadcasterPosts(parseInt(key));
        }}
      >
        <Tabs.Tab title={t("library.picture")+"（" + listInfo?.num_of_pics + ")"} key="1">
          <div className="my-info-list">
            {(imageList ?? []).map((mediaItem) => (
              <div key={mediaItem.id} className="my-info-list-item">
                <Image src={userPhoto} className="my-info-list-item-img" />
                <div className="my-info-list-item-right">
                  <div className="my-info-list-item-title">
                    {mediaItem.content}
                  </div>
                  <Image
                    fit="cover"
                    src={
                      mediaItem.path.length > 10
                        ? mediaItem.path
                        : mediaItem.preview_path
                    }
                    className="my-info-list-item-bigpic"
                    onClick={() => imageQuickLock(mediaItem, 1)}
                  />
                  {mediaItem.paid_content === 1 &&
                  mediaItem.path.length < 10 ? (
                    <div
                      className="my-info-item-lock-container"
                      onClick={() => imageQuickLock(mediaItem, 1)}
                    >
                      <Image
                        className="my-info-item-photo-lock"
                        src={"/images/chat/chat_lock.png"}
                      />
                      <div className="my-info-item-photo-money">
                        <Image
                          className="chat-list-item-photo-money-coin"
                          src={"/images/chat/chat_coin.png"}
                        />
                        <div className="chat-list-item-photo-money-count">
                          {mediaItem.price}
                        </div>
                        <div className="chat-list-item-photo-money-unlock">
                        {t("chat.unlock.now")}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="my-info-list-item-number">
                    <Image
                      className="my-info-list-item-number-logo1"
                      src="/images/person/person_star.png"
                    />
                    <div>{mediaItem?.num_likes}</div>

                    <Image
                      className="my-info-list-item-number-logo2"
                      src="/images/person/person-image-chat.png"
                    />
                    <div>{mediaItem?.num_comments}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Tabs.Tab>
        <Tabs.Tab title={t("library.video")+"" + listInfo?.num_of_vids + ")"} key="0">
          <div className="my-info-list">
            {(videoList ?? []).map((mediaItem) => (
              <div key={mediaItem.id} className="my-info-list-item">
                <Image src={userPhoto} className="my-info-list-item-img" />
                <div className="my-info-list-item-right">
                  <div className="my-info-list-item-title">
                    {mediaItem.content}
                  </div>
                  <video
                    fit="cover"
                    src={
                      mediaItem.path.length > 10
                        ? mediaItem.path
                        : mediaItem.preview_path
                    }
                    className="my-info-list-item-bigpic"
                    onClick={() => imageQuickLock(mediaItem, 0)}
                  />
                  {mediaItem.paid_content === 1 &&
                  mediaItem.path.length < 10 ? (
                    <div
                      className="my-info-item-lock-container"
                      onClick={() => imageQuickLock(mediaItem, 0)}
                    >
                      <Image
                        className="my-info-item-photo-lock"
                        src={"/images/chat/chat_lock.png"}
                      />
                      <div className="my-info-item-photo-money">
                        <Image
                          className="chat-list-item-photo-money-coin"
                          src={"/images/chat/chat_coin.png"}
                        />
                        <div className="chat-list-item-photo-money-count">
                          {mediaItem.price}
                        </div>
                        <div className="chat-list-item-photo-money-unlock">
                        {t("chat.unlock.now")}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="my-info-list-item-number">
                    <Image
                      className="my-info-list-item-number-logo1"
                      src="/images/person/person_star.png"
                    />
                    <div>{mediaItem?.num_likes}</div>

                    <Image
                      className="my-info-list-item-number-logo2"
                      src="/images/person/person-image-chat.png"
                    />
                    <div>{mediaItem?.num_comments}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Tabs.Tab>
      </Tabs>
      <ImageViewer
        classNames={{
          mask: "customize-mask",
          body: "customize-body",
        }}
        image={quickImageUrl}
        visible={imageVisible}
        onClose={() => {
          setImageVisible(false);
        }}
      />

      <Mask visible={videoVisible} onMaskClick={() => setVideoVisible(false)}>
        <video
          fit="cover"
          src={quickVideoUrl}
          className="my-video-style"
          controls
        />
      </Mask>
    </div>
  );
};

export default PersonInfo;
