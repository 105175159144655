import React, { useState } from "react";
import NavigationBar from "../../../uimodules/navbar";
import Valid from "../../../components/custom/valid";
import Request from "../../../components/request/request";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Toast } from "antd-mobile";
import { useTranslation } from 'react-i18next';
import md5 from 'md5';
import "./index.css";
const ResetPasswordSubmit = ({ emailAddress }) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const inParams = location.state;
  console.log(inParams);
  const { email } = inParams;
  const [emailCode, setEmailCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const goBack = () => {};
  const forgotPsw = async () => {
    if (!Valid.isValidPassword(emailCode)) {
      Toast.show({
        content: "Invalid code format！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }
    if (!Valid.isValidPassword(newPassword)) {
      Toast.show({
        content: "Invalid password format！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }
    if (!Valid.isValidPassword(confirmPassword)) {
      Toast.show({
        content: "Invalid confirmed password format！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      Toast.show({
        content: "Two passwords are inconsistent！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }

    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const hashedPassword = md5(newPassword);
      const url = "/api/user/changePassword"; // 设置API端点
      const headers = {};
      const params = { email: email, new_password: hashedPassword , code: emailCode};
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, msg } = result;
      Toast.clear();
      if (code === 200) {
        Toast.show({
          icon: 'success',
          content: 'Password changed successfully!',
        })
        history.push('/login');
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }

  };

  return (
    <div className="container">
      <NavigationBar />
      <div className="passwordsubmit-title">Reset Password</div>

      <input
        className="passwordsubmit-input"
        type="text"
        placeholder="Email Code"
        value={emailCode}
        onChange={(e) => setEmailCode(e.target.value)}
      />

      <input
        className="passwordsubmit-input"
        type="password"
        placeholder="New Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />

      <input
        className="passwordsubmit-input"
        type="password"
        placeholder="Confirm Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />

      <button className="passwordsubmit-submit" onClick={forgotPsw}>
        Submit
      </button>
      <Link to="/login" className="register-login-btn">
      <div className="passwordsubmit-back" onClick={() => goBack()}>
        Back to Login
      </div>
      </Link>
    </div>
  );
};

export default ResetPasswordSubmit;
