import React, { useState, useEffect, useCallback } from "react";
import { Button, Image } from "antd-mobile";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import "./index.css";
const HistoryPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [historyList, setHistoryList] = useState([]);

  const fetchData = async () => {
    try {
      const url = "/api/watchHistory"; // 设置API端点
      const headers = {};
      const params = {
        page: 1,
        limit: 1000,
      };
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      if (code === 200) {
        const { earlier, today, yesterday } = data;
        var array = [];
        if (today && today.length > 0) {
          array.push({
            title: "today",
            list: today,
          });
        }

        if (yesterday && yesterday.length > 0) {
          array.push({
            title: "yesterday",
            list: yesterday,
          });
        }

        if (earlier && earlier.length > 0) {
          array.push({
            title: "earlier",
            list: earlier,
          });
        }

        setHistoryList(array);
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  const searchAct = async() => {
    
    try {
      const url = "/api/searchWatchingHistory"; // 设置API端点
      const headers = {};
      const params = {
        keyword: searchText,
      };
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      if (code === 200) {
        const { earlier, today, yesterday } = data;
        var array = [];
        if (today && today.length > 0) {
          array.push({
            title: "today",
            list: today,
          });
        }

        if (yesterday && yesterday.length > 0) {
          array.push({
            title: "yesterday",
            list: yesterday,
          });
        }

        if (earlier && earlier.length > 0) {
          array.push({
            title: "earlier",
            list: earlier,
          });
        }

        setHistoryList(array);
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  return (
    <div className="history-container">
      <NavigationBar />
      <div className="history-header-container">
        <div className="history-header-title">{t("history.record")}</div>
        <input
          className="history-search-input"
          type="searchText"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onBlur={searchAct}
          placeholder={t("input.search.placehold")}
        />
      </div>

      <div className="history-list">
        {(historyList ?? []).map((item) => (
          <div>
            <div className="history-list-seg">{item.title}</div>
            {(item.list ?? []).map((col) => (
              <div className="history-list-item">
                <Image
                  src={col.bk_pic_path}
                  className="history-list-item-img"
                />
                <div className="history-list-item-title">{col.room_name}</div>
                <Image
                  src={
                    col.is_favorite
                      ? "/images/find/find_star_sel.png"
                      : "/images/find/find_star.png"
                  }
                  className="history-list-item-star"
                />
                <div className="history-item-name">{col.nickname}</div>
                <div className="history-item-star">
                  <Image
                    className="history-item-logo"
                    src="/images/person/person_star.png"
                  />
                  <div>{col.like_count}</div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HistoryPage;
