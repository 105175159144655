import React, { useState, useEffect } from "react";
import { Image, Toast } from "antd-mobile";
import Request from "../../components/request/request";
import { useHistory } from "react-router-dom";
import { NumberKeyboard, PasscodeInput } from "antd-mobile";
import { CloseOutline } from "antd-mobile-icons";
import { useTranslation } from "react-i18next";
import "./index.css";

const PopPwd = ({ popPwdVisiable, setPopPwdVisiable, calllback }) => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const onChange = (value) => {
    
    setError(value.length > 6);
    if (value.length === 6) {
      calllback(value);
      console.log("###############" + value);
    }
  };

  const closePop = () => {
    if (popPwdVisiable) {
      setPopPwdVisiable(false);
    }
  };

  const fetchData = async () => {
    try {
      const url = "/api/getLiveGiftItemsList";
      const headers = {};
      const params = {};
      const result = await Request.get(url, params, headers);
      const { data } = result;
      console.log("data:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  return (
    <div className="live-pop-pwd-container" onClick={closePop}>
      <div className="live-pop-pwd-view" onClick={(e) => e.stopPropagation()}>
        <div className="live-pop-pwd-title">{t("personal.input.paypwd")}</div>
        <div className="live-pop-pwd ">
          <PasscodeInput
            seperated
            onChange={onChange}
            keyboard={<NumberKeyboard />}
            error={error}
          />
        </div>

        <CloseOutline className="live-pop-pwd-close-btn" onClick={closePop} />
      </div>
    </div>
  );
};

export default PopPwd;
