import React, { useState, useEffect } from "react";
import { Button, Image } from "antd-mobile";
import { useHistory } from "react-router-dom";
import {
  LocalUser,
  RemoteUser,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  useRemoteUsers,
  usePublish,
  useJoin,
} from "agora-rtc-react";
import client from "../../components/agora/index";

import AgoraRTC from "agora-rtc-react";
import "./index.css";

const Audience = ({
  appid,
  channel,
  token,
  uid,
  role,
  roomid,
  playbtntitle,
  stopbtntitle,
  callingON,
}) => {
  const [calling, setCalling] = useState(callingON);

  const [micOn, setMic] = useState(false);
  const [cameraOn, setCamera] = useState(false);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);
  usePublish([localMicrophoneTrack, localCameraTrack]);
  const remoteUsers = useRemoteUsers();

  useJoin(
    { appid: appid, channel: channel, token: token ? token : null, uid },
    callingON
  );

  const callAct = () => {
    console.log("################onClick");
    // AgoraRTC.on("camera-changed", (info) => {
    //   console.log("################Camera changed!", info.state, info.device);
    // });

    if (!calling) {
      console.log("##############appid=" + appid);
      console.log("##############channel=" + channel);
      console.log("##############token=" + token);
      console.log("##############uid=" + uid);
      console.log("##############role=" + role);
      client.setClientRole(role, 1);
      client.join(appid, channel, token, uid);
    }
    setCalling(!calling);
  };
  return (
    <div className="audience-container">
      <div className="audience-show-remote">
        {remoteUsers.map((user) => (
          <div className="audience-show-remote-item" key={user.uid}>
            <RemoteUser
              className="audience-show"
              cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
              user={user}
            >
              {/* <samp className="user-name">{user.uid}</samp> */}
            </RemoteUser>
          </div>
        ))}
      </div>
      {/* <div className="audience-show-info">
        <div>roomid:{roomid}</div>
        <div>appid:{appid}</div>
        <div>channel:{channel}</div>
        <div>token:{token}</div>
        <div>uid:{uid}</div>
        <div>role:{role}</div>
      </div> */}

      {/* <Button className="audience-btn" onClick={callAct}>
        {calling ? stopbtntitle : playbtntitle}
      </Button> */}
    </div>
  );
};

export default Audience;
