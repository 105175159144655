import React, { useState, useEffect, useCallback } from "react";
import { Button, Image } from "antd-mobile";
import Request from "../../../components/request/request";
import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./index.css";
const PriceAlert = ({ setAlertVisiable, userId }) => {
  const history = useHistory();
  const [priceList, setPriceList] = useState([]);
  const { t } = useTranslation();
  const fetchData = async () => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/1v1/get1v1Setting"; // 设置API端点
      const headers = {};
      const params = {
        broadcaster_account_id: userId,
      };
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      Toast.clear();
      if (code === 200) {
        setPriceList(data);
      }else{
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
  };
  const setInputValue = async (value,index) => {
 
    const updatedItems = priceList.map((item, idx) => {
      if (idx === index) {
        return { ...item, amount: value }; // Only update the item at the specified index
      }
      return item; // Return all other items unchanged
    });

    // Update the state with the new array
    setPriceList(updatedItems);
  }
  const submit = async () => {
    

    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/1v1/set1v1Setting"; // 设置API端点
      const headers = {};
      const result = await Request.post(url, priceList, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      Toast.clear();
      if (code === 200) {
        setPriceList(data);
        setAlertVisiable(false);
      }else{
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
    console.log(JSON.stringify(priceList));
    
  }

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  return (
    <div className="price-alertcontainer" onClick={() => setAlertVisiable(false)}>
      <Image src="/images/live/live_price_bg.png" className="price-alertbg" />

      <div className="price-alertmain" onClick={(e) => e.stopPropagation()}  >
        <Image
          src="/images/live/live-invite1v1.png"
          className="price-alertmain-top-img"
        />

        <div className="price-alertmain-top-title">{t("show.edit.price")}</div>

        <div className="price-alertmain-price">
          {(priceList ?? []).map((item, index) => (
            <div className="price-alertmain-price-item">
              <input
                className="price-alertmain-price-item1"
                type="searchText"
                value={item?.amount}
                onChange={(e) => setInputValue(e.target.value,index)}
                placeholder="0"
              />
              <div className="price-alertmain-price-item2"></div>

              <div className="price-alertmain-price-item3">
                {item?.time_duration}min
              </div>
            </div>
          ))}
        </div>
        <div className="price-alertmain-sure" onClick={submit}>{t("show.sure")}</div>
      </div>
    </div>
  );
};

export default PriceAlert;
