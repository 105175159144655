import React, { useState, useEffect, useCallback } from "react";
import { Button, Image } from "antd-mobile";
//import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
//import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./index.css";
const Demo = () => {
  const history = useHistory();

  const [messageList] = useState([
    { title: "系统公告", icon: "/images/mine/mine_person.png" },
    { title: "站内信", icon: "/images/mine/mine_earn.png" },
    { title: "麻痹讲", icon: "/images/mine/mine-person_cer.png" },
    { title: "潍坊潍坊", icon: "/images/mine/mine_share.png" },
    { title: "fsdfd", icon: "/images/mine/mine_tag.png" },
    { title: "sfdsf", icon: "/images/mine/mine_fqa.png" },
    { title: "fsfd", icon: "/images/mine/mine_setting.png" },
  ]);

  useEffect(() => {
    return () => {};
  }, []);

  const pay = () => {
    history.push("/pay");
  };

  const logout = () => {
    history.push("/home");
  };

  return (
    <div className="demo-container">
      <NavigationBar navTitle={"消息"} />
      <AudioPlayer
        className="demo-list"
        autoPlay
        src="/mp3/三字经.mp3"
        onPlay={(e) => console.log("onPlay")}
      />
      {/* <div className="demo-list">
        {(messageList ?? []).map((item) => (
          <div className="demo-list-item">
            <Image src={item.icon} className="demo-list-item-logo" />
            <div className="demo-list-item-text">
              <div className="demo-list-item-title">{item.title}</div>
              <div className="demo-list-item-content">
                <div>{item.title}</div>
                <div className="demo-list-item-content-time">10:11</div>
              </div>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Demo;
