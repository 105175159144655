import React, { useState, useEffect, useCallback } from "react";
import { Button, Image } from "antd-mobile";
//import Request from "../../components/request/request";
import NavigationBar from "../../../uimodules/navbar";
//import { Toast } from "antd-mobile";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./index.css";
const PaySuccess = () => {
  const history = useHistory();
  const location = useLocation();
  const successData = location.state?.successData;
  const { to_transfer } = successData || {};
  const { id, bill_time } = to_transfer || {};
  const { t } = useTranslation();
  const goBack = () => {

    history.replace('/home');
    history.push('/mine');
  };
  return (
    <div className="success-container">
      <NavigationBar />

      <div className="success-title-pay">
        <Image src="/images/recharge/pay_success.png" className="success-logo" />
        <div className="success-title-begin"> 充值成功</div>
      </div>
      <div className="success-order">订单ID：{id}</div>
      <div className="success-order-time">订单时间：{bill_time}</div>

      <div className="success-sure" onClick={goBack}>
        返回
      </div>
    </div>
  );
};

export default PaySuccess;
