import React, { useState } from "react";
import { Checkbox } from "antd-mobile";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import NavigationBar from "../../../uimodules/navbar";
import Valid from "../../../components/custom/valid";
import Request from "../../../components/request/request";
import { Toast, Image } from "antd-mobile";
import FireBaseConfiger from "../../../components/firebase/index";
import { useTranslation } from 'react-i18next';
import "./index.css";
const RegistPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [promocode, setPromocode] = useState("");
  // const [agree1, setAgree1] = useState(false);
  // const [agree2, setAgree2] = useState(false);
  // const [agree3, setAgree3] = useState(false);
  const signInWithGoogle = async () => {
    // if (!agree1 || !agree2 || !agree3) {
    //   Toast.show({
    //     content: "Please agree to the AI Me Tos, Privacy and 18+！",
    //     maskClickable: false,
    //     position: "bottom",
    //   });
    //   return;
    // }
    const { code } = await FireBaseConfiger.login(promocode);
    console.log("##############signInWithGoogle");
    if (code === 200) {
      window.selectTab = "home";
      history.push("/home");
    }
  };
  const sendEmailCode = async () => {
    if (!Valid.isValidEmail(email)) {
      Toast.show({
        content: "Invalid email format！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }
    if (!Valid.isValidPassword(password)) {
      Toast.show({
        content: "Invalid password format！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }
    if (!Valid.isValidPassword(repassword)) {
      Toast.show({
        content: "Invalid confirmed password format！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }
    if (password !== repassword) {
      Toast.show({
        content: "Two passwords are inconsistent！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }
    // if (!agree1 || !agree2 || !agree3) {
    //   Toast.show({
    //     content: "Please agree to the AI Me Tos, Privacy and 18+！",
    //     maskClickable: false,
    //     position: "bottom",
    //   });
    //   return;
    // }
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/user/sendEmailCode"; // 设置API端点
      const headers = {};
      const params = { email: email, type: "email_register" };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, msg, data } = result;
      console.log("data:", data);
      Toast.clear();
      if (code === 200) {
        const params = {
          email: email,
          password: password,
          inviteCode: promocode,
        };
        var path = { pathname: "/emailcode", state: params };
        console.log(path);
        history.push(path);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  return (
    <div className="container">
      <NavigationBar />
      <div className="register-title">
        <div>Sign up or log in</div>
      </div>

      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        className="register-email"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        className="register-input"
      />
      <input
        type="password"
        value={repassword}
        onChange={(e) => setRepassword(e.target.value)}
        placeholder="Confirm Password"
        className="register-input"
      />
      <input
        type="text"
        value={promocode}
        onChange={(e) => setPromocode(e.target.value)}
        placeholder="Invitation Code"
        className="register-input"
      />
      {/* <div className="register-check">
        <Image
          src={
            agree1
              ? "/images/login/login_checkbox_sel.png"
              : "/images/login/login_checkbox.png"
          }
          className="register-check-box"
          checked={agree1}
          onClick={() => {
            setAgree1(!agree1);
          }}
        />
        <Link to="/termsofuse">
          <div className="register-check-text-privacy">Terms</div>
        </Link>
        <Image
          src={
            agree2
              ? "/images/login/login_checkbox_sel.png"
              : "/images/login/login_checkbox.png"
          }
          className="register-check-box"
          checked={agree2}
          onClick={() => {
            setAgree2(!agree2);
          }}
        />
        <Link to="/privacy">
          <div className="register-check-text-privacy">Privacy</div>
        </Link>
        <Image
          src={
            agree3
              ? "/images/login/login_checkbox_sel.png"
              : "/images/login/login_checkbox.png"
          }
          className="register-check-box"
          checked={agree3}
          onClick={() => {
            setAgree3(!agree3);
          }}
        />
          <div className="register-check-text-privacy">18+</div>
 
      </div> */}
      <button onClick={sendEmailCode} className="register-btn">
        Create account
      </button>
      <div className="register-or">or</div>
      <div className="register-google-btn" onClick={signInWithGoogle}>
        <div className="register-google-btn-contain">
          <Image
            className="register-google-img"
            src="/images/login/login_google.png"
          />
          <div>Continue with Google</div>
        </div>
      </div>

      <Link to="/login" className="register-login-btn">
        <div style={{ color: "white", paddingRight: "3px" }}>
          Already have an account?
        </div>
        Log in
      </Link>

      <div className="register-check-text">
        By logging in, you confirm you're over 18 years old and agree to our
        <Link to="/termsofuse">
          {" "}
          <span className="register-check-text-privacy"> Terms of Use </span>
        </Link>{" "}
        and{" "}
        <Link to="/privacy">
          <span className="register-check-text-privacy"> Privacy Policy </span>
        </Link>
      </div>
    </div>
  );
};

export default RegistPage;
