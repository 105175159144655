import "./index.css";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import NavigationBar from "../../../uimodules/navbar";
import Valid from "../../../components/custom/valid";
import Request from "../../../components/request/request";
import { Toast } from "antd-mobile";
import { useTranslation } from 'react-i18next';
const ResetPassword = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const handleSendEmail = async () => {
    if (!Valid.isValidEmail(email)) {
      Toast.show({
        content: "Invalid email format！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/user/sendEmailCode"; // 设置API端点
      const headers = {};
      const params = { email: email, type: "change_password" };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, msg, data } = result;
      console.log("data:", data);
      Toast.clear();
      if (code === 200) {
        const params = {
          email: email,
        };
        var path = { pathname: "/passwordsubmit", state: params };
        console.log(path);
        history.push(path);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
    

  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="container">
      <NavigationBar />
      <div className="resetpwd-title">Reset Password</div>
      <input
        type="email"
        placeholder="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="resetpwd-email"
      />
      <button onClick={handleSendEmail} className="resetpwd-send-email">
        Reset Password
      </button>
      <div onClick={handleGoBack} className="resetpwd-cancel">
        Cancel
      </div>
    </div>
  );
};

export default ResetPassword;
