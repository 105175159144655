import React, { useState, useEffect, useCallback } from "react";
import { Tag } from "antd-mobile";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { Toast, Tabs } from "antd-mobile";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./index.css";
const Tags = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [tagMap, setTagMap] = useState({});
  const [likeTagMap, setLikeTagMap] = useState({});
  const [userTagList, setUserTagList] = useState([]);

  const [likeTagList, setLikeTagList] = useState([]);
  const [type, setType] = useState([]);
  const fetchData = async (listMap1,listMap2) => {
    
    try {
      const userdata = localStorage.getItem("userdata");
      let userId = "";
      if (userdata) {
        const userInfo = JSON.parse(userdata) || {};
        const { account } = userInfo || {};
        const { id } = account || {};
        userId = id;
        console.log(userInfo);
      }
      const url = "/api/getUserTagsList"; // 设置API端点
      const headers = {};
      const params = {
        account_id: userId,
      };
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { code, data } = result;
      let userTagMap = {};
      let likeTagMap = {};
      if (code === 200) {
        const { like_tags, user_tags } = data;
        let tagList = user_tags ? user_tags : [];
        let likeList = like_tags ? like_tags : [];
        setUserTagList(tagList);
        setLikeTagList(likeList);
        tagList.forEach((item) => {
          userTagMap[item.code] = item.param_name;
          console.log(`1代码: ${item.code}, 标签: ${item.param_name}`); // 打印每个项目的代码和标签名
        });

        likeList.forEach((item) => {
          likeTagMap[item.code] = item.param_name;
          console.log(`1代码: ${item.code}, 标签: ${item.param_name}`); // 打印每个项目的代码和标签名
        });
        console.log(likeList);
        Object.entries(listMap1 ? listMap1 : {}).forEach(
          ([category, items]) => {
            console.log(`分类: ${category}`); // 打印分类名
            items.forEach((item) => {
              let value = userTagMap[item.code];
              if (value && value.length > 0) {
                item.isSelected = true;
              } else {
                item.isSelected = false;
              }
              console.log(`代码: ${item.code}, 标签: ${item.tag_name}`); // 打印每个项目的代码和标签名
            });
          }
        );

        Object.entries(listMap2 ? listMap2 : {}).forEach(
          ([category, items]) => {
            console.log(`分类: ${category}`); // 打印分类名
            items.forEach((item) => {
              let value = likeTagMap[item.code];
              if (value && value.length > 0) {
                item.isSelected = true;
              } else {
                item.isSelected = false;
              }
              console.log(`代码: ${item.code}, 标签: ${item.tag_name}`); // 打印每个项目的代码和标签名
            });
          }
        );
        Toast.clear();
        // setLikeTagList(liked_tags ? liked_tags : []);
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
      Toast.clear();
    }
  };

  const getTagsList = async () => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/getTagsList"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { code, data } = result;
      if (code === 200) {
       var listMap1 = structuredClone(data);
       var listMap2 = structuredClone(data);
   
        setTagMap(listMap1);
        setLikeTagMap(listMap2);
        fetchData(listMap1,listMap2);
      }else{
        Toast.clear();
      }

    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
  };

  const userAddRemoveTag = async (tag) => {
    Toast.show({
      icon: "loading",
      content:t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/userAddRemoveTag"; // 设置API端点
      const headers = {};
      const params = {
        tag_code: tag.code,
        tag_type: parseInt(type)
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, data } = result;
      if (code === 200) {
 
        fetchData();
        tag.isSelected = !tag.isSelected;
      } else{
        Toast.clear();
      }

    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
  };

  useEffect(() => {
    getTagsList();
    return () => {};
  }, []);
  return (
    <div className="tags-container">
      <NavigationBar navTitle={t("tags.title")} />

      <Tabs className="tags-header" onChange={(key) => {
        setType(key);
      }}>
        <Tabs.Tab title={t("tags.self")} key="0">
          <div className="tags-title">{t("tags.self")}</div>
          <div className="tags-list">
            {(userTagList ?? []).map((item) => (
              <div
                className="tags-item-sel"
                onClick={() => userAddRemoveTag(item)}
              >
                {item.param_name}
              </div>
            ))}
          </div>
          <div className="tags-title">{t("tags.select")}</div>

          {Object.entries(tagMap).map(([category, tags]) => (
            <div className="tags-choose">
              <div className="tags-subtitle">{category}</div>
              <div className="tags-list">
                {(tags ?? []).map((tag) => (
                  <div
                    key={tag.code}
                    className={tag.isSelected ? "tags-item-sel" : "tags-item"}
                    onClick={() => userAddRemoveTag(tag)}
                  >
                    {tag.tag_name}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Tabs.Tab>
        <Tabs.Tab title={t("tags.like")} key="1">
          <div className="tags-title">{t("tags.like")}</div>
          <div className="tags-list">
            {(likeTagList ?? []).map((item) => (
              <div
                className="tags-item-sel"
                onClick={() => userAddRemoveTag(item)}
              >
                {item.param_name}
              </div>
            ))}
          </div>
          <div className="tags-title">{t("tags.select")}</div>

          {Object.entries(likeTagMap).map(([category, tags]) => (
            <div className="tags-choose">
              <div className="tags-subtitle">{category}</div>
              <div className="tags-list">
                {(tags ?? []).map((tag) => (
                  <div
                    key={tag.code}
                    className={tag.isSelected ? "tags-item-sel" : "tags-item"}
                    onClick={() => userAddRemoveTag(tag)}
                  >
                    {tag.tag_name}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Tabs.Tab>
      </Tabs>
    </div>
  );
};

export default Tags;
