class Custom {

  static emojiComponent(emojiUnicode) {
    if (!emojiUnicode || emojiUnicode.length === 0) {
      return "";
    }
    const unicodePoints = emojiUnicode.replace(/U\+/g, "").split(" ");
    const emoji = String.fromCodePoint(
      ...unicodePoints.map((u) => parseInt(u, 16))
    );
    return emoji;
  };

  static truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "..."; // 截取并添加省略号
    }
    return str; // 如果不超过最大长度，则返回原字符串
  };

  static changeToLocal (utcTimestamp) {
    const date = new Date(utcTimestamp);

    // 手动格式化日期和时间
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");

    // 组合为"年-月-日 时:分"格式
    const localDateTime = `${year}-${month}-${day} ${hour}:${minute}`;

    return localDateTime;
  };
}

export default Custom;
