const baseUrl = "https://onlyforyou.live";
//const baseUrl = "http://4ec1260e.r25.cpolar.top";

class Request {
  static async get(url, params, headers = {}) {
    try {
      if (params) {
        const queryString = new URLSearchParams(params).toString();
        url = url + `?${queryString}`;
      }
      var modifiedHeaders = headers || {};
      modifiedHeaders["Content-Type"] = "application/json;charset=UTF-8";
      const authorization = localStorage.getItem("authorization") || "";
      modifiedHeaders["authorization"] = "Bearer " + authorization;
      modifiedHeaders["Language"] = localStorage.getItem("language") || "en-US";
      const response = await fetch(baseUrl + url, {
        method: "GET",
        headers: modifiedHeaders,
        //mode: 'no-cors'
      });

      const statusCode = response.status;
      const responseBody = await response.json();
      const { code } = responseBody;
      console.log(
        `[url=${baseUrl}${url.toString()}][statusCode=${statusCode}][response=${JSON.stringify(
          responseBody
        )}]`
      );
      if (code === 401) {
        window.location.href = "/login";
      } else {
        return responseBody;
      }
    } catch (error) {
      console.error(
        `[url=${baseUrl}${url.toString()}]exception e=${error.toString()}`
      );
      return { code: 1, msg: "Return data format error!" };
    }
  }
  

  static async post(url, params, headers) {
    try {
      var modifiedHeaders = headers || {};
      modifiedHeaders["Content-Type"] = "application/json;charset=UTF-8";
      const authorization = localStorage.getItem("authorization") || "";
      modifiedHeaders["authorization"] = "Bearer " + authorization;
      modifiedHeaders["Language"] = localStorage.getItem("language") || "en-US";
      //console.log("authorization==" + JSON.stringify(modifiedHeaders));

      const response = await fetch(baseUrl + url, {
        method: "POST",
        headers: modifiedHeaders,
        body: JSON.stringify(params),
        //mode: 'no-cors'
      });
      const statusCode = response.status;
      const responseBody = await response.json();
      const { code } = responseBody;
      console.log(
        `[url=${baseUrl}${url}][statusCode=${statusCode}][response=${JSON.stringify(
          responseBody
        )}]`
      );
      if (code === 401) {
        window.location.href = "/login";
      } else {
        return responseBody;
      }
    } catch (error) {
      console.error(`[url=${baseUrl}${url}]exception e=${error.toString()}`);
      return { code: 1, msg: error.toString() };
    }
  }

  static async postFormData(url, params, headers, file) {
    try {
      var modifiedHeaders = headers || {};
      const authorization = localStorage.getItem("authorization") || "";
      modifiedHeaders["authorization"] = "Bearer " + authorization;
      modifiedHeaders["Language"] = localStorage.getItem("language") || "en-US";
      const formData = new FormData();

      if (file) {
        formData.append("file", file);
      }
      if (params) {
        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            formData.append(key, params[key]);
          }
        }
      }
      console.log('################'+JSON.stringify(params));
      console.log('################'+JSON.stringify(modifiedHeaders));
      const response = await fetch(baseUrl + url, {
        method: "POST",
        headers: modifiedHeaders,
        body: formData,
      });

      const statusCode = response.status;
      const responseBody = await response.json();
      const { code } = responseBody;
      console.log(
        `[url=${baseUrl}${url}][statusCode=${statusCode}][response=${JSON.stringify(
          responseBody
        )}]`
      );
      if (code === 401) {
        window.location.href = "/login";
      } else {
        return responseBody;
      }
    } catch (error) {
      console.error(`[url=${baseUrl}${url}]exception e=${error.toString()}`);
      return { code: 1, msg: error.toString() };
    }
  }

  static async postData(url, params, headers, files) {
    try {
      var modifiedHeaders = headers || {};
      const authorization = localStorage.getItem("authorization") || "";
      modifiedHeaders["authorization"] = "Bearer " + authorization;
      modifiedHeaders["Language"] = localStorage.getItem("language") || "en-US";
      const formData = new FormData();

      files.forEach((item, index) => {
        formData.append( item['name'], item['file']);
      });
 
      if (params) {
        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            formData.append(key, params[key]);
          }
        }
      }
      console.log('################'+JSON.stringify(params));
      console.log('################'+JSON.stringify(modifiedHeaders));
      const response = await fetch(baseUrl + url, {
        method: "POST",
        headers: modifiedHeaders,
        body: formData,
      });

      const statusCode = response.status;
      const responseBody = await response.json();
      const { code } = responseBody;
      console.log(
        `[url=${baseUrl}${url}][statusCode=${statusCode}][response=${JSON.stringify(
          responseBody
        )}]`
      );
      if (code === 401) {
        window.location.href = "/login";
      } else {
        return responseBody;
      }
    } catch (error) {
      console.error(`[url=${baseUrl}${url}]exception e=${error.toString()}`);
      return { code: 1, msg: error.toString() };
    }
  }
}

export default Request;
