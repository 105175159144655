import React, { useState, useEffect } from "react";
import { Image, Toast } from "antd-mobile";
import Request from "../../../components/request/request";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./index.css";

const PopView = ({
  accountId,
  sendGift,
  popVisiable,
  setPopVisiable,
  setPopPwdVisiable,
}) => {
  const history = useHistory();
  const [giftList, setGiftList] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [selectIndex, setSelectIndex] = useState(0);
  const { t } = useTranslation();
  const touchBg = () => {
    if (popVisiable) {
      setPopVisiable(false);
    }
  };

  const recharge = () => {
    history.push("/recharge");
  };

  const fetchData = async () => {
    try {
      const url = "/api/getLiveGiftItemsList";
      const headers = {};
      const params = {};
      const result = await Request.get(url, params, headers);
      const { data } = result;
      console.log("data:", data);
      setGiftList(data);
    } catch (error) {
      console.error("Error:", error);
    }

    try {
      const url = "/api/user/info"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data } = result;
      setUserInfo(data);
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  const createOrder = async () => {
    var giftItem = giftList[selectIndex];
    const { id, amount } = giftItem;
    console.log("######################" + JSON.stringify(giftItem));
    Toast.show({
      icon: "loading",
      content:t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/transfers";
      const headers = {};
      const params = {
        to_account_id: accountId,
        amount: amount,
        item_id: id,
      };
      const result = await Request.post(url, params, headers);
      Toast.clear();
      console.log(result);
      const { data, code, msg } = result;
      if (code === 200) {
        sendGift(giftItem.image);
      }
      Toast.show({
        content: msg,
        maskClickable: false,
        position: "bottom",
      });

      console.log("data:", data);
    } catch (error) {
      console.error("Error:", error);
      Toast.clear();
      Toast.show({
        content: error.message,
        maskClickable: false,
        position: "bottom",
      });
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  return (
    <div className="live-pop-container" onClick={touchBg}>
      <div className="live-pop-view" onClick={(e) => e.stopPropagation()}>
        <div className="live-pop-list">
          {(giftList ?? []).map((item, index) => (
            <div
              key={item.id}
              className={
                index === selectIndex ? "live-pop-item" : "live-pop-item-normal"
              }
              onClick={() => setSelectIndex(index)}
            >
              <Image
                className="live-pop-image"
                src={"/svga/" + item.image + ".png"}
              />
              <div className="live-pop-price">
                <Image
                  className="live-pop-price-logo"
                  src="/images/mine/mine-money-coin.png"
                />
                <div>{item.amount}</div>
              </div>
            </div>
          ))}
        </div>

        <div className="live-pop-bottom">
          <div className="live-pop-left" onClick={recharge}>
            <Image
              className="live-pop-money-coin"
              src="/images/mine/mine-money-coin.png"
            />
            <div className="live-pop-money-number">
              {userInfo.scores_balance}
            </div>
            <Image
              className="live-pop-money-arrow"
              src="/images/live/live_arrow.png"
            />
          </div>
          <Image
            className="live-pop-image-send"
            src="/images/live/live_send.png"
            onClick={createOrder}
          />
        </div>
      </div>
    </div>
  );
};

export default PopView;
