import React, { useState, useEffect } from "react";
import { Image, TabBar } from "antd-mobile";
import { Button, Popover, Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import "./index.css";

const TabBarView = ({ onTabChange }) => {
  const history = useHistory();
  const location = window.location;
  const { pathname } = location;
  const homeKey = "home";
  const addKey = "add";
  const findKey = "find";
  const [selectedTab, setSelectedTab] = useState(homeKey);
 
  const setRouteActive = (value) => {
    console.log("##########123" + value);
    
    if (value !== addKey) {
      history.push(value);

      setSelectedTab(value);
      window.selectTab = value;
    }

    if (onTabChange) {
      onTabChange(value);
    }
  };

  useEffect(() => {
    if (!window.selectTab) {
      window.selectTab = homeKey;
    }
    setSelectedTab(window.selectTab);
  }, [selectedTab]);

  const tabs = [
    {
      key: homeKey,
      title: "首页",
      icon:
        selectedTab === homeKey ? (
          <Image
            width={28}
            height={28}
            src="/images/tabbar_home_selected.png"
          />
        ) : (
          <Image width={26} height={26} src="/images/tabbar_home.png" />
        ),
    },
    {
      key: addKey,
      title: "新增",
      icon: <Image width={28} height={24} src="/images/tabbar_add.png" />,
    },
    {
      key: findKey,
      title: "发现",
      icon:
        selectedTab === findKey ? (
          <Image
            width={28}
            height={28}
            src="/images/tabbar_find_selected.png"
          />
        ) : (
          <Image width={26} height={26} src="/images/tabbar_find.png" />
        ),
    },
  ];

  return (
    <div className="tabbar-container">
        <TabBar
          activeKey={pathname}
          onChange={(value) => setRouteActive(value)}
        >
          {tabs.map((item) => (
            <TabBar.Item
              key={item.key}
              icon={item.icon}
              selectedIcon={item.selicon}
              title=""
            />
          ))}
        </TabBar>
    </div>
  );
};

export default TabBarView;
