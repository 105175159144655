import React, { useState, useEffect, useRef } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { Toast, Image } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./index.css";
const Recharge = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [chargeNumber, setChargeNumber] = useState("");
  const [chargeInfo, setChargeInfo] = useState("");
  const [paypalOrder, setPaypalOrder] = useState("");
  const chargeNumberRef = useRef(chargeNumber);

  const initialOptions = {
    clientId:
      "AW4wDw_5z3KFfVgMKkBZdTviVhQ68qeDpxbKSZ_N96UiFsGZtZdXgOLnxR7l2E_7RK6epEhv08pF2_hd",
  };
  var paypalorder = "";

  const fetchData = async () => {
    try {
      const url = "/api/getExchangeRate"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { data } = result;
      setChargeInfo(data);
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  useEffect(() => {
    chargeNumberRef.current = chargeNumber;
  }, [chargeNumber]);

  const createOrder = async () => {
    const currentChargeNumber = chargeNumberRef.current;
    console.log("~~~~~~~~~~~~~~~~~~~~~" + currentChargeNumber);
    try {
      Toast.show({
        icon: "loading",
        content: t("toast.title.loading"),
        duration: 0,
        maskClickable: false,
      });
      const url = "/api/paypal/createOrder"; // 设置API端点
      const headers = {};
      const params = {
        amount: currentChargeNumber.toString(),
        currency: "USD",
      };
      console.log("~~~~~~~~~~~~~~~~~~~~~" + JSON.stringify(params));
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data } = result;
      const { paypal_order } = data;
      Toast.clear();
      console.log(paypal_order);
      setPaypalOrder(paypal_order);
      paypalorder = paypal_order;
      return paypal_order;
    } catch (error) {
      console.error("##########Error:", error); // 打印错误信息';
      Toast.clear();
    }
  };

  const onApprove = async () => {
   
    try {
      Toast.show({
        icon: "loading",
        content: t("toast.title.loading"),
        duration: 0,
        maskClickable: false,
      });
      const url = "/api/paypal/captureOrder"; // 设置API端点
      const headers = {};
      const params = { order_id: paypalorder };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, msg } = result;
      Toast.clear();
      if (parseInt(code) === 200) {
        verifyOrder();
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
        return "";
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息';
      Toast.clear();
      return "";
    }
  };

  const verifyOrder = async () => {
    try {
      Toast.show({
        icon: "loading",
        content: t("toast.title.loading"),
        duration: 0,
        maskClickable: false,
      });
      const url = "/api/paypal/verifyOrder"; // 设置API端点
      const headers = {};
      const params = { order_id: paypalorder };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, data, msg } = result;
      Toast.clear();
      if (parseInt(code) === 200) {
        history.push("paysuccess", { successData: data });
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
        return "";
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息';
      Toast.clear();
      return "";
    }
  };

  const onCancel = (data) => {};

  return (
    <div className="recharge-container">
      <NavigationBar navTitle={t("mine.topup")} />
      <div className="recharge-title">{t("recharge.subtitle")}：</div>
      <div className="recharge-content">{chargeInfo}</div>

      <div className="recharge-item">
        <input
          className="recharge-input"
          value={chargeNumber}
          onChange={(e) => setChargeNumber(e.target.value)}
          placeholder={t("recharge.notice")}
          type="number"
        />
        <Image
          src="/images/recharge/recharge_big_coin.png"
          className="recharge-input-coin"
        />
      </div>

      <div className="recharge-title1">{t("recharge.quick.input")}：</div>
      <div className="recharge-tags">
        <div className="recharge-tag" onClick={() => setChargeNumber(10)}>
          10
        </div>
        <div className="recharge-tag" onClick={() => setChargeNumber(20)}>
          20
        </div>
        <div className="recharge-tag" onClick={() => setChargeNumber(50)}>
          50
        </div>
        <div className="recharge-tag" onClick={() => setChargeNumber(100)}>
          100
        </div>
        <div className="recharge-tag" onClick={() => setChargeNumber(200)}>
          200
        </div>
        <div className="recharge-tag" onClick={() => setChargeNumber(1000)}>
          1000
        </div>
      </div>

      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            layout: "horizontal",
            shape: "rect",
            color: "blue",
          }}
          className="recharge-paypal"
          createOrder={createOrder}
          onApprove={onApprove}
          onCancel={onCancel}
        />
      </PayPalScriptProvider>

      {/* <div className="recharge-next" onClick={createOrder}>
        {t("btn.next")}
      </div> */}
    </div>
  );
};

export default Recharge;
