class Valid {
  static isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    return emailRegex.test(email);
  }

  static isValidPassword(password) {
    return password.length >= 6;
  }
}

export default Valid;
