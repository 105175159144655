import React, { useState, useEffect, useRef } from "react";
import Request from "../../components/request/request";
import { Toast, Image } from "antd-mobile";
import { useHistory } from "react-router-dom";
import Live from "../live/index";
import Host from "../host/index";
import { useLocation } from "react-router-dom";
import { AgoraRTCProvider } from "agora-rtc-react";
import client from "../../components/agora/index";
import AgoraRTC from "agora-rtc-react";
import ChatScroll from "../../uimodules/chatscroll/index";
import { Downloader, Parser, Player } from "svga-web";
import InviteAlert from "./invitealert/index";
import FaceCode from "./facecode/index";
import { useTranslation } from 'react-i18next';
import "./index.css";

const LiveInput = () => {
  const liveRef = useRef(null);
  const chatRef = useRef(null);
  const history = useHistory();
  const [agoraToken, setAgoraToken] = useState({});
  const [liveInfo, setLiveInfo] = useState({});
  const [userPhoto, setUserPhoto] = useState("");
  const [account, setAccount] = useState({});
  const [calling, setCalling] = useState(false);
  const [chatroomId, setChatroomId] = useState("");
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [giftPlaying, setGiftPlaying] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inviteAlertVisiable, setInviteAlertVisiable] = useState(false);
  const [faceAlertVisiable, setFaceAlertVisiable] = useState(false);
  const [userNumber, setUserNumber] = useState(1);
  const [priceItem, setPriceItem] = useState({});
  const [entryId, setEntryId] = useState({});
  const [faceCode, setFaceCode] = useState("");
  const { t } = useTranslation();

  const location = useLocation();
  const paramsDict = location.state;

  const startTimer = () => {
    setIsRunning(true);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  const resetTimer = () => {
    setTime(0);
    setIsRunning(false);
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    console.log("####################paramsDict" + JSON.stringify(paramsDict));

    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [isRunning]);

  const handleGoBack = () => {
    leaveChatRoom();
    closeLive();
  };

  const gotoUserList = () => {
    history.push("/userlist", { liveId: liveInfo.id });
  };

  const closeAgora = async () => {
    stopTimer();
    setCalling(false);
    leaveTruck();
  };

  const closeLive = async () => {
    Toast.show({
      icon: "loading",
      content: t("toast.close"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/live/closeLiveRoom-v2"; // 设置API端点
      const headers = {};
      const params = {
        live_id: liveInfo.id,
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data, msg, code } = result;
      if (code !== 200) {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
      Toast.clear();
      closeAgora();
      window.selectTab = "home"
      history.push("/home");
      
    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
  };

  const fetchData = async (reload) => {
    try {
      let url = "/api/live/createLiveRoom"; // 设置API端点
      const headers = {};
      let params = {
        room_name: paramsDict.roomName,
        one_on_one_open: paramsDict.oneVOne ? 1 : 0,
      };
      if(paramsDict.isFaceLive){
        url = "/api/face-swapping/new";
      }

      if (paramsDict.isUncloseLive || reload) {
        url = "/api/live/return-live";
        params = {};
      } else if (paramsDict.isShow) {
        url = "/api/private-show/preview-to-live";
        params = {};
      }

      const result = await Request.post(url, params, headers);
      const { data, code, msg } = result;
      if (code === 200) {
        const { agora_token, live } = data;
        const { chat_room_id } = live;
        setChatroomId(() => chat_room_id);
        setChatroomId((preChatroomId) => {
          console.log("#########preChatroomId", preChatroomId); // 输出true
          return preChatroomId;
        });

        setAgoraToken(agora_token || "");
        setLiveInfo(live || "");
        if (!paramsDict.isFaceLive) {

          client.setClientRole("host", 2);
          AgoraRTC.setLogLevel(2);
  
          console.log(
            "##########chat_room_id:",
            chat_room_id + "||||||" + chatroomId
          );
  
          setCalling(true);
          startTimer();
        } else {
          const { live_stream_code } = data;
          setFaceCode(live_stream_code);
          setFaceAlertVisiable(true);
        }
        if (chatRef.current) {
          chatRef.current.easeUserToken();
        }
      } else if (code === 400) {
        fetchData(true);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  const readLocal = () => {
    const data = localStorage.getItem("userdata");
    if (data) {
      const userInfo = JSON.parse(data) || {};
      const { account } = userInfo || {};
      const { avatar_path } = account || {};
      console.log(userInfo);
      setUserPhoto(avatar_path);
      setAccount(account);
    }
  };

  const openBeauty = () => {
    if (liveRef.current) {
      liveRef.current.openBeauty();
    }
  };

  const changeCamera = () => {
    if (liveRef.current) {
      liveRef.current.changeCamera();
    }
  };

  const leaveTruck = () => {
    if (liveRef.current) {
      liveRef.current.leaveTruck();
    }
  };

  const openAudio = () => {
    if (liveRef.current) {
      liveRef.current.openAudio();
    }
  };

  const playSvga = async (gift_id) => {
    if (giftPlaying) {
      return;
    }
    setGiftPlaying(true);
    console.log("##################playSvga");
    const downloader = new Downloader();
    const parser = new Parser();
    const player = new Player("#playSvga"); // #canvas is HTMLCanvasElement
    const fileData = await downloader.get("./svga/" + gift_id + ".svga");
    const svgaData = await parser.do(fileData);
    player.set({
      loop: 1,
      fillMode: "forwards",
    });

    await player.mount(svgaData);

    player
      .$on("start", () => console.log("event start"))
      .$on("pause", () => console.log("event pause"))
      .$on("stop", () => console.log("event stop"))
      .$on("end", () => {
        console.log("event end");
        setGiftPlaying(false);

        downloader.destroy();

        parser.destroy();

        player.destroy();
      })
      .$on("clear", () => console.log("event clear"))
      .$on("process", () => console.log("event process", player.progress));

    player.start();
  };
  const sendTextMessage = (event) => {
    if (event.charCode !== 13) {
      return;
    }
    sendMessage();
  };
  const sendMessage = () => {
    if (chatRef.current) {
      console.log("###############sendTextMessage:" + inputValue);
      chatRef.current.sendTextMessage(inputValue);
    }
  };
  const clearInput = () => {
    setInputValue("");
  };
  const updateNumber = (number) => {
    setUserNumber(number);
  };

  const leaveChatRoom = () => {
    if (chatRef.current) {
      chatRef.current.leaveChatRoom();
    }
  };

  const inviteAlert = (entry_id, priceItem) => {
    setInviteAlertVisiable(true);
    setPriceItem(priceItem);
    setEntryId(entry_id);
    console.log(
      "#################priceItem=",
      entry_id,
      JSON.stringify(priceItem)
    );
  };

  const acceptInvite = async () => {
    closeAgora();
    let params = { entry_id: entryId, description: paramsDict.roomName };
    console.log("###########################" + JSON.stringify(params));

    history.push({
      pathname: "/ovo",
      state: params,
    });
  };

  const changeFace = async () => {
    Toast.show({
      content: "开发中！",
      maskClickable: false,
      position: "bottom",
    });
  };

  useEffect(() => {
    readLocal();
    fetchData(false);
    return () => {};
  }, []);

  useEffect(() => {}, [giftPlaying]);

  return (
    <div className="input-live-input-container">
      {!paramsDict.isFaceLive ? (
        <AgoraRTCProvider client={client}>
          <Live
            appid={agoraToken.app_id || ""}
            channel={liveInfo.channel_id || ""}
            token={agoraToken.token || ""}
            uid={agoraToken.external_uid || ""}
            chatroomId={chatroomId}
            role={"host"}
            callingON={calling}
            ref={liveRef}
            isFront={paramsDict.isFront}
            beautyON={paramsDict.beautyON}
          />
        </AgoraRTCProvider>
      ) : null}

      <canvas id="playSvga" className="liveout-gift" />
      <div className="input-live-input-user-info">
        <Image
          className="input-live-input-header"
          src={userPhoto}
          onClick={handleGoBack}
        ></Image>
        <div className="input-live-input-user-info-text">
          <div className="input-live-input-user-info-text1">{account.nickname}</div>
          <div className="input-live-input-user-info-text2">
            ID: {liveInfo.room_id}
            <div className="input-live-input-user-info-dot" />
            <div className="input-live-input-user-info-time">{formatTime(time)}</div>
          </div>
        </div>
      </div>
      <Image
        className="input-live-input-close-btn"
        src="/images/live/live-off.png"
        onClick={handleGoBack}
      />

      <div className="input-live-input-visitor-number" onClick={gotoUserList}>
        <Image
          className="input-live-input-visitor-header"
          src="/images/live/live-visitor-header.png"
        />
        <div className="input-live-input-visitor-header-number">{userNumber}</div>
      </div>

      <ChatScroll
        className="liveinput-chatscroll-container"
        ref={chatRef}
        nickname={account.nickname}
        chatroomId={chatroomId}
        playSvga={playSvga}
        updateNumber={updateNumber}
        clearInput={clearInput}
        inviteAlert={inviteAlert}
      />

      <div className="input-live-input-chat-tools">
        <div className="input-live-input-chat-chat-box">
          <input
            className="input-live-input-chat-chat-inputbox"
            type="searchText"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={t("live.chat.placeholder")}
            onKeyPress={sendTextMessage}
          />
        </div>
      </div>
      <div className="input-live-main-tool">
        <div onClick={changeCamera} className="input-live-main-tool-box">
          <Image
            className="input-live-main-tool-camera"
            src="/images/live/live_camera_turn.png"
          />
          <div className="input-live-main-tool-text">{t("prelive.turn")}</div>
        </div>

        {/* <div onClick={changeFace}>
          <Image
            className="input-live-main-tool-camera"
            src="/images/live/live_face_changing.png"
          />
          <div className="input-live-main-tool-text">{t("prelive.aiface")}</div>
        </div> */}

        <div onClick={openBeauty}>
          <Image
            className="input-live-main-tool-beauty"
            src="/images/live/live_beauty.png"
          />
          <div className="input-live-main-tool-text">{t("prelive.beauty")}</div>
        </div>
        <div onClick={changeFace}>
          <Image
            className="input-live-main-tool-kind"
            src="/images/live/live_partcul.png"
          />
          <div className="input-live-main-tool-text">{t("prelive.magic")}</div>
        </div>
        <div onClick={changeFace}>
          <Image
            className="input-live-main-tool-setting"
            src="/images/live/live_setting.png"
          />
          <div className="input-live-main-tool-text">{t("prelive.setting")}</div>
        </div>
        <div onClick={changeFace}>
          <Image
            className="input-live-main-tool-more"
            src="/images/live/live_setting_more.png"
          />
          <div className="input-live-main-tool-text">{t("prelive.more")}</div>
        </div>
      </div>

      {inviteAlertVisiable ? (
        <InviteAlert
          setAlertVisiable={setInviteAlertVisiable}
          priceItem={priceItem}
          acceptInvite={acceptInvite}
        />
      ) : null}
      {faceAlertVisiable ? (
        <FaceCode setAlertVisiable={setFaceAlertVisiable} faceCode={faceCode} />
      ) : null}
    </div>
  );
};

export default LiveInput;
