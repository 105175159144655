import React, { useEffect,useState } from "react";
import { Picker, Image } from "antd-mobile";
//import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import "./index.css";
import AIMEStorage from "../../components/storage/aimestorage";
import { setDefaultConfig } from 'antd-mobile'
import enUS from 'antd-mobile/es/locales/en-US'
import zhCN from 'antd-mobile/es/locales/zh-CN'


const Setting = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [pickVisiabel, setPickVisiabel] = useState(false);
  const [language, setLanguage] = useState("");
  const [languageColumns] = useState([
    [
      { label: "English", value: "en" },
      { label: "中文", value: "zh" },
    ],
  ]);
  useEffect(() => {
    const lan = AIMEStorage.getLanguage();
    setLanguage(lan);
    return () => {};
  }, []);

  const wordfile = (type) => {
    switch (type) {
      case 1:
        history.push("/wordfile", {
          title: t("setting.parental.title"),
          fileName: "/word/Parental Control Guide.docx",
        });
        break;
      case 2:
        history.push("/wordfile", {
          title: t("setting.aboutus.title"),
          fileName: "/word/About.docx",
        });
        break;
      default:
        break;
    }
  };

  const { i18n } = useTranslation();

  const changeLanguage = () => {
    setPickVisiabel(true)
    
  };

  const privacy = () => {
    history.push("/privacy");
  };

  const termsofuse = () => {
    history.push("/termsofuse");
  };

  return (
    <div className="setting-container">
      <NavigationBar navTitle={t("setting.title")} />
      
      <div className="setting-list">
        <div className="setting-item" onClick={()=>changeLanguage()}>
          <div className="setting-item-title">{t("setting.language.title")}</div>
          <div className="setting-item-content">{t("setting.language")}</div>
          <Image
            className="setting-item-arrow"
            src="/images/home/verified_arrow.png"
          />
        </div>
        <div className="setting-item">
          <div className="setting-item-title">{t("setting.online.title")}</div>
          <div className="setting-item-content">{t("setting.online.status")}</div>
        </div>
        
        {/* <div className="setting-item" onClick={logout}>
          <div className="setting-item-title">背景设置</div>
          <div className="setting-item-content">暗色</div>
          <Image
            className="setting-item-arrow"
            src="/images/home/verified_arrow.png"
          />
        </div> */}

        <div className="setting-item" onClick={termsofuse}>
          <div className="setting-item-title">{t("setting.tou.title")}</div>
          <div className="setting-item-content">{t("setting.check")}</div>
          <Image
            className="setting-item-arrow"
            src="/images/home/verified_arrow.png"
          />
        </div>

        <div className="setting-item" onClick={privacy}>
          <div className="setting-item-title">{t("setting.privacy.title")}</div>
          <div className="setting-item-content">{t("setting.check")}</div>
          <Image
            className="setting-item-arrow"
            src="/images/home/verified_arrow.png"
          />
        </div>

        <div className="setting-item" onClick={() => wordfile(1)}>
          <div className="setting-item-title">{t("setting.parental.title")}</div>
          <div className="setting-item-content">{t("setting.check")}</div>
          <Image
            className="setting-item-arrow"
            src="/images/home/verified_arrow.png"
          />
        </div>

        <div className="setting-item" onClick={() => wordfile(2)}>
          <div className="setting-item-title">{t("setting.aboutus.title")}</div>
          <div className="setting-item-content">{t("setting.check")}</div>
          <Image
            className="setting-item-arrow"
            src="/images/home/verified_arrow.png"
          />
        </div>
      </div>
      <div onClick={privacy} className="setting-bottom">
      {t("setting.privacy.begin")}<div className="setting-bottom-right">{t("setting.privacy.policy")}</div>
      </div>
      <Picker
        columns={languageColumns}
        visible={pickVisiabel}
        onClose={() => {
          setPickVisiabel(false);
        }}
        value={language}
        onConfirm={(v) => {
          let value = v[0];
          i18n.changeLanguage(value);
          setLanguage(value)
          AIMEStorage.setLanguage(value);
        }}
      />
    </div>
  );
};

export default Setting;
