import React, { useState, useEffect, useCallback } from "react";
import { Button, Image } from "antd-mobile";
//import Request from "../../components/request/request";
import NavigationBar from "../../../uimodules/navbar";
//import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import "./index.css";
const Unlock = () => {
  const history = useHistory();

  const logout = () => {
    history.push("/topup");
  };

  return (
    <div className="unlock-container">
      <NavigationBar />
      <div className="unlock-title" onClick={logout}>解锁图片/视频</div>
      <div className="unlock-title-pay">
        <div className="unlock-title-begin">您将支付：</div>
        <div className="unlock-title-price">41</div>
        <Image src="/images/chat/chat_coin.png" className="unlock-logo" />
      </div>
    
        <div className="unlock-sure" onClick={logout}>确认</div>
   
      <div className="unlock-cancel">取消</div>
    </div>
  );
};

export default Unlock;
