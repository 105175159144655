import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Toast } from "antd-mobile";
import {
  LocalUser,
  RemoteUser,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  useLocalAudioTrack,
  useRemoteUsers,
  usePublish,
  useJoin,
} from "agora-rtc-react";
import client from "../../components/agora/index";
import BeautyExtension from "agora-extension-beauty-effect";
import AgoraRTC from "agora-rtc-react";
import "./index.css";


const Live = forwardRef((props, ref) => {
 
  const [localTracks, setLocalTracks] = useState({
    videoTrack: null,
    audioTrack: null,
  });

  const [beautyON, setBeauty] = useState(false);
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);

  const [isFront, setIsFront] = useState(props.isFront);

  const extension = new BeautyExtension();
  AgoraRTC.registerExtensions([extension]);

  const [processor,setProcessor] = useState(null);
  useEffect(() => {
    setProcessor(extension.createProcessor());
    return () => {
      leaveTruck();
    };
  }, []);

  const createTrack = async () => {
   
    console.log('##################createTrack:props.appid='+ props.appid,"||", props.channel,"||", props.token);
    await client.join(
      props.appid,
      props.channel,
      props.token ? props.token : null,
      parseInt(props.uid)
    );

    const audioTrack =
      localTracks.audioTrack || (await AgoraRTC.createMicrophoneAudioTrack({
        encoderConfig: "music_standard"
      }));
    const videoTrack =
      localTracks.videoTrack || (await AgoraRTC.createCameraVideoTrack({
        encoderConfig: '720p_2'
      }));

    videoTrack.pipe(processor).pipe(videoTrack.processorDestination);
    setLocalTracks({
      videoTrack: videoTrack,
      audioTrack: audioTrack,
    });

    await client.publish([audioTrack, videoTrack]);
    console.log("~~~~~~~~~~~~~~~~~~~美颜配置");
    turnCamera(isFront,videoTrack);

    if (props.beautyON) {
      console.log("~~~~~~~~~~~~~~~~~~~开启美颜");
      setBeauty(false)
      setTimeout(function () {
        openBeauty();
      }, 1000);
    } 
  };

  const leaveTruck = ()=>{
    console.log("~~~~~~~~~~~~~~~~~~~leaveTruck");
    localTracks.videoTrack?.close();
    localTracks.videoTrack?.unpipe();
    localTracks.audioTrack?.close();
    localTracks.audioTrack?.unpipe();
    setProcessor(null);
    client.leave();
  }


  const turnCamera = async (isFrontSet,videoTrack) => {
    if(!videoTrack){
      return;
    }
    const cameras = await AgoraRTC.getCameras();
    if (!isFrontSet) {
      const deviceId = cameras[1]?.deviceId;
      videoTrack.setDevice({
        facingMode: "environment",
        deviceId: deviceId,
      });
  
    } else {
      const deviceId = cameras[0]?.deviceId;
      videoTrack.setDevice({
        facingMode: "user",
        deviceId: deviceId,
      });
    }
  };

  const changeCamera = async () => {
    if(!localTracks.videoTrack){
      return;
    }
    const cameras = await AgoraRTC.getCameras();
    console.log("~~~~~~~~~~~~~~~~~~~" + JSON.stringify(cameras));

    if (isFront) {
      setIsFront(false);
      const deviceId = cameras[1]?.deviceId;
      localTracks.videoTrack.setDevice({
        facingMode: "environment",
        deviceId: deviceId,
      });
    } else {
      setIsFront(true);
      const deviceId = cameras[0]?.deviceId;
      localTracks.videoTrack.setDevice({
        facingMode: "user",
        deviceId: deviceId,
      });
    }
  };

  // useJoin(
  //   { appid: appid, channel: channel, token: token ? token : null, uid },
  //   callingON
  // );

  //usePublish([localTracks.audioTrack,localTracks.videoTrack ]);

  useEffect(() => {
    console.log("####################paramsDict"+JSON.stringify(props));

    if (props.callingON) {
      console.log("#################Creating tracks...");
      createTrack();
    }
    return () => {
     
    };
  }, [props.callingON]);

  useImperativeHandle(ref, () => ({
    openBeauty,
    openAudio,
    leaveTruck,
    changeCamera,
  }));

  const openAudio = async () => {
    setMic(!micOn);
    Toast.show({
      content: !micOn?"测试声音已开启":"测试声音已关闭",
      maskClickable: false,
      position: "bottom",
    });
  }
  
  const openBeauty = async () => {
    processor.setOptions({
      // 对比度
      lighteningContrastLevel: 1,
      // 亮度
      lighteningLevel: 0.5,
      // 平滑度
      smoothnessLevel: 0.5,
      // 锐化程度
      sharpnessLevel: 0.6,
      // 红润度
      rednessLevel: 0.5,
    });
    
    if (!beautyON) {
      console.log("~~~~~~~~~~~~~~~~~~~开启美颜");
      await processor.enable();
    } else {
      console.log("~~~~~~~~~~~~~~~~~~~关闭美颜");
      await processor.disable();
    }
    setBeauty(!beautyON);
  };
  return (
    <div className="live-container">
      <LocalUser
        className="live-show"
        audioTrack={localTracks.audioTrack}
        videoTrack={localTracks.videoTrack}
        micOn={micOn}
        cameraOn={cameraOn}
        cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
      ></LocalUser>

      {/* <div className="live-show-info">
        <div>chatroomid:{props.chatroomId}</div>
        <div>appid:{props.appid}</div>
        <div>channel:{props.channel}</div>
        <div>token:{props.token}</div>
        <div>uid:{props.uid}</div>
        <div>role:{props.role}</div>
      </div> */}
    </div>
  );
});

export default Live;

// const remoteUsers = useRemoteUsers();
/* <div className="live-show-remote">
  {remoteUsers.map((user) => (
    <div className="live-show-remote-item" key={user.uid}>
      <RemoteUser
        cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
        user={user}
      >
        <samp className="user-name">{user.uid}</samp>
      </RemoteUser>
    </div>
  ))}
</div> */

// const callAct = () => {
//   console.log("################onClick");
//   // AgoraRTC.on("camera-changed", (info) => {
//   //   console.log("################Camera changed!", info.state, info.device);
//   // });

//   if (!calling) {
//     console.log("##############appid=" + appid);
//     console.log("##############channel=" + channel);
//     console.log("##############token=" + token);
//     console.log("##############uid=" + uid);
//     console.log("##############role=" + role);
//     client.setClientRole(role, 1);
//     client.join(appid, channel, token, uid);
//   }
//   setCalling(!calling);
// };
