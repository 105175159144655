import React, { useState, useEffect, useCallback } from "react";
import { Badge, Image, Empty } from "antd-mobile";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import AIMEEaseMob from "../../components/easemob/index";
import Custom from "../../components/custom/custom";
import AIMEStorage from "../../components/storage/aimestorage";
import { useTranslation } from 'react-i18next';
import "./index.css";

const Message = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [systemList, setSystemList] = useState(null);
  const [messageList, setMessageList] = useState(null);
  const [emptyVisiable, setEmptyVisiable] = useState(false);

  const fetchData = async () => {
    try {
      const url = "/api/systemMessage"; // 设置API端点
      const headers = {};
      const params = {
        page: 1,
        limit: 100,
      };
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;

      if (code === 200) {
        setSystemList(data);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
      setEmptyVisiable(
        systemList &&
          systemList.length === 0 &&
          messageList &&
          messageList.length === 0
      );
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  const fetchMessageData = async (conversations) => {
    try {
      const url = "/api/getMessageInfoList"; // 设置API端点
      const headers = {};
      const result = await Request.post(url, conversations, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      if (code === 200) {
        setMessageList(data);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
      setEmptyVisiable(
        systemList &&
          systemList.length === 0 &&
          messageList &&
          messageList.length === 0
      );
      Toast.clear();
    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
  };

  const getServerConversations = () => {
    AIMEEaseMob.conn
      .getServerConversations({ pageSize: 50, cursor: "" })
      .then((res) => {
        const { conversations } = res.data;
        fetchMessageData(conversations);

        console.log("1#######################" + JSON.stringify(res));
      });
  };
  const easeUserToken = async () => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    AIMEEaseMob.conn.addEventHandler("handlerId", {
      onConnected: () => {
        getServerConversations();
        console.log("##################onConnected");
      },
      onDisconnected: () => {
        console.log("##################onDisconnected");
      },
      onTokenWillExpire: () => {
        console.log("##################onTokenWillExpire");
      },
      onTokenExpired: () => {
        console.log("##################onTokenExpired");
      },
      onTextMessage: (message) => {
        getServerConversations();
      },
      onImageMessage: (message) => {
        getServerConversations();
      },
      onCustomMessage: (message) => {
        getServerConversations();
      },
    });
    if (AIMEEaseMob.conn.isOpened()) {
      getServerConversations();
    } else {
      AIMEEaseMob.easeUserToken();
    }
  };

  
  const fetchUnlockListData = async () => {
    try {
      const url = "/api/post/get-unlock-list"; // 设置API端点
      const headers = {};
      const result = await Request.get(url, {}, headers);
      console.log("########result:", result);
      const { data, code, msg } = result;
      if (code === 200) {
        if(data){
          AIMEStorage.saveUnlockList(data);
        }
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
      Toast.clear();
    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
  };

  useEffect(() => {
    fetchData();
    easeUserToken();
    fetchUnlockListData();
    return () => {};
  }, []);

  const openItem = (item) => {
    console.log(item);
    const { type, data } = item;
    if (type === 2 && data) {
      console.log(data);
      const liveInfo = JSON.parse(data);
      const { entries_id, description, live_1v1_id } = liveInfo;
      if (live_1v1_id) {
        history.push({
          pathname: "/ovoaud",
          state: { live_1v1_id: live_1v1_id },
        });
      } else if (entries_id) {
        history.push({
          pathname: "/ovo",
          state: { entry_id: entries_id, description: description },
        });
      }
    }
  };

  const openChat = (item) => {
    history.push({
      pathname: "/chat",
      state: {
        item: {
          nickname: item.nickName,
          avatar_path: item.avatarPath,
          external_uid: item.conversationId,
        },
      },
    });
  };

  return (
    <div className="message-container">
      <NavigationBar navTitle={t("message.title")} />
      {emptyVisiable ? (
        <Empty className="message-list-empty" description={t("nomessage.empty")} />
      ) : (
        <div className="message-list">
          {(systemList ?? []).map((item, index) => (
            <div
              key={index}
              className="message-list-item"
              onClick={() => openItem(item)}
            >
              <Badge content="1">
                <Image
                  src={item.avatar_path}
                  className="message-list-item-logo"
                />
              </Badge>

              <div className="message-list-item-text">
                <div className="message-list-item-title">{item.title}</div>
                <div className="message-list-item-content">
                  <div>{item.message}</div>
                  <div className="message-list-item-content-time">
                    {Custom.changeToLocal(item.created_at)}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {(messageList ?? []).map((item, index) => (
            <div
              key={index}
              className="message-list-item"
              onClick={() => openChat(item)}
            >
              {item.unReadCount > 0 ? (
                <Badge content={item.unReadCount}>
                  <Image
                    src={item.avatarPath}
                    className="message-list-item-logo"
                  />
                </Badge>
              ) : (
                <Image
                  src={item.avatarPath}
                  className="message-list-item-logo"
                />
              )}

              <div className="message-list-item-text">
                <div className="message-list-item-title">{item.nickName}</div>
                <div className="message-list-item-content">
                  <div>
                    {item.lastMessage.msg
                      ? item.lastMessage.msg
                      : item.lastMessage.customEvent}
                  </div>
                  <div className="message-list-item-content-time">
                    {Custom.changeToLocal(item.lastMessage.time)}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {(systemList && systemList.length > 0) ||
          (messageList && messageList.length > 0) ? (
            <div className="message-list-bottom"></div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Message;
