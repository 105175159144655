import React, { useState, useEffect, useCallback, useRef } from "react";
import Request from "../../components/request/request";
import { Toast, Image, Dialog } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Audience from "../audience/index";
import { AgoraRTCProvider } from "agora-rtc-react";
import AgoraRTC from "agora-rtc-react";
import client from "../../components/agora/index";
import ChatScroll from "../../uimodules/chatscroll/index";
import { Downloader, Parser, Player } from "svga-web";
import PopView from "./popview/index";
import AIMEEaseMob from "../../components/easemob/index";
import WebIM from "easemob-websdk";
import PayAlert from "./payalert/index";
import { useTranslation } from 'react-i18next';
import Custom from "../../components/custom/custom";
import "./index.css";

const LiveOutput = () => {
  const liveRef = useRef(null);
  const chatRef = useRef(null);
  const history = useHistory();
  const [liveInfo, setLiveInfo] = useState({});
  const [agoraInfo, setAgoraInfo] = useState({});
  const [userPhoto, setUserPhoto] = useState("");
  const [calling, setCalling] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [nickname, setNickname] = useState("");
  const [giftPlaying, setGiftPlaying] = useState(false);
  const [popVisiable, setPopVisiable] = useState(false);
  const [popPwdVisiable, setPopPwdVisiable] = useState(false);
  const [payAlertVisiable, setPayAlertVisiable] = useState(false);
  const [userNumber, setUserNumber] = useState("");
  const { t } = useTranslation();
  const playSvga = async (gift_id) => {
    if (giftPlaying) {
      return;
    }
    setGiftPlaying(true);
    console.log("##################playSvga");
    const downloader = new Downloader();
    const parser = new Parser();
    const player = new Player("#playSvga"); // #canvas is HTMLCanvasElement
    const fileData = await downloader.get("./svga/" + gift_id + ".svga");
    const svgaData = await parser.do(fileData);
    player.set({
      loop: 1,
      fillMode: "forwards",
    });

    await player.mount(svgaData);

    player
      .$on("start", () => console.log("event start"))
      .$on("pause", () => console.log("event pause"))
      .$on("stop", () => console.log("event stop"))
      .$on("end", () => {
        console.log("event end");
        setGiftPlaying(false);

        downloader.destroy();

        parser.destroy();

        player.destroy();
      })
      .$on("clear", () => console.log("event clear"));
    // .$on("process", () => console.log("event process", player.progress));

    player.start();
  };

  const handleGoBack = async () => {
    Toast.show({
      icon: "loading",
      content: t("toast.close"),
      duration: 0,
      maskClickable: false,
    });
    try {
      let url = "";
      if (item.type === 1) {
        url = "/api/private-show/viewer-leave-live"; // 设置API端点
      } else {
        url = "/api/live/viewerLeaveLiveRoom"; // 设置API端点
      }
      const headers = {};
      const params = { channel_id: liveInfo.channel_id };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, msg } = result;
      Toast.clear();
      if (code !== 200) {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }

      history.goBack();
      leaveChatRoom();
    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
  };

  const gotoUserInfo = () => {
    leaveChatRoom();
  };

  const location = useLocation();
  const item = location.state.item;

  const fetchData = useCallback(async () => {
    console.log(item);
    console.log(item.type);
    if (item.type === 1) {
      try {
        const url = "/api/private-show/viewer-enter-live";
        const headers = {};
        const params = { live_id: item.live_id };
        const result = await Request.post(url, params, headers);
        const { data, code, msg } = result;
        console.log("data:", data);
        if (code === 200) {
          const { agora_info, live_info } = data;
          setLiveInfo(live_info);
          setAgoraInfo(agora_info);
          client.setClientRole("audience", 1);
          AgoraRTC.setLogLevel(2);
          setCalling(true);
        } else {
          Toast.show({
            content: msg,
            maskClickable: false,
            position: "bottom",
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      try {
        const url = "/api/live/viewerJoinLiveRoom";
        const headers = {};
        const params = { live_id: item.live_id };
        const result = await Request.post(url, params, headers);
        const { data, code, msg } = result;
        console.log("data:", data);
        if (code === 200) {
          const { agora_info, live_info } = data;
          setLiveInfo(live_info);
          setAgoraInfo(agora_info);
          client.setClientRole("audience", 1);
          AgoraRTC.setLogLevel(2);
          setCalling(true);
        } else {
          Toast.show({
            content: msg,
            maskClickable: false,
            position: "bottom",
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }, [item]);

  const readLocal = () => {
    const data = localStorage.getItem("userdata");
    if (data) {
      const parsedData = JSON.parse(data);
      const { nickname, avatar_path } = parsedData.account || {};
      setNickname(nickname);
      setUserPhoto(avatar_path);
      console.log("##################data=" + data);
    }
  };

  const follow = async () => {
    try {
      const url = "/api/user/follow";
      const params = {
        followee_id: item.account_id,
      };
      const result = await Request.post(url, params, {});
      console.log("result:", result);
      const { code, msg } = result;
      if (code === 200) {
        setIsFollow(true);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };

  const sendTextMessage = (event) => {
    if (event.charCode !== 13) {
      return;
    }
    sendMessage();
  };
  const sendMessage = () => {
    if (chatRef.current) {
      console.log("###############sendTextMessage:" + inputValue);
      chatRef.current.sendTextMessage(inputValue);
    }
  };

  const clearInput = () => {
    setInputValue("");
  };
  const sendGift = (giftId) => {
    if (chatRef.current) {
      chatRef.current.sendGift(giftId);
    }
    setPopVisiable(false);
  };

  const leaveChatRoom = (giftId) => {
    if (chatRef.current) {
      chatRef.current.leaveChatRoom();
    }
  };

  const updateNumber = (number) => {
    setUserNumber(number);
  };

  const showGift = () => {
    setPopVisiable(true);
  };

  const showInvite = (live_1v1_id) => {
    console.log("#########################live_1v1_id=" + live_1v1_id);
    Dialog.confirm({
      content: "1v1邀请成功，主播正在等您！",
      onConfirm: async () => {
        leaveChatRoom();
        history.push({
          pathname: "/ovoaud",
          state: { live_1v1_id: live_1v1_id },
        });
        // setTimeout(() => {
        //   history.push({
        //     pathname: "/ovoaud",
        //     state: { live_1v1_id: live_1v1_id },
        //   });
        // }, 3000);
      },
    });
  };

  const payCallback = (data, priceItem) => {
    setPayAlertVisiable(false);
    const { entry } = data;
    const { id } = entry;
    let customEvent = "1v1_pay_success";
    // 通过键值对设置自定义消息内容。
    let customExts = {
      fromNickname: nickname,
      entry_id: id,
      price_item: priceItem,
    };
    let option = {
      type: "custom",
      to: "" + liveInfo.external_uid,
      chatType: "singleChat",
      customEvent,
      customExts,
      ext: {},
    };
    console.log("###############" + JSON.stringify(option));
    // 创建文本消息。
    let msg = WebIM.message.create(option);
    // 调用 `send` 方法发送该文本消息。
    AIMEEaseMob.conn
      .send(msg)
      .then((res) => {
        console.log("Send message success", res);
      })
      .catch((e) => {
        console.log("Send message fail", e);
      });
  };

  useEffect(() => {
    readLocal();
    fetchData();
    if (chatRef.current) {
      chatRef.current.easeUserToken();
    }
    return () => {
      leaveChatRoom();
    };
  }, []);

  useEffect(() => {}, [giftPlaying]);

  return (
    <div className="live-output-container">
      <AgoraRTCProvider client={client}>
        <Audience
          appid={agoraInfo?.app_id}
          channel={item?.channel_id}
          token={agoraInfo?.agora_token}
          uid={agoraInfo?.external_uid}
          role={"audience"}
          roomid={item?.chatroom_id}
          callingON={calling}
        />
      </AgoraRTCProvider>
      <canvas id="playSvga" className="liveout-gift" />
      <div className="live-output-user-container">
        <div className="live-output-user-info">
          <Image
            className="live-output-header"
            src={userPhoto}
            onClick={gotoUserInfo}
          ></Image>
          <div className="live-output-user-info-text">
            <div className="live-output-user-info-text1">{Custom.truncateString(item?.nickname, 8)}</div>
            <div className="live-output-user-info-text2">
              <Image
                className="live-output-user-info-image"
                src="/images/person/person_star.png"
              />
              {item?.num_of_likes}{" "+t("prelive.like.title")}
            </div>
          </div>
          {isFollow ? (
            <div className="live-output-user-info-notice" onClick={follow}>
              {t("personal.followed")}
            </div>
          ) : (
            <div className="live-output-user-info-notice" onClick={follow}>
              {t("personal.follow")}
            </div>
          )}
        </div>
      </div>
      <Image
        className="live-output-close-btn"
        src="/images/live/live_closed.png"
        onClick={handleGoBack}
      />
      <div className="live-output-user-id">{item?.unique_nickname}</div>

      <ChatScroll
        className="liveoutput-chatscroll-container"
        ref={chatRef}
        nickname={nickname}
        chatroomId={item?.chatroom_id}
        clearInput={clearInput}
        updateNumber={updateNumber}
        playSvga={playSvga}
        showInvite={showInvite}
      />

      <Image
        className="live-output-invite1v1"
        src="/images/live/live-invite1v1.png"
        onClick={() => setPayAlertVisiable(true)}
      />

      <div className="live-output-tools">
        <div className="live-output-chat-box">
          <Image className="live-output-chat-logo" src={userPhoto} />
          <input
            className="live-output-chat-inputbox"
            type="searchText"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={t("live.chat.placeholder")}
            onKeyPress={sendTextMessage}
            enterKeyHint="send"
          />
        </div>
        <Image
          src="/images/chat/chat_send.png"
          className="live-output-input-send"
          onClick={sendMessage}
        />
        <div className="live-output-tools-imgs">
          <div className="live-output-tools-box">
            <Image src="/images/live/live-output-chat.png" />
          </div>
          <div className="live-output-tools-box">
            <Image src="/images/live/live-output-magic.png" />
          </div>
          <div className="live-output-tools-box" onClick={showGift}>
            <Image src="/images/live/live-output-gift.png" />
          </div>
          <div className="live-output-tools-box">
            <Image src="/images/live/live-output-more.png" />
          </div>
        </div>
      </div>
      {popVisiable && (
        <PopView
          accountId={item.account_id}
          sendGift={sendGift}
          popVisiable={popVisiable}
          setPopVisiable={setPopVisiable}
          setPopPwdVisiable={setPopPwdVisiable}
        />
      )}
      {/* {popPwdVisiable && (
        <PopPwd
        popPwdVisiable ={popPwdVisiable}
        setPopPwdVisiable={setPopPwdVisiable}
        />
      )} */}

      {payAlertVisiable ? (
        <PayAlert
          setAlertVisiable={setPayAlertVisiable}
          userId={item.account_id}
          payCallback={payCallback}
        />
      ) : null}
    </div>
  );
};

export default LiveOutput;
