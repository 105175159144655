import React, { useState } from "react";
import NavigationBar from "../../../uimodules/navbar";
import Request from "../../../components/request/request";
import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import md5 from "md5";
import "./index.css";
const EmailCode = () => {
  const location = useLocation();
  const history = useHistory();
  const inParams = location.state;
  console.log(inParams);
  const { email, password, inviteCode } = inParams;
  const [emailCode, setEmailCode] = useState("");
  const { t } = useTranslation();
  const register = async () => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });

    try {
      const hashedPassword = md5(password);
      const url = "/api/user/registerByEmail"; // 设置API端点
      const headers = {};
      const params = {
        email: email,
        password: hashedPassword,
        code: emailCode,
        inviteCode: inviteCode,
      };
      const result = await Request.post(url, params, headers);
      const { code, msg, data } = result;
      console.log("data:", data);
      Toast.clear();
      if (code === 200) {
        if (data) {
          const { access_token } = data || "";
          localStorage.setItem("authorization", access_token);
          localStorage.setItem("userdata", JSON.stringify(data));
        }
        window.selectTab = "home"
        history.push("/home");
      } else {
        Toast.show({
          content: msg,
          duration:4000,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  return (
    <div className="container">
      <NavigationBar />
      <div className="emailcode-title">Email Code</div>

      <div className="emailcode-des">Please enter the verification code sent to your email
      <span className="emailcode-check-text-privacy">{"  "}{email}</span></div>
      <input
        className="emailcode-input"
        type="text"
        placeholder="Email Code"
        value={emailCode}
        onChange={(e) => setEmailCode(e.target.value)}
      />
      <button className="emailcode-login" onClick={register}>
        Log in
      </button>
      <div className="emailcode-check-text">
        Can't find the email? Click
        <span className="emailcode-check-text-privacy"> here </span>to resend.
      </div>
    </div>
  );
};

export default EmailCode;
