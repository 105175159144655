import { Toast } from "antd-mobile";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import Request from "../request/request";

class FireBaseConfiger {
  static initializeApp() {
    const firebaseConfig = {
      apiKey: "AIzaSyChzSUrM-DZaKA1Cxzfcssg_gs3cPJDcA4",
      authDomain: "aime-53071.firebaseapp.com",
      projectId: "aime-53071",
      storageBucket: "aime-53071.appspot.com",
      messagingSenderId: "126618378813",
      appId: "1:126618378813:web:7e02d77e66539f3311c054",
      measurementId: "G-EYVZ6N3NP1",
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  }
  static async serverLogin(email, idToken, googleUserId, name, picture,promocode) {
    try {
      const url = "/api/user/google/login"; // 设置API端点
      const headers = {};
      const params = {
        email: email,
        idToken: idToken,
        googleUserId: googleUserId,
        name: name,
        picture: picture,
        inviteCode: promocode
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, msg, data } = result;
      console.log("data:", data);

      Toast.clear();
      if (code === 200) {
        if (data) {
          const { access_token } = data || "";
          localStorage.setItem("authorization", access_token);
          localStorage.setItem("userdata", JSON.stringify(data));
        }
        return { code: code, msg: msg };
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
        return { code: 1, msg: msg };
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息';
      Toast.clear();
      return { code: 1, msg: error.toString() };
    }
  }

  static async login(promocode) {
    Toast.show({
      icon: "loading",
      content: "loading…",
      duration: 0,
      maskClickable: false,
    });

    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();

      const result = await signInWithPopup(auth, provider);
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      console.log(token);
      // The signed-in user info.
      const firbaseUser = auth.currentUser;
      if (firbaseUser !== null) {
        // The user object has basic properties such as display name, email, etc.
        const displayName = firbaseUser.displayName;
        const email = firbaseUser.email;
        const photoURL = firbaseUser.photoURL;
        const idToken = await firbaseUser.getIdToken();
        // The user's ID, unique to the Firebase project. Do NOT use
        // this value to authenticate with your backend server, if
        // you have one. Use User.getToken() instead.
        const uid = firbaseUser.uid;
        //email, idToken, googleUserId, name, picture
        console.log("###################email" + email);
        Toast.clear();
        return this.serverLogin(email, idToken, uid, displayName, photoURL,promocode);
      } else {
        Toast.clear();
        console.log("###################currentUser is null!");
        return { code: 1, msg: "currentUser is null!" };
      }
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      //const credential = GoogleAuthProvider.credentialFromError(error);
      console.log("###################2", errorCode, errorMessage);
      console.log(errorMessage);
      Toast.clear();
      if (errorCode === "auth/popup-blocked") {
        Toast.show({
          content:
            "请在设置->Safari->关闭阻止弹出式窗口，以确保Google登录弹窗打开！",
          maskClickable: false,
          position: "bottom",
          duration: 5000,
        });
      }

      return { code: errorCode, msg: errorMessage };
    }
  }

  static logout() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }
}

export default FireBaseConfiger;
