import React, { useState, useEffect, useRef } from "react";
import Request from "../../components/request/request";
import { Toast, Image } from "antd-mobile";
import { useHistory } from "react-router-dom";
import VideoChat from "../videochat/index";
import { useLocation } from "react-router-dom";
import { AgoraRTCProvider } from "agora-rtc-react";
import client from "../../components/agora/rtc";
import AgoraRTC from "agora-rtc-react";
import ChatScroll from "../../uimodules/chatscroll/index";
import { Downloader, Parser, Player } from "svga-web";
import AIMEEaseMob from "../../components/easemob/index";
import WebIM from "easemob-websdk";
import { useTranslation } from 'react-i18next';
import "./index.css";

const LiveInput = () => {
  const liveRef = useRef(null);
  const chatRef = useRef(null);
  const history = useHistory();
  const [agoraToken, setAgoraToken] = useState({});
  const [liveInfo, setLiveInfo] = useState({});
  const [userPhoto, setUserPhoto] = useState("");
  const [account, setAccount] = useState({});
  const [calling, setCalling] = useState(false);
  const [chatroomId, setChatroomId] = useState("");
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [giftPlaying, setGiftPlaying] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [userNumber, setUserNumber] = useState(1);
  const { t } = useTranslation();
  const location = useLocation();
  const paramsDict = location.state;

  const startTimer = () => {
    setIsRunning(true);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  const resetTimer = () => {
    setTime(0);
    setIsRunning(false);
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    console.log("####################paramsDict" + JSON.stringify(paramsDict));

    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [isRunning]);

  const handleGoBack = () => {
    leaveChatRoom();
    closeLive();
  };

  const gotoUserList = () => {
    history.push("/userlist",  { liveId: liveInfo.id });
  };

  const closeLive = async () => {
    Toast.show({
      icon: "loading",
      content: t("toast.close"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/live/broadcasterLeave1On1LiveRoom"; // 设置API端点
      const headers = {};
      const params = { live_1v1_id: liveInfo.id };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data } = result;
      Toast.clear();
      stopTimer();
      setCalling(false);
      //history.goBack();
      leaveTruck();
      window.selectTab = "home";
      history.push("/home");
    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
  };

  const fetchData = async () => {
    try {
      const url = "/api/live/create1V1Live"; // 设置API端点
      const headers = {};
      const params = {
        entries_id: paramsDict.entry_id,
        description: paramsDict.description,
      };
      const result = await Request.post(url, params, headers);
      console.log("##########createLiveRoom:", result);
      const { data, code, msg } = result;
      if (code === 200) {
        const { agora_token, live1v1, chat_room } = data;
        const { chat_room_id } = chat_room;
        setChatroomId(() => chat_room_id);
        setChatroomId((preChatroomId) => {
          console.log("#########preChatroomId", preChatroomId); // 输出true
          return preChatroomId;
        });

        setAgoraToken(agora_token || "");
        setLiveInfo(live1v1 || "");
        // client.setClientRole("host", 2);
        AgoraRTC.setLogLevel(2);
        setCalling(true);
        console.log(
          "##########chat_room_id:",
          chat_room_id + "||||||" + chatroomId
        );
        if (chatRef.current) {
          chatRef.current.easeUserToken();
        }
        startTimer();
        noticeUser(live1v1.external_uid, live1v1.id);
        // recursiveTimeout(live1v1.external_uid, live1v1.id);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  const noticeUser = (external_uid, live_1v1_id) => {
    let customEvent = "1v1_invite";
    // 通过键值对设置自定义消息内容。
    let customExts = {
      fromNickname: account?.nickname,
      live_1v1_id: live_1v1_id,
    };
    let option = {
      type: "custom",
      to: "" + external_uid,
      chatType: "singleChat",
      customEvent,
      customExts,
      ext: {},
    };
    console.log("###############noticeUser" + JSON.stringify(option));
    // 创建文本消息。
    let msg = WebIM.message.create(option);
    // 调用 `send` 方法发送该文本消息。
    AIMEEaseMob.conn
      .send(msg)
      .then((res) => {
        console.log("Send message success", res);
      })
      .catch((e) => {
        console.log("Send message fail", e);
      });
  };

  const readLocal = () => {
    const data = localStorage.getItem("userdata");
    if (data) {
      const userInfo = JSON.parse(data) || {};
      const { account } = userInfo || {};
      const { avatar_path } = account || {};
      console.log(userInfo);
      setUserPhoto(avatar_path);
      setAccount(account);
    }
  };

  const openBeauty = () => {
    if (liveRef.current) {
      liveRef.current.openBeauty();
    }
  };

  const leaveTruck = () => {
    if (liveRef.current) {
      liveRef.current.leaveTruck();
    }
  };

  const openAudio = () => {
    if (liveRef.current) {
      liveRef.current.openAudio();
    }
  };

  const playSvga = async (gift_id) => {
    if (giftPlaying) {
      return;
    }
    setGiftPlaying(true);
    console.log("##################playSvga");
    const downloader = new Downloader();
    const parser = new Parser();
    const player = new Player("#playSvga"); // #canvas is HTMLCanvasElement
    const fileData = await downloader.get("./svga/" + gift_id + ".svga");
    const svgaData = await parser.do(fileData);
    player.set({
      loop: 1,
      fillMode: "forwards",
    });

    await player.mount(svgaData);

    player
      .$on("start", () => console.log("event start"))
      .$on("pause", () => console.log("event pause"))
      .$on("stop", () => console.log("event stop"))
      .$on("end", () => {
        console.log("event end");
        setGiftPlaying(false);

        downloader.destroy();

        parser.destroy();

        player.destroy();
      })
      .$on("clear", () => console.log("event clear"))
      .$on("process", () => console.log("event process", player.progress));

    player.start();
  };
  const sendTextMessage = (event) => {
    if (event.charCode !== 13) {
      return;
    }
    sendMessage();
  };
  const sendMessage = () => {
    if (chatRef.current) {
      console.log("###############sendTextMessage:" + inputValue);
      chatRef.current.sendTextMessage(inputValue);
    }
  };
  const clearInput = () => {
    setInputValue("");
  };
  const updateNumber = (number) => {
    setUserNumber(number);
  };

  const leaveChatRoom = (giftId) => {
    if (chatRef.current) {
      chatRef.current.leaveChatRoom();
    }
  };

  const changeCamera = () => {
    if (liveRef.current) {
      liveRef.current.changeCamera();
    }
  };

  const changeFace = async () => {
    Toast.show({
      content: "开发中！",
      maskClickable: false,
      position: "bottom",
    });
  };

  useEffect(() => {
    readLocal();
    fetchData();
    return () => {};
  }, []);

  useEffect(() => {}, [giftPlaying]);

  return (
    <div className="oono-input-container">
      <AgoraRTCProvider client={client}>
        <VideoChat
          appid={agoraToken.app_id || ""}
          channel={liveInfo.channel_id || ""}
          token={agoraToken.token || ""}
          uid={agoraToken.external_uid || ""}
          chatroomId={chatroomId}
          role={"host"}
          callingON={calling}
          ref={liveRef}
          isFront={paramsDict.isFront}
          beautyON={paramsDict.beautyON}
        />
      </AgoraRTCProvider>
      <canvas id="playSvga" className="liveout-gift" />
      <div className="oono-input-user-info">
        <Image
          className="oono-input-header"
          src={userPhoto}
          onClick={handleGoBack}
        ></Image>
        <div className="oono-input-user-info-text">
          <div className="oono-input-user-info-text1">{account.nickname}</div>
          <div className="oono-input-user-info-text2">
            ID: {liveInfo.room_id}
            <div className="oono-input-user-info-dot" />
            <div className="oono-input-user-info-time">{formatTime(time)}</div>
          </div>
        </div>
      </div>
      <Image
        className="oono-input-close-btn"
        src="/images/live/live-off.png"
        onClick={handleGoBack}
      />

      <div className="oono-input-visitor-number" onClick={gotoUserList}>
        <Image
          className="oono-input-visitor-header"
          src="/images/live/live-visitor-header.png"
        />
        <div className="oono-input-visitor-header-number">{userNumber}</div>
      </div>

      <ChatScroll
        className="liveinput-chatscroll-container"
        ref={chatRef}
        nickname={account.nickname}
        chatroomId={chatroomId}
        playSvga={playSvga}
        updateNumber={updateNumber}
        clearInput={clearInput}
      />

      <div className="live-input-chat-tools">
        <div className="live-input-chat-chat-box">
          <input
            className="live-input-chat-chat-inputbox"
            type="searchText"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={t("live.chat.placeholder")}
            onKeyPress={sendTextMessage}
          />
        </div>
      </div>
      <div className="ovo-main-tool">
        <div onClick={changeCamera}>
          <Image
            className="ovo-main-tool-camera"
            src="/images/live/live_camera_turn.png"
          />
          <div className="ovo-main-tool-text">{t("prelive.turn")}</div>
        </div>

        {/* <div onClick={changeFace}>
          <Image
            className="ovo-main-tool-camera"
            src="/images/live/live_face_changing.png"
          />
          <div className="ovo-main-tool-text">{t("prelive.aiface")}</div>
        </div> */}

        <div onClick={openBeauty}>
          <Image
            className="ovo-main-tool-beauty"
            src="/images/live/live_beauty.png"
          />
          <div className="ovo-main-tool-text">{t("prelive.beauty")}</div>
        </div>
        <div onClick={changeFace}>
          <Image
            className="ovo-main-tool-kind"
            src="/images/live/live_partcul.png"
          />
          <div className="ovo-main-tool-text">{t("prelive.magic")}</div>
        </div>
        <div onClick={changeFace}>
          <Image
            className="ovo-main-tool-setting"
            src="/images/live/live_setting.png"
          />
          <div className="ovo-main-tool-text">{t("prelive.setting")}</div>
        </div>
        <div onClick={changeFace}>
          <Image
            className="ovo-main-tool-more"
            src="/images/live/live_setting_more.png"
          />
          <div className="ovo-main-tool-text">{t("prelive.more")}</div>
        </div>
      </div>
    </div>
  );
};

export default LiveInput;
