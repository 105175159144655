import React, { useState, useEffect, useCallback } from "react";
import { Button, Image } from "antd-mobile";
import Request from "../../../components/request/request";
import NavigationBar from "../../../uimodules/navbar";
import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./index.css";
const PriceAlert = ({ setAlertVisiable, userId, payCallback }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [priceList, setPriceList] = useState([]);
  const [selectIndex, setSelectIndex] = useState(0);
  const fetchData = async () => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/1v1/get1v1Setting"; // 设置API端点
      const headers = {};
      const params = {
        broadcaster_account_id: userId,
      };
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { data, code,msg } = result;
      Toast.clear();
      if (code === 200) {
        setPriceList(data);
      }else{
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
  };

  const createOrder = async () => {
    var priceItem = priceList[selectIndex];
    const { item_id, amount, account_id } = priceItem;
    console.log("######################" + JSON.stringify(priceItem));
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/transfers";
      const headers = {};
      const params = {
        to_account_id: account_id,
        amount: amount,
        item_id: item_id,
      };
      const result = await Request.post(url, params, headers);
      Toast.clear();
      console.log(result);
      const { data, code, msg } = result;
      if (code === 200) {
        Toast.show({
          content: "Waiting for host to accept",
          maskClickable: false,
          position: "bottom",
        });
        if (payCallback) {
          payCallback(data,priceItem);
        }
      }else{
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }

      console.log("data:", data);
    } catch (error) {
      console.error("Error:", error);
      Toast.clear();
      Toast.show({
        content: error.message,
        maskClickable: false,
        position: "bottom",
      });
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  return (
    <div className="alert-container" onClick={() => setAlertVisiable(false)}>
      <Image src="/images/live/live_price_bg.png" className="alert-bg" />

      <div className="alert-main" onClick={(e) => e.stopPropagation()}>
        <Image
          src="/images/live/live-invite1v1.png"
          className="alert-main-top-img"
        />

        {/* <div className="alert-main-top-title">用户可以开关己方摄像头</div> */}
        <div className="alert-main-price">
          {(priceList ?? []).map((item, index) => (
            <div
              key={index}
              className={
                selectIndex === index
                  ? "alert-main-price-item-sel"
                  : "alert-main-price-item"
              }
              onClick={() => setSelectIndex(index)}
            >
              <div
                className={
                  selectIndex === index
                    ? "alert-main-price-item1-sel"
                    : "alert-main-price-item1"
                }
              >
                {item?.amount}
              </div>
              <div className="alert-main-price-item2"></div>

              <div className="alert-main-price-item3">
                {item?.time_duration}min
              </div>
            </div>
          ))}
        </div>
        <div className="alert-main-sure" onClick={createOrder}>
        {t("show.sure")}
        </div>

        <div className="alert-main-bottom-txt">用户可以开关己方摄像头</div>
      </div>
    </div>
  );
};

export default PriceAlert;
