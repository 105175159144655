import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import NavigationBar from "../../uimodules/navbar";
import Request from "../../components/request/request";
import Valid from "../../components/custom/valid";
import { Toast, Image } from "antd-mobile";
import FireBaseConfiger from "../../components/firebase/index";
import { useTranslation } from 'react-i18next';
import md5 from "md5";
import "./index.css";

const Login = () => {
  let userName = localStorage.getItem("username");
  let psw = localStorage.getItem("password");
  console.log("#####################userName=" + userName);
  const history = useHistory();
  const [email, setEmail] = useState(userName ? userName : "");
  const [password, setPassword] = useState(psw ? psw : "");
  const [remember, setRemember] = useState(psw ? true : false);
  const { t } = useTranslation();
  const signInWithGoogle = async () => {
    const { code } = await FireBaseConfiger.login();
    console.log("##############signInWithGoogle");
    if (code === 200) {
      window.selectTab = "home"
      history.replace("/home");
    }
  };

  const login = async () => {
    if (!Valid.isValidEmail(email)) {
      Toast.show({
        content: "Invalid email format！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }

    if (!Valid.isValidPassword(password)) {
      Toast.show({
        content: "Invalid password format！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const hashedPassword = md5(password);
      const url = "/api/user/login"; // 设置API端点
      const headers = {};
      const params = { email: email, password: hashedPassword };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, msg, data } = result;
      console.log("data:", data);

      Toast.clear();
      if (code === 200) {
        if (data) {
          const { access_token } = data;
          localStorage.setItem("username", email);
          if (remember) {
            localStorage.setItem("password", password);
          } else {
            localStorage.setItem("password", "");
          }
          localStorage.setItem("authorization", access_token);
          localStorage.setItem("userdata", JSON.stringify(data));
        }
        window.selectTab = "home"
        history.replace("/home");
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息';
      Toast.clear();
    }
  };

  return (
    <div className="login-container">
      <NavigationBar />
      <div className="login-title">Log in to AI Me</div>
      
      <input
        className="login-email-input"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        className="login-password-input"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />

      <div
        className="login-remember"
        onClick={() => {
          setRemember(!remember);
        }}
      >
        <Image
          src={
            remember
              ? "/images/login/login_checkbox_sel.png"
              : "/images/login/login_checkbox.png"
          }
          className="login-remember-check-box"
        />
        <div className="login-remember-check-text-remember">{t("login.rem.pwd")} </div>
      </div>

      <div className="login-btn" onClick={login}>
        Log in
      </div>
      <div className="login-or">or</div>
      <div onClick={signInWithGoogle} className="login-google-btn">
        <div className="login-google-btn-contain">
          <Image
            className="login-google-img"
            src="/images/login/login_google.png"
          />
          <div> Continue with Google</div>
        </div>
      </div>
      


      <Link to="/resetpassword" className="login-forgot-btn">
        Reset Password
      </Link>
      <Link to="/regist" className="login-register-btn">
        <div style={{ color: "white" }}>New to AIME？ </div> Sign Up Free
      </Link>

      <div className="login-check-text">
        By logging in, you confirm you're over 18 years old and agree to our
        <Link to="/termsofuse">
          {" "}
          <span className="register-check-text-privacy"> Terms of Use </span>
        </Link>{" "}
        and{" "}
        <Link to="/privacy">
          <span className="register-check-text-privacy"> Privacy Policy </span>
        </Link>
      </div>
    </div>
  );
};

export default Login;
