import React, { useState, useEffect } from "react";
import { Image, Input } from "antd-mobile";
import NavigationBar from "../../uimodules/navbar";
import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { AgoraRTCProvider } from "agora-rtc-react";
import client from "../../components/agora/index";
import BeautyExtension from "agora-extension-beauty-effect";
import AgoraRTC from "agora-rtc-react";
import Request from "../../components/request/request";
import { LocalUser } from "agora-rtc-react";
import ShowAlert from "./showalert/index";
import PriceAlert from "./pricealert/index";
import FaceAlert from "./facealert/index";
import { useTranslation } from "react-i18next";

import "./index.css";
const Prelive = () => {
  const history = useHistory();
  const [localTracks, setLocalTracks] = useState({
    videoTrack: null,
    audioTrack: null,
  });
  const { t } = useTranslation();
  const [beautyON, setBeauty] = useState(false);
  const [micOn, setMic] = useState(false);
  const [cameraOn, setCamera] = useState(true);
  const [isVerified, setIsVerified] = useState(null);
  const [canClick, setCanClick] = useState(false);
  const [isFront, setIsFront] = useState(true);
  const [selectIndex, setSelectIndex] = useState(0);
  const [oneVOne, setOneVOne] = useState(true);
  const [userId, setUserId] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [entranceFee, setEntranceFee] = useState("");
  const [maxNumber, setMaxNumber] = useState("");

  const [alertVisiable, setAlertVisiable] = useState(false);
  const [priceAlertVisiable, setPriceAlertVisiable] = useState(false);
  const [faceAlertVisiable, setFaceAlertVisiable] = useState(false);
  const [roomName, setRoomName] = useState("");

  const extension = new BeautyExtension();
  AgoraRTC.registerExtensions([extension]);

  const [processor, setProcessor] = useState(extension.createProcessor());
  const fetchData = async () => {
    try {
      const url = "/api/user/info"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data, code } = result;
      if (code === 200) {
        const { status, id } = data;
        if (status === "normal") {
          setIsVerified(false);
        } else {
          setIsVerified(true);
        }
        setUserId(id);
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  const createTrack = async () => {
    const audioTrack =
      localTracks.audioTrack ||
      (await AgoraRTC.createMicrophoneAudioTrack({
        encoderConfig: "music_standard",
      }));
    const videoTrack =
      localTracks.videoTrack ||
      (await AgoraRTC.createCameraVideoTrack({
        encoderConfig: "720p_2",
        // cameraId: cameras[cameraIndex]?.deviceId,
      }));
    videoTrack.pipe(processor).pipe(videoTrack.processorDestination);
    setLocalTracks({
      videoTrack: videoTrack,
      audioTrack: audioTrack,
    });
    console.log("~~~~~~~~~~~~~~~~~~~美颜配置");
  };

  const openBeauty = async () => {
    processor.setOptions({
      // 对比度
      lighteningContrastLevel: 1,
      // 亮度
      lighteningLevel: 0.5,
      // 平滑度
      smoothnessLevel: 0.5,
      // 锐化程度
      sharpnessLevel: 0.6,
      // 红润度
      rednessLevel: 0.5,
    });
    if (!beautyON) {
      console.log("~~~~~~~~~~~~~~~~~~~开启美颜");
      await processor.enable();
    } else {
      console.log("~~~~~~~~~~~~~~~~~~~关闭美颜");
      await processor.disable();
    }
    setBeauty(!beautyON);
  };

  const changeCamera = async () => {
    const cameras = await AgoraRTC.getCameras();
    console.log("~~~~~~~~~~~~~~~~~~~" + JSON.stringify(cameras));

    if (isFront) {
      setIsFront(false);
      const deviceId = cameras[1]?.deviceId;
      localTracks.videoTrack.setDevice({
        facingMode: "environment",
        deviceId: deviceId,
      });
      console.log("~~~~~~~~~~~~~~~~~~~1");
    } else {
      setIsFront(true);
      const deviceId = cameras[0]?.deviceId;
      localTracks.videoTrack.setDevice({
        facingMode: "user",
        deviceId: deviceId,
      });
      console.log("~~~~~~~~~~~~~~~~~~~0");
    }
  };

  useEffect(() => {
    fetchData();

    setTimeout(function () {
      setCanClick(true);
    }, 2000);
    createTrack();
    return () => {
      closeLive();
    };
  }, []);

  const createShow = async () => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const now = new Date();
      now.setMinutes(now.getMinutes() + 5);
      const utcStartTimestamp = now.getTime();
      now.setMinutes(now.getMinutes() + 60);
      const utcEndTimestamp = now.getTime();
      const url = "/api/private-show/create-preview"; // 设置API端点
      const headers = {};
      const params = {
        entrance_fee: entranceFee ? entranceFee : "10",
        start_time: startTime ? startTime : utcStartTimestamp,
        end_time: endTime ? endTime : utcEndTimestamp,
        room_name: roomName ? roomName : t("home.room.name"),
        description: "",
        limit_num_of_visitor: maxNumber ? parseInt(maxNumber) : 10,
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      Toast.clear();
      if (code === 200) {
        Toast.show({
          icon: "success",
          content: msg,
          position: "bottom",
        });
        let params = {
          isFront: isFront,
          beautyON: beautyON,
          roomName: roomName ? roomName : t("home.room.name"),
          oneVOne: false,
          isShow: true,
        };
        var path = { pathname: "/liveinput", state: params };
        history.push(path);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
  };
  const closeLive = () => {
    localTracks.videoTrack?.close();
    localTracks.videoTrack?.unpipe();
    localTracks.audioTrack?.close();
    localTracks.audioTrack?.unpipe();
    setProcessor(null);
    client.leave();
  };

  const goToLive = () => {
    if (isVerified) {
      if (selectIndex === 1) {
        createShow();
      } else if (selectIndex === 0) {
        closeLive();
        let params = {
          isFront: isFront,
          beautyON: beautyON,
          roomName: roomName ? roomName : t("home.room.name"),
          oneVOne: oneVOne,
        };
        var path = { pathname: "/liveinput", state: params };
        history.push(path);
      } else {
        closeLive();
        let params = {
          isFront: isFront,
          beautyON: beautyON,
          roomName: roomName ? roomName : t("home.room.name"),
          oneVOne: false,
          isFaceLive: true,
        };
        var path = { pathname: "/liveinput", state: params };
        history.push(path);
      }
    } else {
      closeLive();
      history.push("/verified");
    }
  };

  const goBackHandle = () => {
    localTracks.videoTrack?.close();
    localTracks.videoTrack?.unpipe();
    localTracks.audioTrack?.close();
    localTracks.audioTrack?.unpipe();
    setProcessor(null);
    client.leave();
  };
  const share = () => {};

  const selectCheckbox = (index) => {
    setSelectIndex(index);
    console.log("############################selectIndex=" + index);
  };

  const oneVOneAct = () => {
    if (selectIndex === 1) {
      setAlertVisiable(true);
    } else if (selectIndex === 0) {
      setPriceAlertVisiable(true);
    } else {
      setFaceAlertVisiable(true);
    }
  };

  const changeFace = async () => {
    Toast.show({
      content: "开发中！",
      maskClickable: false,
      position: "bottom",
    });
  };

  const getDisplayText = (index) => {
    switch (index) {
      case 0:
        return t("prelive.1v1.setting");
      case 1:
        return t("prelive.show.setting");
      case 2:
        return t("prelive.aiface.setting");
      default:
        return "unknow";
    }
  };

  return (
    <div className="prelive-container">
      <NavigationBar
        titleColor={"#F7B500"}
        titleBorder={"2px solid #F7B500"}
        navTitle={t("prelive.title")}
        type={1}
        moreImg={"/images/live/live_share.png"}
        moreHandle={share}
        goBackHandle={goBackHandle}
      />
      <AgoraRTCProvider client={client}>
        <LocalUser
          className="prelive-live-container"
          audioTrack={localTracks.audioTrack}
          videoTrack={localTracks.videoTrack}
          micOn={micOn}
          cameraOn={cameraOn}
          cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
        ></LocalUser>
      </AgoraRTCProvider>
      <div className="prelive-main">
        <div className="prelive-main-info">
          <Image
            className="prelive-main-info-image"
            src="https://i.imgur.com/Ac0IpkB.jpg"
          />
          <div className="prelive-main-info-edit">{t("find.edit.cover")}</div>
          <div>
            <div className="prelive-main-info-text1">
              <Input
                className="prelive-main-info-input"
                placeholder={t("home.room.name")}
                value={roomName}
                onChange={(val) => {
                  setRoomName(val);
                }}
              />
              <Image
                className="prelive-main-info-text1-img"
                src="/images/live/live_pen.png"
              />
            </div>
            <div className="prelive-main-info-text2" onClick={oneVOneAct}>
              {getDisplayText(selectIndex)}
              <Image
                className="prelive-main-info-text2-img"
                src="/images/live/live_arrow.png"
              />
            </div>
          </div>
        </div>
        <div className="prelive-main-item">
          <Image
            className="prelive-main-item-option"
            src="/images/live/live_setting_kind.png"
          />
          <div className="prelive-main-item-text">
            {t("prelive.title.setting")}
          </div>
        </div>

        <div className="prelive-main-subitem" onClick={() => selectCheckbox(0)}>
          <Image
            className="prelive-main-subitem-option"
            src={
              selectIndex === 0
                ? "/images/live/live_checkbox_selected.png"
                : "/images/live/live_checkbox.png"
            }
          />
          <div className="prelive-main-item-text">{t("prelive.chat")}</div>
        </div>

        <div className="prelive-main-subitem" onClick={() => selectCheckbox(1)}>
          <Image
            className="prelive-main-subitem-option"
            src={
              selectIndex === 1
                ? "/images/live/live_checkbox_selected.png"
                : "/images/live/live_checkbox.png"
            }
          />
          <div className="prelive-main-item-text">{t("prelive.show")}</div>
        </div>
        <div className="prelive-main-subitem" onClick={() => selectCheckbox(2)}>
          <Image
            className="prelive-main-subitem-option"
            src={
              selectIndex === 2
                ? "/images/live/live_checkbox_selected.png"
                : "/images/live/live_checkbox.png"
            }
          />
          <div className="prelive-main-item-text">{t("prelive.aiface")}</div>
        </div>
        {selectIndex === 0 ? (
          <div
            className="prelive-main-subitem"
            onClick={() => setOneVOne(!oneVOne)}
          >
            <Image
              className="prelive-main-subitem-option"
              src={
                oneVOne
                  ? "/images/live/live_checkbox_selected.png"
                  : "/images/live/live_checkbox.png"
              }
            />
            <div className="prelive-main-item-text">{t("prelive.ovo")}</div>
          </div>
        ) : null}

        <div className="prelive-main-tool">
          <div onClick={changeCamera}>
            <Image
              className="prelive-main-tool-camera"
              src="/images/live/live_camera_turn.png"
            />
            <div className="prelive-main-tool-text">{t("prelive.turn")}</div>
          </div>

          {/* <div onClick={changeFace}>
            <Image
              className="prelive-main-tool-camera"
              src="/images/live/live_face_changing.png"
            />
            <div className="prelive-main-tool-text">{t("prelive.aiface")}</div>
          </div> */}

          <div onClick={openBeauty}>
            <Image
              className="prelive-main-tool-beauty"
              src="/images/live/live_beauty.png"
            />
            <div className="prelive-main-tool-text">{t("prelive.beauty")}</div>
          </div>
          <div onClick={changeFace}>
            <Image
              className="prelive-main-tool-kind"
              src="/images/live/live_partcul.png"
            />
            <div className="prelive-main-tool-text">{t("prelive.magic")}</div>
          </div>
          <div onClick={changeFace}>
            <Image
              className="prelive-main-tool-setting"
              src="/images/live/live_setting.png"
            />
            <div className="prelive-main-tool-text">{t("prelive.setting")}</div>
          </div>
          <div onClick={changeFace}>
            <Image
              className="prelive-main-tool-more"
              src="/images/live/live_setting_more.png"
            />
            <div className="prelive-main-tool-text">{t("prelive.more")}</div>
          </div>
        </div>

        {canClick ? (
          <div className="prelive-live-start" onClick={goToLive}>
            {t("prelive.begin")}
          </div>
        ) : // isVerified ? (
        //   <div className="prelive-live-start" onClick={goToLive}>
        //     {t("prelive.begin")}
        //   </div>
        // ) : (
        //   <div className="prelive-live-start" onClick={goToLive}>
        //     {t("prelive.go")}
        //   </div>
        // )
        null}
      </div>
      {alertVisiable ? (
        <ShowAlert
          startTime={startTime}
          endTime={endTime}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
          setAlertVisiable={setAlertVisiable}
          entranceFee={entranceFee}
          setEntranceFee={setEntranceFee}
          maxNumber={maxNumber}
          setMaxNumber={setMaxNumber}
        />
      ) : null}

      {priceAlertVisiable ? (
        <PriceAlert setAlertVisiable={setPriceAlertVisiable} userId={userId} />
      ) : null}

      {faceAlertVisiable ? (
        <FaceAlert setAlertVisiable={setFaceAlertVisiable} />
      ) : null}
    </div>
  );
};

export default Prelive;
