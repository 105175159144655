import React, { useState, useEffect, useCallback } from "react";
import { Button, Image } from "antd-mobile";
import Request from "../../../components/request/request";
import NavigationBar from "../../../uimodules/navbar";
import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./index.css";
const FaceAlert = ({ setAlertVisiable, userId }) => {
  const history = useHistory();
  const [priceList, setPriceList] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [videoFile, setVideoFile] = useState("");
  const { t } = useTranslation();
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    const fileType = file.type;
    const isVideo = fileType.startsWith("video/");
    const reader = new FileReader();

    reader.onload = (event) => {
      // 使用URL.createObjectURL方法将文件转换为URL
      const videoUrl = URL.createObjectURL(file);
      setImageUrl(videoUrl);
    };
    reader.readAsDataURL(file);
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    setVideoFile(file);
    const fileType = file.type;
    const isVideo = fileType.startsWith("video/");
    const reader = new FileReader();
    reader.onload = (event) => {
      // 使用URL.createObjectURL方法将文件转换为URL
      const videoUrl = URL.createObjectURL(file);
      setVideoUrl(videoUrl);
    };
    reader.readAsDataURL(file);
  };

  const submit = async () => {
    if (!imageFile) {
      Toast.show({
        content: "请选择换脸图片！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }

    if (!videoFile) {
      Toast.show({
        content: "请选择换脸视频！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }

    Toast.show({
      icon: "loading",
      content: t("toast.title.upload"),
      duration: 0,
      maskClickable: false,
    });
    const files = [
      {
        name: "image",
        file: imageFile,
      },{
        name: "video",
        file: videoFile,
      },
    ];
    try {
      const url = "/api/broadcaster/face/upload"; // 设置API端点
      const headers = {};
      const result = await Request.postData(url, {}, headers, files);
      console.log("result:", result);
      const { data, code, msg } = result;
      Toast.clear();
      if (code === 200) {
        setAlertVisiable(false);
      }
      Toast.show({
        content: msg,
        maskClickable: false,
        position: "bottom",
      });
    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
    console.log(JSON.stringify(priceList));
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div
      className="face-alert-container"
      onClick={() => setAlertVisiable(false)}
    >
      <Image src="/images/live/live_price_bg.png" className="face-alert-bg" />

      <div className="face-alert-main" onClick={(e) => e.stopPropagation()}>
        <Image
          src="/images/live/live-invite1v1.png"
          className="face-alert-main-top-img"
        />

        <div className="face-alert-main-top-title">换脸设置</div>

        <div className="face-alert-main-price">
          <div className="face-alert-main-price-item">
            {imageUrl ? (
              <Image
                className="face-alert-main-price-item-image"
                src={imageUrl}
              />
            ) : (
              <Image
                className="face-alert-main-price-item1"
                src="/images/live/verified_add.png"
              />
            )}
            <div className="face-alert-main-price-item2"></div>

            <div className="face-alert-main-price-item3">换脸照片</div>
          </div>
          <div className="face-alert-main-price-item">
            {videoUrl ? (
              <video className="face-alert-main-price-item1" src={videoUrl} />
            ) : (
              <Image
                className="face-alert-main-price-item1"
                src="/images/live/verified_add.png"
              />
            )}

            <div className="face-alert-main-price-item2"></div>

            <div className="face-alert-main-price-item3">换脸视频</div>
          </div>
          <div className="face-alert-main-bottom-notice">
            1、照片必须高清，无过度压缩
          </div>
          <div className="face-alert-main-bottom-notice">
            2、请上传3分钟30帧的视频
          </div>

          <input
            className="face-alert-add-image"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />

          <input
            className="face-alert-add-video"
            type="file"
            accept="video/*"
            onChange={handleVideoChange}
          />
        </div>

        <div className="face-alert-main-sure" onClick={submit}>
        {t("show.sure")}
        </div>
      </div>
    </div>
  );
};

export default FaceAlert;
