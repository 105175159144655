import React, { useState, useEffect } from "react";
import NavigationBar from "../../uimodules/navbar";
import mammoth from "mammoth";
import { useLocation } from "react-router-dom";
import "react-h5-audio-player/lib/styles.css";
import "./index.css";
const WordFile = () => {
  const location = useLocation();
  const [docHtml, setDocHtml] = useState("");
  const docUrl = location.state?.fileName//"/word/Terms of Use.docx"; // 文件位于 public/docs/example.docx
  const title = location.state?.title;
  useEffect(() => {
    fetch(docUrl)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        mammoth
          .convertToHtml({ arrayBuffer })
          .then((result) => {
            const newStr = result.value.replace("Top of Form", "");
            setDocHtml(newStr);
          })
          .catch((err) => {
            console.error("Failed to convert document:", err);
          });
      })
      .catch((error) => {
        console.error("Failed to fetch document:", error);
      });
  }, [docUrl]);


  return (
    <div className="termsofuse-container">
      <NavigationBar navTitle={title} />
      <div className="termsofuse-policy" dangerouslySetInnerHTML={{ __html: docHtml }} />

 
    </div>
  );
};

export default WordFile;
