import React, { useState, useEffect, useCallback } from "react";
import { Button, Image } from "antd-mobile";
//import Request from "../../components/request/request";
import NavigationBar from "../../../uimodules/navbar";
//import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";

import "./index.css";
const Topup = () => {
  const history = useHistory();

  return (
    <div className="topup-container">
      <NavigationBar />
      
      <div  className="topup-title-pay">
      <Image src="/images/chat/topup_warning.png" className="topup-logo"/>
        <div className="topup-title-begin"> 余额不足</div>
        
      </div>

      <div className="topup-sure">去充值</div>

      <div className="topup-cancel">取消</div>
    </div>
  );
};

export default Topup;
