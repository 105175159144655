import React, { useState, useEffect, useCallback } from "react";
import { Image } from "antd-mobile";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import FireBaseConfiger from "../../components/firebase/index";
import AIMEEaseMob from "../../components/easemob/index";
import { useTranslation } from 'react-i18next';
import "./index.css";
const Mine = () => {
  const history = useHistory();
  const [userPhoto, setUserPhoto] = useState("");
  const [account, setAccount] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const { t } = useTranslation();
  const [mineList] = useState([
    {
      title: t("mine.title1"),
      icon: "/images/mine/mine_person.png",
      path: "personal",
    },
    { title:t("mine.title2"), icon: "/images/mine/mine_earn.png", path: "income" },
    {
      title: t("mine.title3"),
      icon: "/images/mine/mine-person_cer.png",
      path: "verified",
    },
    { title: t("mine.title4"), icon: "/images/mine/mine_share.png", path: "share" },
    { title: t("mine.title5"), icon: "/images/mine/mine_tag.png", path: "tags" },
    { title: "FAQ", icon: "/images/mine/mine_fqa.png", path: "faq", height:"18px" },
    { title: t("mine.title6"), icon: "/images/mine/mine_corp.png", path: "wordfile" },
    { title: t("mine.title7"), icon: "/images/mine/mine_setting.png", path: "setting" },
  ]);

  const readLocal = () => {
    const data = localStorage.getItem("userdata");
    if (data) {
      const userInfo = JSON.parse(data) || {};
      const { account } = userInfo || {};
      const { avatar_path, nickname, unique_nickname_id } = account || {};
      console.log(userInfo);
      setUserPhoto(avatar_path);
      setAccount(account);
    }
  };

  const fetchData = async () => {
    try {
      const url = "/api/user/info"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data,code,msg } = result;
      if(code === 200){
        setUserInfo(data);
      }else{
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }

    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  useEffect(() => {
    readLocal();
    fetchData();
    return () => {};
  }, []);

  const openPath = (path) => {
    if(path === "wordfile"){
      history.push("/wordfile", {
        title: t("mine.title6"),
        fileName: "/word/Affiliate.docx",
      });
      return;
    }
    history.push(path);
  };
  const withdraw = () => {
    history.push("/withdraw");
  };

  const recharge = () => {
    history.push("/recharge");
  };

  const logout = async() => {
    try {
      const url = "/api/user/logout"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data } = result;
      setUserInfo(data);
      localStorage.setItem("authorization", "");
      localStorage.setItem("userdata", "");
      window.selectTab = "home"
      history.push("/home");
      FireBaseConfiger.logout();
      AIMEEaseMob.conn.close();
    } catch (error) {
      localStorage.setItem("authorization", "");
      localStorage.setItem("userdata", "");
      window.selectTab = "home"
      history.push("/home");
      FireBaseConfiger.logout();
      AIMEEaseMob.conn.close();
      console.error("Error:", error); // 打印错误信息
    }
  };
  
  return (
    <div className="mine-container">
      <NavigationBar />
      <div className="header-container">
        <Image className="header-bg" src="/images/mine/mine_header_bg.png" />
        <Image className="header-user-photo" src={userInfo?.avatar_path} />
        <div className="header-name">{userInfo?.nickname}</div>
        <div className="header-id">@{userInfo.unique_nickname_id}</div>
        <div className="header-lnf">
          <div className="header-lnf-cell">
            <div className="header-lnf-cell-text">
              {userInfo?.num_followings}
            </div>
            <div className="header-lnf-cell-text">{t("mine.focuson")}</div>
          </div>
          <div className="header-lnf-cell">
            <div className="header-lnf-cell-text">{userInfo?.num_followers}</div>
            <div className="header-lnf-cell-text">{t("mine.fan")}</div>
          </div>
          <div className="header-lnf-cell">
            <div className="header-lnf-cell-text">{userInfo?.num_likes}</div>
            <div className="header-lnf-cell-text">{t("mine.liked")}</div>
          </div>
        </div>
      </div>

      <div className="mine-money">
        <Image className="mine-money-bg" src="/images/mine/mine_money.png" />
        <div className="mine-money-title">{t("mine.account.balance")}</div>
        <Image
          className="mine-money-coin"
          src="/images/mine/mine-money-coin.png"
        />
        <div className="mine-money-number">{userInfo?.scores_balance}</div>
        <div className="mine-money-withdraw" onClick={withdraw}>
          <div className="mine-money-btn-txt">{t("mine.withdraw")}</div>
          <Image
            src="/images/mine/mine-mini-arrow.png"
            className="mine-money-mini-arrow"
          />
        </div>
        <div className="mine-money-topup" onClick={recharge}>
          <div className="mine-money-btn-txt">{t("mine.topup")}</div>
          <Image
            src="/images/mine/mine-mini-arrow.png"
            className="mine-money-mini-arrow"
          />
        </div>
      </div>

      <div className="mine-list">
        {(mineList ?? []).map((item) => (
          <div
            key={item.title}
            className="mine-list-item"
            onClick={() => openPath(item.path)}
          >
            <Image src={item.icon} className="mine-list-item-img" style={{ height: item.height || '24px' }} />
            <div className="mine-list-item-title">{item.title}</div>
            <Image
              src={"/images/mine/mine_arrow.png"}
              className="mine-list-item-arrow"
            />
          </div>
        ))}
      </div>

      <div className="mine-logout" onClick={logout}>
   
        {t("mine.logout")}
        
      </div>
      <div className="mine-black"  />
  
      
    </div>
  );
};

export default Mine;
