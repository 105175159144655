import React from "react";
import { useHistory } from "react-router-dom";
import { Toast } from "antd-mobile";
import { useTranslation } from "react-i18next";
import "./index.css";
const PopView = ({ popVisiable, setPopVisiable }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const openSelfPage = () => {
    setPopVisiable(false);
    history.push({ pathname: "/myinfo", state: {} });
  };
  const openFindPage = () => {
    // window.selectTab = "find";
    setPopVisiable(false);
    history.push("/editfind");
  };
  const openLibraryPage = () => {
    setPopVisiable(false);
    history.push("library");
  };
  const openLivePage = () => {
    setPopVisiable(false);
    history.push("/prelive");
  };

  const changeFace = () => {
    setPopVisiable(false);
    Toast.show({
      content: "开发中！",
      maskClickable: false,
      position: "bottom",
    });
  };

  const touchBg = () => {
    if (popVisiable) {
      setPopVisiable(false);
    }
  };

  return (
    <div className="pop-container" onClick={touchBg}>
      <div className="pop-view">
        <div className="pop-view-btn1" onClick={changeFace}>
 
          {t("popview.ai.title")}
        </div>
        <div className="pop-view-btn2" onClick={openSelfPage}>
          {t("popview.pp.title")}
        </div>
        <div className="pop-view-btn2" onClick={openFindPage}>
        {t("popview.find.title")}
        
        </div>
        <div className="pop-view-btn2" onClick={openLibraryPage}>
        {t("popview.library.title")}
 
        </div>
        <div className="pop-view-btn2" onClick={openLivePage}>
        {t("popview.studio.title")}
 
        </div>
      </div>
    </div>
 
  );
};

export default PopView;
