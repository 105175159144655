import React, { useState, useEffect, useCallback } from "react";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { Tabs, Toast, Image } from "antd-mobile";
import { useHistory, useLocation } from "react-router-dom";
import AIMEEaseMob from "../../components/easemob/index";
import WebIM from "easemob-websdk";
import { useTranslation } from 'react-i18next';
import "./index.css";
const Library = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [videoList, setVideoList] = useState([]);
  const [pictureList, setPictureList] = useState([]);
  const location = useLocation();
  const isSelectLibrary = location.state?.isSelectLibrary;
  const chatItem = location.state?.item;
  const loadList = async (index) => {
    try {
      const url = "/api/post/gallery";
      const headers = {};
      const params = {
        type: index,
        include_public: isSelectLibrary ? (chatItem ? 1 : 0) : 1,
      };
      const result = await Request.get(url, params, headers);
      const { data, code, msg } = result;
      if (code === 200) {
        if (index == 1) {
          setPictureList(data);
        } else {
          setVideoList(data);
        }
      }
      // Toast.clear();
      console.log("data:", data);
    } catch (error) {
      // Toast.clear();
      console.error("Error:", error);
    }
  };

  const delItem = async (event, id, index) => {
    event.stopPropagation();
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/post/deletePost";
      const headers = {};
      const params = {
        post_id: id,
      };
      const result = await Request.post(url, params, headers);
      const { data, code, msg } = result;
      Toast.clear();
      if (code === 200) {
        loadList(index);
      }
      console.log("data:", data);
    } catch (error) {
      Toast.clear();
      console.error("Error:", error);
    }
  };

  const handleReturn = async (item) => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.upload"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/post/editPost";
      const headers = {};
      const params = {
        post_id: item.id,
        content: item.content,
        paid_content: item.paid_content + "",
        price: item.price,
        public: "1",
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, msg } = result;

      if (code === 200) {
        setTimeout(function () {
          Toast.clear();
          history.goBack();
        }, 2000);
      } else {
        Toast.clear();
      }
      Toast.show({
        content: msg,
        maskClickable: false,
        position: "bottom",
      });
    } catch (error) {
      Toast.clear();
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };

  const sendMessage = (item) => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.upload"),
      duration: 0,
      maskClickable: false,
    });

    let customEvent = item.type === 0 ? "video" : "image";
    let customExts = item;
    let option = {
      type: "custom",
      to: "" + chatItem.external_uid,
      chatType: "singleChat",
      customEvent,
      customExts,
      ext: {},
    };

    console.log("########sendTextMessage=", JSON.stringify(option));
    let msg = WebIM.message.create(option);
    AIMEEaseMob.conn
      .send(msg)
      .then((res) => {
        Toast.clear();
        history.goBack();
      })
      .catch((e) => {
        Toast.clear();
        console.log("######Send message fail", e);
      });
  };

  const postCheck = async (item) => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.upload"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/post/check-broadcaster-sent-post-to-user";
      const headers = {};
      const params = {
        post_id: item.id,
        account_id: item.account_id,
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, data, msg } = result;
      Toast.clear();
      if (code === 200) {
        sendMsgCheck(item);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.clear();
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };

  const sendMsgCheck = (item) => {
    AIMEEaseMob.conn.addEventHandler("handlerId", {
      onConnected: () => {
        sendMessage(item);
      },
    });
    if (AIMEEaseMob.conn.isOpened()) {
      sendMessage(item);
    } else {
      AIMEEaseMob.easeUserToken();
    }
  };

  const libEdit = (item) => {
    if (isSelectLibrary) {
      if (chatItem) {
        postCheck(item);
      } else {
        handleReturn(item);
      }
    } else {
      history.push({ pathname: "/libraryedit", state: { item: item } });
    }
  };

  const isImage = (path) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(path);
  };

  // Function to determine if the path is a video
  const isVideo = (path) => {
    return /\.(mp4|webm|ogg)$/i.test(path);
  };

  useEffect(() => {
    console.log("########################11", chatItem);
    loadList(1);
    return () => {};
  }, []);

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    const fileType = file.type;
    const isVideo = fileType.startsWith("video/");
    createMedia(file, isVideo);
  };

  const createMedia = async (file, isVideo) => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.upload"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/post/createPost";
      const headers = {};
      const params = {
        content: "",
        paid_content: "0",
        price: "0",
        public: 0,
      };
      const result = await Request.postFormData(url, params, headers, file);
      console.log("result:", result);
      const { code, msg } = result;
      Toast.clear();
      if (code === 200) {
        loadList(isVideo ? 0 : 1);
      }
      Toast.show({
        content: msg,
        maskClickable: false,
        position: "bottom",
      });
    } catch (error) {
      Toast.clear();
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };
 
  return (
    <div className="library-container">
      <NavigationBar navTitle={t("library.title")} />

      <Tabs
        className="library-tabs"
        onTabClick={(tab, index) => {
          console.log("onTabClick", index, tab);
        }}
        onChange={(key) => {
          console.log("onChange", key);
          loadList(key);
        }}
      >
        <Tabs.Tab title={t("library.picture")} key="1">
          <div className="library-list">
            {(pictureList ?? []).map((item) => (
              <div
                key={item.title}
                className="library-list-item"
                onClick={() => libEdit(item)}
              >
                {isImage(item.path) ? (
                  <img
                    fit="cover"
                    src={item.path}
                    className="library-list-item-img"
                    alt="Media"
                  />
                ) : isVideo(item.path) ? (
                  <video
                    src={item.path}
                    className="library-list-item-img"
                    // controls
                  />
                ) : (
                  <p>Unsupported file type</p>
                )}
                <div className="library-list-item-title">{item.content}</div>
                <div className="library-list-item-money">
                  <Image
                    className="library-list-item-coin"
                    src="/images/mine/mine-money-coin.png"
                  />
                  {item.price}
                </div>
                <div className="library-list-item-number">
                {t("library.sell")} {item.sold}{t("library.sell.number")}
                </div>

                <div className="library-list-item-tag">
                  {item.id.substring(item.id.length - 5, item.id.length)}
                </div>
                {/* <Link to={{ pathname: "/share", state: { item } }}>
                <Image
                  onClick={() => shareItem(item.id)}
                  src={"/images/live/live_share.png"}
                  className="library-list-item-share"
                />
                </Link> */}
                <Image
                  onClick={(event) => delItem(event, item.id, 1)}
                  src={"/images/live/live_trash.png"}
                  className="library-list-item-trash"
                />
              </div>
            ))}
          </div>
        </Tabs.Tab>
        <Tabs.Tab title={t("library.video")} key="0">
          <div className="library-list">
            {(videoList ?? []).map((item) => (
              <div
                key={item.id}
                className="library-list-item"
                onClick={() => libEdit(item)}
              >
                {isImage(item.path) ? (
                  <img
                    fit="cover"
                    src={item.path}
                    className="library-list-item-img"
                    alt="Media"
                  />
                ) : isVideo(item.path) ? (
                  <video
                    src={item.path}
                    className="library-list-item-img"
                    // controls
                  />
                ) : (
                  <p>Unsupported file type</p>
                )}

                <div className="library-list-item-title">{item.content}</div>
                <div className="library-list-item-money">
                  <Image
                    className="library-list-item-coin"
                    src="/images/mine/mine-money-coin.png"
                  />
                  {item.price}
                </div>
                <div className="library-list-item-number">
                {t("library.sell")} {item.sold}{t("library.sell.number")}
                </div>

                <div className="library-list-item-tag">
                  {item.id.substring(item.id.length - 5, item.id.length)}
                </div>
                {/* <Link to={{ pathname: "/share", state: { item } }}>
                <Image
                  src={"/images/live/live_share.png"}
                  className="library-list-item-share"
                />
                </Link> */}
                <Image
                  onClick={(event) => delItem(event, item.id, 0)}
                  src={"/images/live/live_trash.png"}
                  className="library-list-item-trash"
                />
              </div>
            ))}
          </div>
        </Tabs.Tab>
      </Tabs>
      {!isSelectLibrary ? (
        <div>
          <Image
            src={"/images/library/library_add.png"}
            className="library-add"
          />
          <input
            className="library-add-upload"
            type="file"
            accept="image/*, video/*"
            onChange={handleVideoChange}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Library;
