import React, { useState, useEffect, useCallback } from "react";
import { Toast, Image } from "antd-mobile";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./index.css";
const Report = () => {
  const history = useHistory();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState("");
  const [optionsList, setOptionsList] = useState([]);
  const { item } = location.state || {};
  const { t } = useTranslation();
  const getOptionList = async () => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/report/option-list";
      const headers = {};
      const params = {};
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { code, msg, data } = result;
 
      if (code === 200) {
        setOptionsList(data);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
      Toast.clear();
    } catch (error) {
      Toast.clear();
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };

  const reportSubmit = async () => {
    console.log("###############",item);
    if (selectedOption.length===0) {
      Toast.show({
        content: t("report.toast.msg"),
        maskClickable: false,
        position: "bottom",
      });
      return;
    }
    Toast.show({
      icon: "loading",
      content: t("toast.process"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/report/new"; // 设置API端点
      const headers = {};
      const params = {
        target_user_id:item.account_id,
        report_item_id:selectedOption
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data,code,msg } = result;
      Toast.show({
        content: msg,
        maskClickable: false,
        position: "bottom",
      });
      if(code===200){
        history.goBack();
        Toast.success(msg, 1);
      }else{
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
      Toast.clear();
    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
  }

  useEffect(() => {
    getOptionList();
    return () => {};
  }, []);

  return (
    <div className="report-container">
      <NavigationBar navTitle={t("report.title")} />

      <div className="report-list">
        <h2 className="report-title">{t("report.content")}</h2>

        {(optionsList ?? []).map((option, index) => (
        
            <div
              key={index}
              className="report-list-item"
              onClick={() => setSelectedOption(option.report_item_id)}
            >
              <Image
                className="report-list-item-checkbox"
                src={
                  selectedOption === option.report_item_id
                    ? "/images/mine/report_selected.png"
                    : "/images/mine/report_unselected.png"
                }
              />

              <label className="report-list-item-txt">{option.param_value}</label>
            </div>
          ))}
          <div className="report-list-submit" onClick={reportSubmit}>
            {t("report.submit")}
          </div>
  
      </div>
    </div>
  );
};

export default Report;
