import React, { useRef, useState, useEffect, useCallback } from "react";
import { Image } from "antd-mobile";
import { useLocation } from "react-router-dom";
import NavigationBar from "../../uimodules/navbar";
import { Toast, PullToRefresh } from "antd-mobile";
import { useHistory } from "react-router-dom";
import WebIM from "easemob-websdk";
import Request from "../../components/request/request";
import AIMEEaseMob from "../../components/easemob/index";
import AIMEStorage from "../../components/storage/aimestorage";
import { ImageViewer, Mask } from "antd-mobile";
import { useTranslation } from 'react-i18next';
import { PullStatus } from "antd-mobile/es/components/pull-to-refresh";
import "./index.css";
const Chat = () => {
  const history = useHistory();
  const location = useLocation();
  const { item } = location.state || {};
  const [userPhoto, setUserPhoto] = useState("");
  const [emojiView, setEmojiView] = useState(false);
  const [imageVisible, setImageVisible] = useState(false);
  const [quickImageUrl, setQuickImageUrl] = useState("");
  const [videoVisible, setVideoVisible] = useState(false);
  const [quickVideoUrl, setQuickVideoUrl] = useState("");
  const [chatList, setChatList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [emojiList] = useState(AIMEStorage.emoji);
  const { t } = useTranslation();

  const statusRecord = {
    pulling: "用力拉",
    canRelease: "松开吧",
    refreshing: "玩命加载中...",
    complete: "好啦",
  };

  const scrollToBottom = () => {
    // 使用 window.scrollTo 方法滚动到页面底部
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth", // 启用平滑滚动
    });
  };

  const msgNotice = () => {
    AIMEEaseMob.conn.addEventHandler("handlerId", {
      onTextMessage: (message) => {
        console.log("################## 收到消息：", message);
        let itemDict = makeTxtMsg(message);
        setChatList((prevChatList) => [...prevChatList, itemDict]);
        setTimeout(scrollToBottom, 100);
      },
      onImageMessage: (message) => {},
      onCustomMessage: (message) => {
        console.log("################## 收到消息：", message);
        let itemDict = makeMediaMsg(message);
        setChatList((prevChatList) => [...prevChatList, itemDict]);
        setTimeout(scrollToBottom, 100);
      },
      onConnected: () => {
        console.log("##################onConnected");
        getHistory();
      },
    });

    if (AIMEEaseMob.conn.isOpened()) {
      getHistory();
    } else {
      AIMEEaseMob.easeUserToken();
    }
  };

  const makeTxtMsg = (message) => {
    const { id, type, msg, from, to } = message;
    let isMe = item?.external_uid === to;
    let itemDict = {
      id: id,
      from: from,
      type: 1,
      msg: msg,
      isMe: isMe,
    };
    return itemDict;
  };

  const makeMediaMsg = (message) => {
    const { id, type, from, to } = message;
    const { price, preview_path, path } = message.customExts;
    let isExit = AIMEStorage.searchUnlockList(message.customExts?.id);
    let isMe = item?.external_uid === to;
    let itemDict = {
      id: id,
      mediaId: message.customExts.id,
      from: from,
      type: 2,
      price: price,
      mediaType: message.customExts?.type,
      isPayed: isExit,
      path: path,
      previewPath: preview_path,
      isMe: isMe,
    };
    return itemDict;
  };

  const imageQuickLock = (mediaItem) => {
    console.log("##############1", mediaItem);
    if (mediaItem.price > 0 && !mediaItem.isMe && !mediaItem.isPayed) {
      unlock(mediaItem);
      return;
    }
    const mediaPath = mediaItem.path;
    if (mediaItem.mediaType == 1) {
      setImageVisible(true);
      setQuickImageUrl(mediaPath);
    } else {
      setVideoVisible(true);
      setQuickVideoUrl(mediaPath);
    }
  };

  const sendTextMessage = (event) => {
    if (event.charCode !== 13) {
      return;
    }
    
    AIMEEaseMob.conn.addEventHandler("handlerId", {
      onConnected: () => {
        sendMessage(item);
      },
    });
    if (AIMEEaseMob.conn.isOpened()) {
      sendMessage(item);
    } else {
      AIMEEaseMob.easeUserToken();
    }
   
  };

  const sendMessage = () => {
    console.log(inputValue);
    if (!inputValue) {
      return;
    }
    setEmojiView(false);

    let option = {
      type: "txt",
      msg: inputValue,
      to: item?.external_uid,
      chatType: "singleChat",
    };
    console.log("########sendTextMessage=", JSON.stringify(option));
    let msg = WebIM.message.create(option);
    AIMEEaseMob.conn
      .send(msg)
      .then((res) => {
        const { message } = res;
        let itemDict = makeTxtMsg(message);
        setChatList((prevChatList) => [...prevChatList, itemDict]);
        setTimeout(scrollToBottom, 100);
        setInputValue("");
      })
      .catch((e) => {
        console.log("######Send message fail", e);
      });
  };

  const replaceChatById = (id, newChat) => {
    setChatList((prevChatList) => {
      const index = prevChatList.findIndex((chat) => chat.id === id);
      if (index === -1) return prevChatList; // 如果没有找到，返回原数组

      return [
        ...prevChatList.slice(0, index),
        newChat,
        ...prevChatList.slice(index + 1),
      ];
    });
  };

  const unlock = async (listItem) => {
    Toast.show({
      icon: "loading",
      content: "unlocking…",
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/post/unlock";
      const headers = {};
      const params = {
        post_id: listItem.mediaId,
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { code, msg } = result;
      Toast.clear();
      if (code === 200) {
        AIMEStorage.addUnlockItem(listItem.mediaId);
        listItem.isPayed = true;
        replaceChatById(listItem.id, listItem);
      }
      Toast.show({
        content: msg,
        maskClickable: false,
        position: "bottom",
      });
    } catch (error) {
      Toast.clear();
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };

  const readLocal = () => {
    const data = localStorage.getItem("userdata");
    if (data) {
      const userInfo = JSON.parse(data) || {};
      const { account } = userInfo || {};
      const { avatar_path } = account || {};
      console.log("####################userInfo");
      console.log(userInfo);
      console.log("####################item", avatar_path, item?.avatar_path);
      console.log(item);
      setUserPhoto(avatar_path);
    }
  };

  const getHistory = () => {
    let options = {
      // 对方的用户 ID 或者群组 ID 或聊天室 ID。
      targetId: item?.external_uid,
      // 每页期望获取的消息条数。取值范围为 [1,50]，默认值为 20。
      pageSize: 20,
      // 查询的起始消息 ID。若该参数设置为 `-1`、`null` 或空字符串，从最新消息开始。
      cursor: -1,
      // 会话类型：（默认） `singleChat`：单聊；`groupChat`：群聊；`chatRoom`：聊天室
      chatType: "singleChat",
      // 消息搜索方向：（默认）`up`：按服务器收到消息的时间的逆序获取；`down`：按服务器收到消息的时间的正序获取。
      searchDirection: "up",
    };
    AIMEEaseMob.conn
      .getHistoryMessages(options)
      .then((res) => {
        // 成功获取历史消息。
        console.log("#########################res.messages", res.messages);

        const { messages } = res;
        var chatL = [];
        if (messages) {
          let number = 0;
          for (let index = messages.length - 1; index >= 0; index--) {
            let message = messages[index];
            if (message.type === "txt") {
              let itemDict = makeTxtMsg(message);

              chatL[number++] = itemDict;
            } else {
              let itemDict = makeMediaMsg(message);

              chatL[number++] = itemDict;
            }
          }
          console.log("#################chatL", chatL);
          setChatList(chatL);
        }

        setTimeout(scrollToBottom, 100);
      })
      .catch((e) => {
        // 获取失败。
      });
  };

  const handleEmojiSelect = (emoji) => {
    console.log("Selected Emoji:", emoji);
    setInputValue(inputValue + emoji);
  };

  const selectLibrary = () => {
    history.push("library", { isSelectLibrary: true, item: item });
  };

  useEffect(() => {
    msgNotice();
    readLocal();
    setTimeout(scrollToBottom, 100);
    return () => {};
  }, []);

  function renderMedia(listItem) {
    if (listItem.mediaType === 1) {
      return (
        <Image
          className="chat-list-item-photo-gs"
          src={
            listItem.isPayed 
              ? listItem.path
              : listItem.previewPath
          }
        />
      );
    } else {
      return (
        <div>
          {listItem.isPayed ? (
            <Image
              className="chat-list-item-photo-play"
              src="/images/library/video_play.png"
            />
          ) : null}
          <Image
            className="chat-list-item-photo-gs"
            src={listItem.previewPath}
          />
        </div>
      );
    }
  }

  return (
    <div className="chat-container">
      <NavigationBar navTitle={item?.nickname} bgColor={"#29282b"} />
      <PullToRefresh
        onRefresh={async () => {
          getHistory();
        }}
        renderText={(status) => {
          return <div>{statusRecord[status]}</div>;
        }}
      >
        <div className="chat-list">
          {(chatList ?? []).map((listItem) => (
            <div key={listItem.id}>
              {!listItem.isMe ? (
                <div className="chat-list-item-l">
                  <Image
                    src={
                      item?.avatar_path
                        ? item?.avatar_path
                        : "https://i.imgur.com/sb7TZMc.jpeg"
                    }
                    className="chat-list-item-logo"
                  />
                  {listItem.type === 1 ? (
                    <div className="chat-list-item-text-white">
                      <div className="chat-list-item-msg">{listItem.msg}</div>
                    </div>
                  ) : (
                    <div
                      className="chat-list-item-photo"
                      onClick={() => imageQuickLock(listItem)}
                    >
                      {renderMedia(listItem)}

                      {listItem.price > 0 && !listItem.isPayed ? (
                        <div>
                          <Image
                            className="chat-list-item-photo-lock"
                            src={
                              listItem.mediaType !== 1
                                ? "/images/library/video_play.png"
                                : "/images/chat/chat_lock.png"
                            }
                          />
                          <div className="chat-list-item-photo-money">
                            <Image
                              className="chat-list-item-photo-money-coin"
                              src={"/images/chat/chat_coin.png"}
                            />
                            <div className="chat-list-item-photo-money-count">
                              {listItem.price}
                            </div>
                            <div className="chat-list-item-photo-money-unlock">
                       
                              {t("chat.unlock.now")}
                              
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              ) : (
                <div key={listItem.id} className="chat-list-item-r">
                  <Image
                    src={
                      userPhoto ? userPhoto : "https://i.imgur.com/sb7TZMc.jpeg"
                    }
                    className="chat-list-item-logo"
                  />
                  {listItem.type === 1 ? (
                    <div className="chat-list-item-text-yellow">
                      <div className="chat-list-item-msg">{listItem.msg}</div>
                    </div>
                  ) : (
                    <div
                      className="chat-list-item-photo"
                      onClick={() => imageQuickLock(listItem)}
                    >
                      {renderMedia(listItem)}
                      {listItem.price > 0 ? (
                        <div>
                          <Image
                            className="chat-list-item-photo-lock"
                            src={
                              listItem.mediaType !== 1
                                ? "/images/library/video_play.png"
                                : "/images/chat/chat_lock.png"
                            }
                          />
                          <div className="chat-list-item-photo-money">
                            <Image
                              className="chat-list-item-photo-money-coin"
                              src={"/images/chat/chat_coin.png"}
                            />
                            <div className="chat-list-item-photo-money-count">
                              {listItem.price}
                            </div>
                            <div className="chat-list-item-photo-money-unlock">
                              {t("chat.unlock.now")}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="chat-box">
          <div className="chat-input">
            <Image
              src="/images/chat/chat_photo.png"
              className="chat-input-image"
              onClick={selectLibrary}
            />
            <Image
              onClick={() => setEmojiView(!emojiView)}
              src="/images/chat/chat_emoj.png"
              className="chat-input-image"
            />

            <input
              className="chat-inputbox"
              type="searchText"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={sendTextMessage}
              placeholder={t("live.chat.placeholder")}
            />
            <Image
              src="/images/chat/chat_send.png"
              onClick={() => sendMessage()}
              className="chat-input-send"
            />
          </div>
          {emojiView ? (
            <div className="chat-input-emoji">
              {emojiList.map((emoji, index) => (
                <button
                  key={index}
                  className="emoji-btn"
                  onClick={() => handleEmojiSelect(emoji)}
                >
                  {emoji}
                </button>
              ))}
            </div>
          ) : null}
        </div>
      </PullToRefresh>
      <ImageViewer
        classNames={{
          mask: "customize-mask",
          body: "customize-body",
        }}
        image={quickImageUrl}
        visible={imageVisible}
        onClose={() => {
          setImageVisible(false);
        }}
      />

      <Mask visible={videoVisible} onMaskClick={() => setVideoVisible(false)}>
        <video
          fit="cover"
          src={quickVideoUrl}
          className="my-video-style"
          controls
        />
      </Mask>
    </div>
  );
};

export default Chat;
