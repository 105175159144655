import React, { useState, useEffect, useCallback } from "react";
import { Image } from "antd-mobile";
import Request from "../../../components/request/request";
import NavigationBar from "../../../uimodules/navbar";
import { TextArea, Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./index.css";
const LibraryEdit = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [selectMediaFile, setSelectMediaFile] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [isVideo, setIsVideo] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectIndex, setSelectIndex] = useState(1);
  const [price, setPrice] = useState("");
  const item = location.state.item;

  // const handleVideoChange = (event) => {
  //   const file = event.target.files[0];
  //   const fileType = file.type;
  //   const isVideo = fileType.startsWith("video/");
  //   setIsVideo(isVideo);
  //   setSelectMediaFile(file);
  //   if(isVideo){
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       // 使用URL.createObjectURL方法将文件转换为URL
  //       const videoUrl = URL.createObjectURL(file);
  //       setSelectedVideo(videoUrl);
  //     };
  //     // 读取文件内容
  //     reader.readAsDataURL(file);
  //   }else{
  //     setSelectedVideo(URL.createObjectURL(file));
  //   }
  // };

  const createMedia = async () => {

    if (inputValue.length === 0) {
      Toast.show({
        content: t("input.search.placehold"),
        maskClickable: false,
        position: "bottom",
      });
      return;
    }

    if (selectIndex===1&&parseInt(price)<=0) {
      Toast.show({
        content: t("toast.error.money"),
        maskClickable: false,
        position: "bottom",
      });
      return;
    }

    Toast.show({
      icon: "loading",
      content: t("toast.title.upload"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/post/editPost";
      const headers = {};
      const params = {
        post_id: item.id,
        content: inputValue,
        paid_content: selectIndex===1?"1":"0",
        price: selectIndex===1?price:"0",
        public: "0",
      };
      const result = await Request.post(
        url,
        params,
        headers
      );
      console.log("result:", result);
      const { code, msg } = result;
      Toast.clear();
      if (code === 200) {
        history.goBack();
      }
      Toast.show({
        content: msg,
        maskClickable: false,
        position: "bottom",
      });
    } catch (error) {
      Toast.clear();
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setPrice(item?.price?item?.price:"");
    setInputValue(item?.content?item?.content:"");
    setSelectIndex(item?.price>0?1:0);
    return () => {};
  }, [location.state]);

  return (
    <div className="library-edit-edit-container">
      <NavigationBar navTitle={t("library.edit.lib")} />

      {item.type===0 ? (
        <video className="library-edit-item-media" src={item.path}  controls/> //controls
      ) : (
        <Image src={item.path} className="library-edit-item-media" />
      )}

      <TextArea
        className="library-edit-item-inputbox"
        value={inputValue}
        placeholder={"Write a short description (0-50 words)"}
        onChange={(val) => {
          setInputValue(val);
        }}
      />
      {/* {!selectedVideo && (
        <div className="library-edit-item-upload">
          <Image
            src="/images/live/verified_add.png"
            className="library-edit-item-upload-image"
          />
          <div className="library-edit-item-upload-txt">请选择作品</div>
        </div>
      )} */}
      <div className="library-edit-main">
        <div className="library-edit-main-subitem" onClick={() => setSelectIndex(0)}>
          <Image
            className="library-edit-main-subitem-option"
            src={
              selectIndex === 0
                ? "/images/live/live_checkbox_selected.png"
                : "/images/live/live_checkbox.png"
            }
          />
          <div className="library-edit-main-item-text">{t("library.edit.free")}</div>
        </div>

        <div className="library-edit-main-subitem" onClick={() => setSelectIndex(1)}>
          <Image
            className="library-edit-main-subitem-option"
            src={
              selectIndex === 1
                ? "/images/live/live_checkbox_selected.png"
                : "/images/live/live_checkbox.png"
            }
          />
          <div className="library-edit-main-item-text">{t("library.edit.pay")}</div>
        </div>
      </div>
      {selectIndex === 1 ? (
              <input
              className="library-edit-main-price"
              type="email"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder={t("library.edit.placehold")}
            />
 
      ) : null}

      {/* <input
        className="library-edit-upload"
        type="file"
        accept="image/*, video/*"
        onChange={handleVideoChange}
      /> */}
      <div className="library-edit-go-next" onClick={createMedia}>
        {t("btn.next")}
      </div>
    </div>
  );
};

export default LibraryEdit;
