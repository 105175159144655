import React, { useState, useEffect, useCallback } from "react";
import NavigationBar from "../../uimodules/navbar";
import mammoth from "mammoth";
import { useTranslation } from 'react-i18next';
import "./index.css";
const TermsOfUse = () => {
  const [docHtml, setDocHtml] = useState("");
  const docUrl = "/word/Terms of Use.docx"; // 文件位于 public/docs/example.docx
  const { t } = useTranslation();
  useEffect(() => {
    fetch(docUrl)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        mammoth
          .convertToHtml({ arrayBuffer })
          .then((result) => {
            const newStr = result.value.replace("Top of Form", "");
            setDocHtml(newStr);
          })
          .catch((err) => {
            console.error("Failed to convert document:", err);
          });
      })
      .catch((error) => {
        console.error("Failed to fetch document:", error);
      });
  }, [docUrl]);


  return (
    <div className="termsofuse-container">
      <NavigationBar navTitle={t("setting.tou.title")} />
      <div className="termsofuse-policy" dangerouslySetInnerHTML={{ __html: docHtml }} />

 
    </div>
  );
};

export default TermsOfUse;
