import Storage from "./storage";

class AIMEStorage {
  static setItem(key, value) {
    Storage.setItem(key, value);
  }

  static getItem(key) {
    return Storage.getItem(key);
  }

  static saveUnlockList(value) {
    console.log("####################saveUnlockList", value);
    this.setItem("unlock-list", value);
  }

  static searchUnlockList(value) {
    let unlockList = this.getItem("unlock-list");
    console.log("####################searchUnlockList", unlockList, value);
    if (unlockList && value) {
      return unlockList.indexOf(value) != -1;
    }
    return false;
  }

  static addUnlockItem(value) {
    let unlockList = this.getItem("unlock-list");
    if (unlockList) {
      unlockList = unlockList + "," + value;
    } else {
      unlockList = value;
    }
    this.saveUnlockList(unlockList);
    console.log("####################addUnlockItem", unlockList, value);
  }
  static getLanguage() {
    let language = this.getItem("aime-language");
    if(!language||language.length===0){
      language="en";
    }
    console.log("#################getLanguage",language);
    return language;
  }

  static setLanguage(value) {
    console.log("#################setLanguage",value);
    this.setItem("aime-language",value);
  }

  static emoji() {
    return [
        "😀",
        "😅",
        "😇",
        "🥰",
        "😛",
        "🤓",
        "😒",
        "😂",
        "🤣",
        "🥲",
        "😊",
        "😍",
        "😉",
        "😉",
        "🙃",
        "🙂",
        "😘",
        "😗",
        "😙",
        "😚",
        "😋",
        "🤨",
        "🤨",
        "🤪",
        "😜",
        "😜",
        "😝",
        "😞",
        "🤩",
        "🤩",
        "😏",
        "🙁",
        "😕",
        "😟",
        "😔",
        "😞",
        "🥶",
        "🥵",
        "😤",
        "😭",
        "😳",
        "😨",
        "🤯",
        "😰",
        "😥",
        "🤗",
        "🤔",
        "😱",
        "😶‍🌫️",
        "😴",
        "🤐",
        "😴",
        "😶‍🌫️",
        "😱",
        "💩",
        "👌",
        "👍",
        "🫰",
        "😇",
        "😊",
      ];
  }
}

export default AIMEStorage;
