import React, { useState, useEffect, useCallback } from "react";
import { Button, Image } from "antd-mobile";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { Link } from "react-router-dom";
import { Tabs, Input, Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Custom from "../../components/custom/custom";
import { ImageViewer, Mask } from "antd-mobile";
import { useTranslation } from 'react-i18next';
import "./index.css";

const MyInfo = () => {
  const location = useLocation();
  const history = useHistory();
  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [listInfo, setListInfo] = useState({
    num_of_pics: 0,
    num_of_vids: 0,
  });
  const [userPhoto, setUserPhoto] = useState("");
  const [userID, setUserID] = useState("");
  const [nickname, setNickname] = useState("");
  const [domainnick, setDomainnick] = useState("");
  const [description, setDescription] = useState("");
  const [emoji, setEmoji] = useState("");
  const [numberLikes, setNumberLikes] = useState("");
  const [numberPlay, setNumberPlay] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");

  const [imageVisible, setImageVisible] = useState(false);
  const [quickImageUrl, setQuickImageUrl] = useState("");
  const [videoVisible, setVideoVisible] = useState(false);
  const [quickVideoUrl, setQuickVideoUrl] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const { state } = location || {};
  const { item } = state || {};
  const { t } = useTranslation();
  const getBroadcasterPosts = async (type, accountId) => {
    try {
      const url = "/api/post/getBroadcasterPosts";
      const headers = {};
      const params = {
        page: 1,
        limit: 200,
        account_id: accountId,
        type: type,
      };
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { code, msg, data } = result;
      const { posts_list, info } = data;
      if (code === 200) {
        if (type === 1) {
          setImageList(posts_list);
        } else {
          setVideoList(posts_list);
        }
        setListInfo(info);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };

  const getUserInfo = async () => {
    try {
      const url = "/api/user/info"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data } = result;
      const {
        id,
        avatar_path,
        unique_nickname_id,
        num_likes,
        one_v_one,
        nickname,
        description,
        location_emoji_unicode,
        background_img,
      } = data;
      setUserID(id);
      setUserPhoto(avatar_path);
      setNickname(nickname);
      setDomainnick(unique_nickname_id);
      setDescription(description);
      getBroadcasterPosts(1, id);
      setEmoji(location_emoji_unicode);
      setNumberLikes(num_likes);
      setNumberPlay(one_v_one);
      setBackgroundImage(background_img);
      setUserInfo(data);
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  useEffect(() => {
    getUserInfo();
    return () => {};
  }, []);

  const selectLibrary = () => {
    history.push("library", { isSelectLibrary: true });
  };

  const imageQuickLock = (imagePath, type) => {
    if (type == 1) {
      setImageVisible(true);
      setQuickImageUrl(imagePath);
    } else {
      setVideoVisible(true);
      setQuickVideoUrl(imagePath);
    }
  };

  const handleVideoChange = async (event, type) => {
    const file = event.target.files[0];
    Toast.show({
      icon: "loading",
      content: t("toast.title.upload"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/user/editImg";
      const headers = {};
      const params = {
        img_type: type,
      };
      const result = await Request.postFormData(url, params, headers, file);
      console.log("result:", result);
      const { code, msg } = result;
      Toast.clear();
      if (code === 200) {
        getUserInfo();
      }
      Toast.show({
        content: msg,
        maskClickable: false,
        position: "bottom",
      });
    } catch (error) {
      Toast.clear();
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };

  const updateDes = async (event) => {
    if (event.charCode !== 13) {
      return;
    }
    submitDes();
  };
  const submitDes = async () => {
    if (!description || description.length === 0) {
      Toast.show({
        content: "Invalid email format！",
        maskClickable: false,
        position: "bottom",
      });
      return;
    }
    try {
      const url = "/api/broadcaster/editPersonalPageInfo"; // 设置API端点
      const headers = {};
      const params = {
        description: description,
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      if (code === 200) {
        getUserInfo();
      }
      Toast.show({
        content: msg,
        maskClickable: false,
        position: "bottom",
      });
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  return (
    <div className="my-info-container">
      <NavigationBar />
      <div className="my-info-header-container">
        <Image className="my-info-header-bg" src={backgroundImage} />
        <Image className="my-info-header-user-photo" src={userPhoto} />
        <Image
          className="my-info-header-user-photo-camera"
          src="/images/mine/mine_camera.png"
        />
        <input
          className="my-info-header-user-photo-input"
          type="file"
          accept="image/*"
          onChange={(event) => handleVideoChange(event, "avatar")}
        />

        {/* <div className="my-info-header-name">
          {" "}
          {Custom.truncateString(nickname, 8)} {Custom.emojiComponent(emoji)}
        </div> */}
        <div className="my-info-header-id">
          <Input
            className="my-info-main-info-input"
            placeholder={description}
            value={description}
            onChange={(val) => {
              setDescription(val);
            }}
            onKeyPress={updateDes}
            onBlur={submitDes}
          />
          <Image
            className="prelive-main-info-text1-img"
            src="/images/live/live_pen.png"
          />
        </div>

        <div className="my-info-header-lnf">
          <Link to={{ pathname: "/share", state: { item } }}>
            <Image
              src={"/images/person/person_icon1.png"}
              className="my-info-header-lnf-cell"
            />
          </Link>
          <Image
            src={"/images/person/person_icon2.png"}
            className="my-info-header-lnf-cell"
          />
          <Link to={{ pathname: "/chat", state: { item: userInfo } }}>
            <Image
              src={"/images/person/person_icon3.png"}
              className="my-info-header-lnf-cell"
            />
          </Link>
        </div>

        <div className="my-info-header-change-bg">
          <Image
            src="/images/mine/mine_change_bg.png"
            className="my-info-header-change-bg-image"
          />
          <div>{t("find.edit.cover")}</div>
        </div>
      </div>
      <input
        className="my-info-header-change-input"
        type="file"
        accept="image/*"
        onChange={(event) => handleVideoChange(event, "bg_img")}
      />

      <Tabs
        className="my-info-media"
        onChange={(key) => {
          getBroadcasterPosts(parseInt(key), userID);
        }}
      >
        <Tabs.Tab title={t("library.picture")+"（" + listInfo?.num_of_pics + ")"} key="1">
          <div className="my-info-list">
            {(imageList ?? []).map((mediaItem) => (
              <div key={mediaItem.id} className="my-info-list-item">
                <Image src={userPhoto} className="my-info-list-item-img" />
                <div className="my-info-list-item-right">
                  <div className="my-info-list-item-title">
                    {mediaItem.content}
                  </div>
                  <Image
                    fit="cover"
                    src={
                      mediaItem.path.length > 10
                        ? mediaItem.path
                        : mediaItem.preview_path
                    }
                    className="my-info-list-item-bigpic"
                    onClick={() =>
                      imageQuickLock(
                        mediaItem.path.length > 10
                          ? mediaItem.path
                          : mediaItem.preview_path,
                        1
                      )
                    }
                  />
                  {
                    (mediaItem.paid_content===1&&mediaItem.path.length < 10)?(
                      <div className="my-info-item-lock-container">
                      <Image
                        className="my-info-item-photo-lock"
                        src={"/images/chat/chat_lock.png"}
                      />
                      <div className="my-info-item-photo-money">
                        <Image
                          className="chat-list-item-photo-money-coin"
                          src={"/images/chat/chat_coin.png"}
                        />
                        <div className="chat-list-item-photo-money-count">
                        {mediaItem.price}
                        </div>
                        <div className="chat-list-item-photo-money-unlock">
                        {t("chat.unlock.now")}
                        </div>
                      </div>
                    </div>
                    ):(null)
                  }


                  <div className="my-info-list-item-number">
                    <Image
                      className="my-info-list-item-number-logo1"
                      src="/images/person/person_star.png"
                    />
                    <div>{mediaItem?.num_likes}</div>

                    <Image
                      className="my-info-list-item-number-logo2"
                      src="/images/person/person-image-chat.png"
                    />
                    <div>{mediaItem?.num_comments}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Tabs.Tab>
        <Tabs.Tab title={t("library.video")+"（" + listInfo?.num_of_vids + ")"} key="0">
          <div className="my-info-list">
            {(videoList ?? []).map((mediaItem) => (
              <div key={mediaItem.id} className="my-info-list-item">
                <Image src={userPhoto} className="my-info-list-item-img" />
                <div className="my-info-list-item-right">
                  <div className="my-info-list-item-title">
                    {mediaItem.content}
                  </div>
                  <video
                    fit="cover"
                    src={
                      mediaItem.path.length > 10
                        ? mediaItem.path
                        : mediaItem.preview_path
                    }
                    className="my-info-list-item-bigpic"
                    onClick={() =>
                      imageQuickLock(
                        mediaItem.path.length > 10
                          ? mediaItem.path
                          : mediaItem.preview_path,
                        0
                      )
                    }
                  />
                  <div className="my-info-item-lock-container">
                    <Image
                      className="my-info-item-photo-lock"
                      src={"/images/chat/chat_lock.png"}
                    />
                    <div className="my-info-item-photo-money">
                      <Image
                        className="chat-list-item-photo-money-coin"
                        src={"/images/chat/chat_coin.png"}
                      />
                      <div className="chat-list-item-photo-money-count">
                        {mediaItem.price}
                      </div>
                      <div className="chat-list-item-photo-money-unlock">
                      {t("chat.unlock.now")}
                      </div>
                    </div>
                  </div>
                  <div className="my-info-list-item-number">
                    <Image
                      className="my-info-list-item-number-logo1"
                      src="/images/person/person_star.png"
                    />
                    <div>{mediaItem?.num_likes}</div>

                    <Image
                      className="my-info-list-item-number-logo2"
                      src="/images/person/person-image-chat.png"
                    />
                    <div>{mediaItem?.num_comments}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Tabs.Tab>
      </Tabs>
      <Image
        src={"/images/library/library_add.png"}
        className="my-library-add"
        onClick={selectLibrary}
      />
      <ImageViewer
        classNames={{
          mask: "customize-mask",
          body: "customize-body",
        }}
        image={quickImageUrl}
        visible={imageVisible}
        onClose={() => {
          setImageVisible(false);
        }}
      />

      <Mask visible={videoVisible} onMaskClick={() => setVideoVisible(false)}>
        <video
          fit="cover"
          src={quickVideoUrl}
          className="my-video-style"
          controls
        />
      </Mask>
    </div>
  );
};

export default MyInfo;
