class Storage {
  static setItem(key, value) {
    if (key) {
      localStorage.setItem(key, value);
    }
  }

  static getItem(key) {
    if (key) {
      return localStorage.getItem(key);
    }
    return null;
  }
}
export default Storage;
