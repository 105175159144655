import React, { useState, useEffect, useCallback } from "react";
import { Image } from "antd-mobile";
import Request from "../../components/request/request";
import NavBar from "../home/navbar/index";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./index.css";
const Find = () => {
  const [userPhoto, setUserPhoto] = useState("/images/home/home_head.png");
  const [isLogin, setIsLogin] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [exploreList, setExploreList] = useState([]);
  const { t } = useTranslation();

  const readLocal = () => {
    const data = localStorage.getItem("userdata");
    if (data) {
      const userInfo = JSON.parse(data) || {};
      const { avatar_path } = userInfo.account || {};

      setUserPhoto(avatar_path);
      setIsLogin(userInfo.access_token ? true : false);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const url = "/api/discoveryList";
      const headers = {};
      const params = {
        page: 1,
        limit: 20,
      };
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { data } = result;
      setExploreList(data);
      // No need to log exploreList directly
    } catch (error) {
      console.error("Error:", error);
    }
  }, []); // Keep this array empty since fetchData doesn't need dynamic dependencies

  useEffect(() => {
    if (!isLoad) {
      setIsLoad(true);
      readLocal();
      fetchData();
    }

    return () => {
      // Cleanup if needed
    };
  }, [fetchData, exploreList, isLoad]);

  const handleBlur = async () => {
    console.log("Input lost focus:", searchText);
    try {
      const url = "/api/search";
      const headers = {};
      const params = {
        keyword: searchText || "",
        page: 1,
        limit: 20,
      };
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { data } = result;
      setExploreList(data);
      // No need to log exploreList directly
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="find-container">
      <NavBar hideSearch={true} userPhoto={userPhoto} isLogin={isLogin} />
      <div className="find-search">
        <input
          className="find-search-input "
          type="searchText"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={t("input.search.placehold")} 
          onBlur={handleBlur}
          key={"find"}
        />
        <Image
          onClick={handleBlur}
          src="/images/find/find_search.png"
          className="find-search-btn"
        />
      </div>

      <div className="find-list">
        {(exploreList ?? []).map((item) => (
          <Link key={item.id} to={{ pathname: "/personinfo", state: { item: item } }}>
            <div  className="find-item">
              <Image
                className="find-item-bg"
                src={item.discovery_page_bk_img}
              />
              <Image className="find-item-head" src={item.avatar_path} />
              <div
                className={`find-item-head-dot ${
                  item.online_status === 0 ? "dot-unable" : ""
                }`}
              />
              <div className="find-item-name">{item.nickname}</div>
              <div className="find-item-id">
                {"@" + item.domain_nick}
                <Image
                  className="find-item-id-star"
                  src={
                    item.favorite === 0
                      ? "/images/find/find_star.png"
                      : "/images/find/find_star_sel.png"
                  }
                />
              </div>
              <div className="find-item-state">{item.status}</div>
              <Image
                className="find-item-more"
                src="/images/find/find_more.png"
              />
            </div>
          </Link>
        ))}
        <div className="find-item-bottom">

        </div>
      </div>
    </div>
  );
};

export default Find;
