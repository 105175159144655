import React, { useState, useEffect, useRef } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";

import Home from "./pages/home/index";
import Find from "./pages/find/index";
import EditFind from "./pages/find/edit/index";

import Mine from "./pages/mine/index";
import Login from "./pages/login/index";
import Regist from "./pages/login/register/index";
import ResetPassword from "./pages/login/resetpassword/index";
import PasswordSubmit from "./pages/login/passwordsubmit/index";
import EmailCode from "./pages/login/emailcode/index";

import Message from "./pages/message/index";
import Search from "./pages/search/index";
import HistoryPage from "./pages/history/index";
import PersonInfo from "./pages/personinfo/index";
import MyInfo from "./pages/myinfo/index";
import Chat from "./pages/chat/index";
import Unlock from "./pages/chat/unlock/index";
import Demo from "./pages/demo/index";
import Prelive from "./pages/prelive/index";

import LiveInput from "./pages/liveinput/index";
import LiveOutput from "./pages/liveoutput/index";
import OneOnOne from "./pages/ovo/index";
import OneOnOneAud from "./pages/ovoaud/index";

import Library from "./pages/library/index";
import LibraryEdit from "./pages/library/edit/index";
import Share from "./pages/share/index";
import Verified from "./pages/verified/index";
import Withdraw from "./pages/withdraw/index";
import Personal from "./pages/personal/index";
// import Profit from "./pages/profit/index";
import UserList from "./pages/liveinput/userlist/index";
import Recharge from "./pages/recharge/index";
import PaySuccess from "./pages/recharge/success/index";

import Tags from "./pages/tags/index";
import Income from "./pages/income/index";
import Faq from "./pages/faq/index";
import Setting from "./pages/setting/index";

import Topup from "./pages/chat/topup/index";
import TabBarView from "./uimodules/tabbar/index";
import PopView from "./uimodules/popview/index";
import FireBaseConfiger from "./components/firebase/index";
import Privacy from "./pages/privacy/index";
import TermsOfUse from "./pages/termsofuse/index";
import WordFile from "./pages/wordfile/index";
import Report from "./pages/report/index";

import { useHistory } from "react-router-dom";
import "./App.css";

const APP = () => {
  const [showTabBar, setShowTabBar] = useState(true);
  const [popVisiable, setPopVisiable] = useState(false);
  const history = useHistory();

  const fullscreenRef = useRef(null);

  const handleFullscreen = () => {
    const element = fullscreenRef.current;

    if (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        /* Safari */
        console.log("############webkitRequestFullscreen");
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        /* IE11 */
        element.msRequestFullscreen();
      }
    }
  };
  useEffect(() => {
    FireBaseConfiger.initializeApp();
    import("easemob-websdk").then((res) => {
      const EC = res.default;
      console.log(EC, "#########easemob websdk");
    });
    handleFullscreen();
    return () => {
      // 清理操作，当组件卸载时执行
    };
  }, []);
  //切换tab时选中状态更新
  const onTabChange = (value) => {
    if (value !== "home" && value !== "find") {
      //setShowTabBar(false);
      setPopVisiable(!popVisiable);
    } else {
      setPopVisiable(false);
    }
  };
  const logout = () => {
    console.log("==============xxxxxxxxx");
    history.push("/login");
  };
  window.logout = logout;

  return (
    <Router initialEntries={["/home"]}>
      <Switch>
        <Route
          exact
          path="/home"  //首页
          render={() => {
            setShowTabBar(true);
            return <Home />;
          }}
        />
        <Route
          exact
          path="/find"  //发现
          render={() => {
            setShowTabBar(true);
            return <Find />;
          }}
        />

        <Route
          exact
          path="/mine"   //我的
          render={() => {
            setShowTabBar(false);
            return <Mine />;
          }}
        />

        <Route
          exact
          path="/login"  //登录
          render={() => {
            setShowTabBar(false);
            return <Login />;
          }}
        />

        <Route
          exact
          path="/regist"  //注册
          render={() => {
            setShowTabBar(false);
            return <Regist />;
          }}
        />
        <Route
          exact
          path="/resetpassword" //重置密码
          render={() => {
            setShowTabBar(false);
            return <ResetPassword />;
          }}
        />
        <Route
          exact
          path="/ovo"   
          render={() => {
            setShowTabBar(false);
            return <OneOnOne />;
          }}
        />
        <Route
          exact
          path="/ovoaud"
          render={() => {
            setShowTabBar(false);
            return <OneOnOneAud />;
          }}
        />

        <Route
          exact
          path="/passwordsubmit"   
          render={() => {
            setShowTabBar(false);
            return <PasswordSubmit />;
          }}
        />
        <Route
          exact
          path="/emailcode"
          render={() => {
            setShowTabBar(false);
            return <EmailCode />;
          }}
        />
        <Route
          exact
          path="/message"
          render={() => {
            setShowTabBar(false);
            return <Message />;
          }}
        />
        <Route
          exact
          path="/history"
          render={() => {
            setShowTabBar(false);
            return <HistoryPage />;
          }}
        />
        <Route
          exact
          path="/setting"
          render={() => {
            setShowTabBar(false);
            return <Setting />;
          }}
        />

        <Route
          exact
          path="/search"
          render={() => {
            setShowTabBar(false);
            return <Search />;
          }}
        />
        <Route
          exact
          path="/faq"
          render={() => {
            setShowTabBar(false);
            return <Faq />;
          }}
        />

        <Route
          exact
          path="/personinfo"
          render={() => {
            setShowTabBar(false);
            return <PersonInfo />;
          }}
        />
        <Route
          exact
          path="/myinfo"
          render={() => {
            setShowTabBar(false);
            return <MyInfo />;
          }}
        />

        <Route
          exact
          path="/recharge"
          render={() => {
            setShowTabBar(false);
            return <Recharge />;
          }}
        />
        <Route
          exact
          path="/paysuccess"
          render={() => {
            setShowTabBar(false);
            return <PaySuccess />;
          }}
        />

        <Route
          exact
          path="/chat"
          render={() => {
            setShowTabBar(false);
            return <Chat />;
          }}
        />

        <Route
          exact
          path="/income"
          render={() => {
            setShowTabBar(false);
            return <Income />;
          }}
        />

        <Route
          exact
          path="/unlock"
          render={() => {
            setShowTabBar(false);
            return <Unlock />;
          }}
        />
        <Route
          exact
          path="/topup"
          render={() => {
            setShowTabBar(false);
            return <Topup />;
          }}
        />

        <Route
          exact
          path="/demo"
          render={() => {
            setShowTabBar(false);
            return <Demo />;
          }}
        />

        <Route
          exact
          path="/prelive"
          render={() => {
            setShowTabBar(false);
            return <Prelive />;
          }}
        />
        <Route
          exact
          path="/liveinput"
          render={() => {
            setShowTabBar(false);
            return <LiveInput />;
          }}
        />
        <Route
          exact
          path="/userlist"
          render={() => {
            setShowTabBar(false);
            return <UserList />;
          }}
        />

        <Route
          exact
          path="/liveoutput"
          render={() => {
            setShowTabBar(false);
            return <LiveOutput />;
          }}
        />
        <Route
          exact
          path="/editfind"
          render={() => {
            setShowTabBar(false);
            return <EditFind />;
          }}
        />
        <Route
          exact
          path="/library"
          render={() => {
            setShowTabBar(false);
            return <Library />;
          }}
        />
        <Route
          exact
          path="/share"
          render={() => {
            setShowTabBar(false);
            return <Share />;
          }}
        />

        <Route
          exact
          path="/libraryedit"
          render={() => {
            setShowTabBar(false);
            return <LibraryEdit />;
          }}
        />
        <Route
          exact
          path="/verified"
          render={() => {
            setShowTabBar(false);
            return <Verified />;
          }}
        />
        <Route
          exact
          path="/withdraw"
          render={() => {
            setShowTabBar(false);
            return <Withdraw />;
          }}
        />
        <Route
          exact
          path="/personal"
          render={() => {
            setShowTabBar(false);
            return <Personal />;
          }}
        />

        <Route
          exact
          path="/tags"
          render={() => {
            setShowTabBar(false);
            return <Tags />;
          }}
        />

        <Route
          exact
          path="/privacy"
          render={() => {
            setShowTabBar(false);
            return <Privacy />;
          }}
        />
        <Route
          exact
          path="/termsofuse"
          render={() => {
            setShowTabBar(false);
            return <TermsOfUse />;
          }}
        />
        <Route
          exact
          path="/wordfile"
          render={() => {
            setShowTabBar(false);
            return <WordFile />;
          }}
        />
        <Route
          exact
          path="/report"
          render={() => {
            setShowTabBar(false);
            return <Report />;
          }}
        />

        {/* <Route
          exact
          path="/profit"
          render={() => {
            setShowTabBar(false);
            return <Profit />;
          }}
        /> */}

        <Redirect to="/home" />
      </Switch>
      {showTabBar && (
        <div className="bottom">
          <TabBarView onTabChange={onTabChange} />
        </div>
      )}
      {popVisiable && (
        <PopView popVisiable={popVisiable} setPopVisiable={setPopVisiable} />
      )}
    </Router>
  );
};

export default APP;
