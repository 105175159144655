import React, { useState, useEffect, useCallback } from "react";
import { DatePicker, Image, Toast } from "antd-mobile";
import Request from "../../../components/request/request";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./index.css";
const ShowPayAlert = ({ setAlertVisiable, alertInfo, alertItem }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const changeToLocal = (utcTimestamp) => {
    const date = new Date(utcTimestamp);

    // 手动格式化日期和时间
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");

    // 组合为"年-月-日 时:分"格式
    const localDateTime = `${year}-${month}-${day} ${hour}:${minute}`;

    return localDateTime;
  };

  const buyTicket = async () => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/private-show/sale-ticket";
      const headers = {};
      const params = {
        live_id: alertItem.live_id,
      };
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result || [];
      Toast.clear();
      if (code === 200) {
        Toast.show({
          icon: "success",
          content: "支付成功",
          position: "bottom",
        });
        console.log("#########################alertItem"+JSON.stringify(alertItem));
        history.push({ pathname: "/liveoutput", state: { item:alertItem } });
        setAlertVisiable(false);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.clear();
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    console.log("#########################alertItem"+JSON.stringify(alertItem));
    console.log("#####################" + JSON.stringify(alertInfo));
    return () => {};
  }, [alertInfo]);

  return (
    <div
      className="show-pay-alert-container"
      onClick={() => setAlertVisiable(false)}
    >
      <Image
        src="/images/live/live_alert_bg.png"
        className="show-pay-alert-bg"
      />
      <div className="show-pay-alert-main" onClick={(e) => e.stopPropagation()}>
        <div className="show-pay-alert-main-title">
          <Image
            src="/images/live/live_show_hot.png"
            className="show-pay-alert-img"
          />
          <div className="show-pay-alert-main-text">{t("show.title")}</div>
        </div>
        <div className="show-pay-alert-main-input1">
          <div>{t("show.begin.time")}：{changeToLocal(alertInfo.start_time)}</div>
        </div>

        <div className="show-pay-alert-main-input2">
          <div>{t("show.end.time")}：{changeToLocal(alertInfo.end_time)}</div>
        </div>

        <div className="show-pay-alert-main-input2">
          <div>{t("show.max.number")}：{alertInfo.limit_num_visitor}人</div>
        </div>

        <div className="show-pay-alert-main-input2">
          <div>{t("show.price")}：{alertInfo.entry_fee}{t("show.each")}</div>
        </div>
        <div className="show-pay-alert-main-sure" onClick={buyTicket}>
          {t("show.sure")}
        </div>
      </div>
    </div>
  );
};

export default ShowPayAlert;
