import EC from "easemob-websdk";
import { Toast } from "antd-mobile";
import Request from "../request/request";

class AIMEEaseMob {
  // Initialize the connection within the class
  static conn = new EC.connection({
    appKey: "1132240507150669#demo"
  });

  static failTryNumber = 0;

  static easeUserToken = async () => {
    try {
      const url = "/api/easeUserToken";
      const headers = {};
      const params = {};
      const result = await Request.get(url, params, headers); 
      console.log("result:", result);
      const { code, msg, data } = result;
      const { access_token, user } = data || {};
      const { username } = user || {};
      if (code === 200) {
        this.easemob(username, access_token);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.show({
        content: `Error: ${error}`,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };

  static easemob = (username, access_token) => {
    // console.log("###############login:" + username + "||" + access_token);
    this.conn
      .open({
        user: username,
        accessToken: access_token,
      })
      .then(() => {
        console.log("###############login succes" );  // Ensure props.chatroomId is accessible or remove this part
      })
      .catch((reason) => {
        console.log("###############login fail", reason);
        if (this.failTryNumber++ < 3) {
          this.easeUserToken();
        }
      });
  };
}

export default AIMEEaseMob;
