import React, { useEffect, useState } from "react";
import { Badge, Image } from "antd-mobile";
import AIMEEaseMob from "../../../components/easemob/index";
import Request from "../../../components/request/request";
import "./index.css";
import { useHistory } from "react-router-dom";

const NavBar = (props) => {
  const history = useHistory();
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadSystemCount, setUnreadSystemCount] = useState(0);
  function login(e) {
    if (props.isLogin) {
      history.push("/mine");
    } else {
      history.push("/login");
    }
  }
  function message() {
    history.push("/message");
  }

  function historyPage() {
    history.push("/history");
  }

  function search() {
    history.push("/search");
  }

  const fetchData = async () => {
    try {
      const url = "/api/systemMessage"; // 设置API端点
      const headers = {};
      const params = {
        page: 1,
        limit: 1000,
      };
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;

      if (code === 200) {
        setUnreadSystemCount(data.length);
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  const getServerConversations = () => {
    AIMEEaseMob.conn
      .getServerConversations({ pageSize: 50, cursor: "" })
      .then((res) => {
        const { conversations } = res.data;
        let count = 0;

        conversations.forEach((item) => {
          count = count + parseInt(item.unReadCount);
        });
        setUnreadCount(count);
        console.log(
          "getServerConversations######################",
          conversations
        );
      });
  };

  useEffect(() => {
    if (props.isLogin) {
      AIMEEaseMob.conn.addEventHandler("handlerId", {
        onConnected: () => {
          getServerConversations(true);
          console.log("##################onConnected");
        },
        onDisconnected: () => {
          console.log("##################onDisconnected");
        },
        onTokenWillExpire: () => {
          console.log("##################onTokenWillExpire");
        },
        onTokenExpired: () => {
          console.log("##################onTokenExpired");
        },
        onTextMessage: (message) => {
          getServerConversations(false);
        },
        onImageMessage: (message) => {
          getServerConversations(false);
        },
        onCustomMessage: (message) => {
          getServerConversations(false);
        },
      });
      if (AIMEEaseMob.conn.isOpened()) {
        getServerConversations(true);
      } else {
        AIMEEaseMob.easeUserToken();
      }
      console.log("##################isOpened", AIMEEaseMob.conn.isOpened);

      fetchData();
    }
  }, [props.isLogin]);

  return (
    <div className="navbar">
      <Image
        className="head-btn"
        src={
          props.userPhoto ? props.userPhoto : "https://i.imgur.com/sb7TZMc.jpeg"
        }
        fit="fill"
        onClick={login}
      />

      <div className="btn-contain">
        {props.hideSearch ? (
          <div />
        ) : (
          <Image
            className="search-btn"
            src="/images/find/find_search.png"
            fit="fill"
            onClick={search}
          />
        )}

        <Image
          className="history-btn"
          src="/images/home/home_history.png"
          fit="fill"
          onClick={historyPage}
        />
        {unreadCount + unreadSystemCount > 0 ? (
          <Badge
            content={unreadCount + unreadSystemCount}
            style={{ "--color": "#F7B500", "--right": "18px" }}
          >
            <Image
              className="message-btn"
              src="/images/home/home_message.png"
              fit="fill"
              onClick={message}
            />
          </Badge>
        ) : (
          <Image
            className="message-btn"
            src="/images/home/home_message.png"
            fit="fill"
            onClick={message}
          />
        )}
      </div>
    </div>
  );
};

export default NavBar;
