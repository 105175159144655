import React, { useState, useEffect, useCallback } from "react";
import { DatePicker, Image, Toast } from "antd-mobile";
import Request from "../../../components/request/request";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./index.css";
const ShowAlert = ({
  setAlertVisiable,
  startTime,
  endTime,
  setStartTime,
  setEndTime,
  entranceFee,
  setEntranceFee,
  maxNumber,
  setMaxNumber,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [showSetting, setShowSetting] = useState({});
  const [pickerVisiable, setPickerVisiable] = useState(false);
  const [pickerIndex, setPickerIndex] = useState(0);

  const [startTimeAlert, setStartTimeAlert] = useState(startTime);
  const [endTimeAlert, setEndTimeAlert] = useState(endTime);
  const [entranceFeeAlert, setEntranceFeeAlert] = useState(entranceFee);
  const [maxNumberAlert, setMaxNumberAlert] = useState(maxNumber);

  const fetchData = async () => {
    Toast.show({
      icon: "loading",
      content: t("toast.title.loading"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/private-show/default-setting"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      Toast.clear();
      if (code === 200) {
        setShowSetting(data);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      Toast.clear();
      console.error("Error:", error); // 打印错误信息
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const onConfirm = async () => {
    const startDate = new Date(startTimeAlert);
    const utcStartTimestamp = startDate.getTime();
    setStartTime(utcStartTimestamp);

    const endDate = new Date(endTimeAlert);
    const utcEndTimestamp = endDate.getTime();
    setEndTime(utcEndTimestamp);
    setMaxNumber(maxNumberAlert ? maxNumberAlert : 10);
    setEntranceFee(entranceFeeAlert ? entranceFeeAlert : "10");
    setAlertVisiable(false);
  };

  const pickClick = (index) => {
    setPickerVisiable(true);
    setPickerIndex(index);
  };
  useEffect(() => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 10);
    setStartTimeAlert(formatDate(now));
    now.setMinutes(now.getMinutes() + 50);
    setEndTimeAlert(formatDate(now));
    fetchData();
    return () => {};
  }, []);

  return (
    <div
      className="show-alert-container"
      onClick={() => setAlertVisiable(false)}
    >
      <Image src="/images/live/live_alert_bg.png" className="show-alert-bg" />
      <div className="show-alert-main" onClick={(e) => e.stopPropagation()}>
        <div className="show-alert-main-title">
          <Image
            src="/images/live/live_show_hot.png"
            className="show-alert-img"
          />
          <div className="show-alert-main-text">{t("show.title")}</div>
        </div>
        <div className="show-alert-main-input1" onClick={() => pickClick(0)}>
          <div>{t("show.begin.time")}：</div>
          <div className="show-alert-main-input-txt">{startTimeAlert}</div>
        </div>

        <div className="show-alert-main-input2" onClick={() => pickClick(1)}>
          <div>{t("show.end.time")}：</div>
          <div className="show-alert-main-input-txt">{endTimeAlert}</div>
        </div>

        <div className="show-alert-main-input2">
          <div>{t("show.max.number")}：</div>

          <input
            className="show-alert-main-input-txt"
            type="number"
            value={maxNumberAlert}
            onChange={(e) => setMaxNumberAlert(e.target.value)}
            placeholder={
              (showSetting.limit_num_of_visitor
                ? showSetting.limit_num_of_visitor
                : 0) +
              " " +
              t("show.people")
            }
          />
        </div>

        <div className="show-alert-main-input2">
          <div>{t("show.price")}：</div>
          <input
            className="show-alert-main-input-txt"
            type="number"
            value={entranceFeeAlert}
            onChange={(e) => setEntranceFeeAlert(e.target.value)}
            placeholder={
              (showSetting.entrance_fee ? showSetting.entrance_fee : 10) +
              " " +
              t("show.each")
            }
          />
        </div>
        <div className="show-alert-main-sure" onClick={onConfirm}>
          {t("show.sure")}
        </div>
      </div>

      <DatePicker
        title={pickerIndex === 0 ? t("show.begin.time") : t("show.end.time")}
        visible={pickerVisiable}
        onClose={() => {
          setPickerVisiable(false);
        }}
        min={new Date()}
        precision="minute"
        onConfirm={(val) => {
          if (pickerIndex === 0) {
            setStartTimeAlert(formatDate(val));
            val.setMinutes(val.getMinutes() + 40);
            setEndTimeAlert(formatDate(val));
          } else {
            setEndTimeAlert(formatDate(val));
          }
        }}
      />
    </div>
  );
};

export default ShowAlert;
