import React, { useState, useEffect, useCallback } from "react";
import { Image, Toast } from "antd-mobile";
import Request from "../../../components/request/request";
import { useTranslation } from 'react-i18next';
import NavigationBar from "../../../uimodules/navbar";
import "./index.css";
const EditFind = () => {
  const [userInfo, setUserInfo] = useState([]);
  const { t } = useTranslation();
  const handleVideoChange = async (event, type) => {
    const file = event.target.files[0];
    Toast.show({
      icon: "loading",
      content: t("toast.title.upload"),
      duration: 0,
      maskClickable: false,
    });
    try {
      const url = "/api/user/editImg";
      const headers = {};
      const params = {
        img_type: type,
      };
      const result = await Request.postFormData(url, params, headers, file);
      console.log("result:", result);
      const { code, msg } = result;
      Toast.clear();
      if (code === 200) {
        getUserInfo();
      }
      Toast.show({
        content: msg,
        maskClickable: false,
        position: "bottom",
      });
    } catch (error) {
      Toast.clear();
      Toast.show({
        content: error,
        maskClickable: false,
        position: "bottom",
      });
      console.error("Error:", error);
    }
  };
  const getUserInfo = async () => {
    try {
      const url = "/api/user/info"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.post(url, params, headers);
      console.log("result:", result);
      const { data, code, msg } = result;
      if (code === 200) {
        setUserInfo(data);
      } else {
        Toast.show({
          content: msg,
          maskClickable: false,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };
  useEffect(() => {
    getUserInfo();
    return () => {};
  }, []);

  return (
    <div className="find-edit-container">
      <NavigationBar navTitle={t("find.edit.title")} />

      <div className="find-edit-list">
        <div className="find-edit-item">
          <Image
            className="find-edit-item-bg"
            src={userInfo?.discovery_page_bk_img}
          />
          <Image className="find-edit-item-head" src={userInfo?.avatar_path} />

          <div className="find-edit-item-name">{t("find.edit.des")}</div>

          <div className="find-edit-item-state">{t("find.edit.bg")}</div>
  
        </div>
        <input
          className="find-edit-item-absolute"
          type="file"
          accept="image/*"
          onChange={(event) => handleVideoChange(event, "discover_bg_img")}
        />
      </div>
    </div>
  );
};

export default EditFind;
