import React, { useState, useEffect, useCallback } from "react";
import { Button, Image } from "antd-mobile";
//import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
//import { Toast } from "antd-mobile";
import { useHistory } from "react-router-dom";
import Custom from "../../components/custom/custom";
import "./index.css";
const Faq = () => {
  const history = useHistory();

  const [messageList] = useState([
    { title: "6 Proposals for Hosts" },
    { title: "12 Prohibited Rules" },
    { title: "Host Basic Rules" },
    { title: "Host Guide" },
    { title: "How can I unlink my host account from the studio" },
    { title: "What is AIMeScore and how can it be improved" },
    { title: "What to do in case of rejected documents" },
  ]);

  const openPath = (fileName) => {
    history.push("/wordfile", {
      title: fileName,
      fileName: "/word/"+fileName+".docx",
    });
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="faq-container">
      <NavigationBar navTitle={"FAQ"} />

      <div className="faq-list">
        {(messageList ?? []).map((item) => (
          <div className="mine-list-item" onClick={()=>openPath(item.title)}>
            <div className="mine-list-item-title">
            {Custom.truncateString(item.title, 40)}
            </div>
            <Image
              src={"/images/mine/mine_arrow.png"}
              className="mine-list-item-arrow"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
