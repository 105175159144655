import React, { useEffect } from "react";
import { Image } from "antd-mobile";
import { useTranslation } from 'react-i18next';
import "./index.css";
const FaceCode = ({ setAlertVisiable, faceCode}) => {
 
  useEffect(() => {

    return () => {};
  }, []);

  return (
    <div className="face-code-container" onClick={() => setAlertVisiable(false)}>
         <div className="face-code-main-title">直播码</div>
 
        <div className="face-code-main-code">
         {faceCode}
        </div>
 
        <div className="face-code-main-bottom-txt face-code-main-bottom-margin">换脸直播对设备性能要求比较高，请在电脑端下载后直播!</div>
        <div className="face-code-main-bottom-txt">安装完成后凭直播码进入：http://www.aime.com</div>
 
    </div>
  );
};

export default FaceCode;
