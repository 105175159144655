import React from "react";
import { LeftOutline, CloseOutline } from "antd-mobile-icons";
import { Image } from "antd-mobile";
import { useHistory } from "react-router-dom";
import Custom from "../../components/custom/custom";
import "./index.css";

const NavigationBar = ({
  navTitle,
  titleColor,
  titleBorder,
  bgColor,
  type,
  moreImg,
  moreHandle,
  goBackHandle,
}) => {
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
    if(goBackHandle){
      goBackHandle();
    }
  };

  return (
    <div className="navigation-bar" style={{ backgroundColor: bgColor }}>
      <div className="navigation-back" onClick={handleGoBack}>
        {type === 1 ? (
          <CloseOutline fontSize={20} color="#ffffff" />
        ) : (
          <LeftOutline fontSize={20} color="#ffffff" />
        )}
      </div>
      <div className="navigation-title-container">
        <div
          className="navigation-title"
          style={{ color: titleColor, borderBottom: titleBorder }}
        >
          {navTitle&&navTitle.length>0?(Custom.truncateString(navTitle, 30)):(null)}
        </div>
      </div>
      {moreImg ? (
        <Image className="navigation-more" src={moreImg} onClick={moreHandle} />
      ) : null}
    </div>
  );
};

export default NavigationBar;
