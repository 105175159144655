import React, { useState, useEffect, useCallback } from "react";
import { Image } from "antd-mobile";
import Request from "../../components/request/request";
import NavigationBar from "../../uimodules/navbar";
import { Toast } from "antd-mobile";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import "./index.css";
const Income = () => {
  const history = useHistory();
  const [incomeAll, setIncomeAll] = useState(0);
  const { t } = useTranslation();
  const fetchData = async () => {
    try {
      const url = "/api/getTotalIncome"; // 设置API端点
      const headers = {};
      const params = {};
      const result = await Request.get(url, params, headers);
      console.log("result:", result);
      const { code, msg, data } = result;
      if (code === 200) {
        setIncomeAll(data);
      }
    } catch (error) {
      console.error("Error:", error); // 打印错误信息
    }
  };

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  return (
    <div className="income-container">
      <NavigationBar navTitle={t("income.title")} />
      <div className="income-list">
        <div className="income-title-pay">
          <div className="income-title-begin">{t("income.content")}：</div>
          <div className="income-title-price">{incomeAll}</div>
          <Image src="/images/chat/chat_coin.png" className="income-logo" />
        </div>
      </div>
    </div>
  );
};

export default Income;
